import { action, computed, observable } from 'mobx';
import * as uuid from 'uuid';
import { IError, IErrorHandler } from '../interfaces';

export class ErrorHandler implements IErrorHandler {

    @computed public get visibleErrors() {
        return this.errors.filter((error) => {
            return new Date(error.added.getSeconds() + 5) > new Date();
        });
    }
    @observable public errors: IError[] = [];

    @action public deleteError = (id: string) => {
        this.errors = this.errors.filter((error) => {
            return error.id !== id;
        });
    }

    @action public addError = (message: string) => {
        this.errors.push({
            message,
            added: new Date(),
            id: uuid.v4()
        });
    }
}
