import { component } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Search from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import * as React from 'react';
import { INavigation } from '../../interfaces';

export const ApplicationList = component('ApplicationList')
    .use(mui)
    .use(theme)
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .style(() => ({
        Root: {
            margin: '0',
            marginBottom: '32px',
            padding: '0px 0px 0px 4px',
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: '4px',
            height: '56px',
            boxShadow: 'none'
        },
        IconButton: {
            padding: 10,
            borderRadius: 0
        },
        InputBase: {
            marginLeft: 8,
            flex: 1
        },
        AppLogo: {
            maxWidth: 30,
            maxHeight: 30,
            padding: '0px'
        },
        List: {
            width: '100%'

        },
        SkeletonList: {
            width: '100%'
        },
        SkeletonListItem: {
            padding: '5px 20px'
        }
    }))
    .render(({ $, navigation, theme }) => {
        const $List = $(List, 'List');
        const $SkeletonList = $(Box, 'SkeletonList');
        const $SkeletonListItem = $(Box, 'SkeletonListItem');
        const $Root = $(Paper, 'Root');
        const $InputBase = $(InputBase, 'InputBase');

        return (
            <>
                <$Root square>
                    <$InputBase
                        placeholder="Sök"
                        inputProps={{ 'aria-label': 'search applications' }}
                        value={navigation.applicationSearch}
                        onChange={(e: any) =>
                            navigation.setApplicationSearch(e.target.value)
                        }
                        startAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                    />
                </$Root>
                <Typography variant="h6">Applikationer</Typography>
                {navigation.applications.length > 0 ? (
                    <$List>
                        {navigation.filteredApplications.map((app) => (
                            <ListItem
                                key={app.id}
                                button
                                onClick={() => app.open()}
                                style={{
                                    paddingLeft: '8px',
                                    paddingBottom: '8px',
                                    marginLeft: '-8px'
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        style={{
                                            background: '#284e80',
                                            color: '#fff',
                                            lineHeight: 'inherit',
                                            fontSize: '15px',
                                            fontWeight: 600,
                                            padding: '10px',
                                            height: '25px',
                                            width: '25px',
                                            letterSpacing: '0.5px'
                                        }}
                                    >
                                        {app.logo}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={app.title} />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        icon={
                                            <FavoriteBorder
                                                style={{
                                                    color: '#000',
                                                    width: '20px',
                                                    height: '20px',
                                                    right: '-5px',
                                                    position: 'absolute'
                                                }}
                                            />
                                        }
                                        checkedIcon={
                                            <Favorite
                                                style={{
                                                    color: '#000',
                                                    width: '20px',
                                                    height: '20px',
                                                    right: '-5px',
                                                    position: 'absolute'
                                                }}
                                            />
                                        }
                                        value={`checked-${app.id}`}
                                        checked={app.favorite}
                                        onChange={
                                            app.favorite
                                                ? app.unmarkAsFavorite
                                                : app.markAsFavorite
                                        }
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </$List>
                ) : (
                    <$SkeletonList>
                        <$SkeletonListItem>
                            <Skeleton height={20} variant="text" />
                        </$SkeletonListItem>
                        <$SkeletonListItem>
                            <Skeleton height={20} variant="text" />
                        </$SkeletonListItem>
                        <$SkeletonListItem>
                            <Skeleton height={20} variant="text" />
                        </$SkeletonListItem>
                        <$SkeletonListItem>
                            <Skeleton height={20} variant="text" />
                        </$SkeletonListItem>
                        <$SkeletonListItem>
                            <Skeleton height={20} variant="text" />
                        </$SkeletonListItem>
                    </$SkeletonList>
                )}
            </>
        );
    })
    .compile();
