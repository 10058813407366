import { component } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Skeleton from '@material-ui/lab/Skeleton';
import * as React from 'react';
import { INavigation } from '../../../interfaces';

export const ApplicationMenuList = component('ApplicationMenuList')
    .use(mui)
    .use(theme)
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .style(() => ({
        AppLogo: {
            maxWidth: 30,
            maxHeight: 30,
            padding: '0px 10px'
        },
        List: {
            width: 380
        },
        SkeletonList: {
            width: 380
        },
        SkeletonListItem: {
            padding: '5px 20px'
        }
    }))
    .render(({ $, navigation, theme }) => {
        const $List = $(List, 'List');
        const $SkeletonList = $(Box, 'SkeletonList');
        const $SkeletonListItem = $(Box, 'SkeletonListItem');

        return navigation.applications.length > 0
            ? (
                <$List>
                    {
                        navigation.filteredApplications.map((app) => (
                            <ListItem
                                key={app.id}
                                button
                                onClick={() => app.open()}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        style={{
                                            background: app.theme.primaryColor,
                                            color: theme.palette.getContrastText(app.theme.primaryColor),
                                            lineHeight: 'inherit'
                                        }}
                                    >
                                        {app.logo}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={app.title} />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        icon={<FavoriteBorder color="primary" />}
                                        checkedIcon={<Favorite color="primary" />}
                                        value={`checked-${app.id}`}
                                        checked={app.favorite}
                                        onChange={app.favorite ? app.unmarkAsFavorite : app.markAsFavorite}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))
                    }
                </$List>
            )
            : (
                <$SkeletonList>
                    <$SkeletonListItem>
                        <Skeleton height={20} variant="text" />
                    </$SkeletonListItem>
                    <$SkeletonListItem>
                        <Skeleton height={20} variant="text" />
                    </$SkeletonListItem>
                    <$SkeletonListItem>
                        <Skeleton height={20} variant="text" />
                    </$SkeletonListItem>
                    <$SkeletonListItem>
                        <Skeleton height={20} variant="text" />
                    </$SkeletonListItem>
                    <$SkeletonListItem>
                        <Skeleton height={20} variant="text" />
                    </$SkeletonListItem>
                </$SkeletonList>
            );
    })
    .compile();
