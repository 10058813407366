import { component, types } from '@lardy/core';
import { mui } from '@lardy/mui';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as React from 'react';
import { IXSearchResultDivider } from '../../../interfaces';

export const XSearchbarDivider = component('XSearchbarDivider')
    .use(mui)
    .props({
        data: types.complex<IXSearchResultDivider>()
    })
    .style(({ data }) => ({
        ListSubheader: {

        }
    }))
    .render(({
        $,
        data: {
            label,
            count
        }
    }) => {
        const $ListSubheader = $(ListSubheader, 'ListSubheader') as any;

        return (
            <$ListSubheader component="div" id="nested-list-subheader">
                {label} ({count})
            </$ListSubheader>
        );
    })
    .compile();
