import { component } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import Button from '@material-ui/core/Button';
import * as React from 'react';
import { INavigation } from '../../../interfaces';

export const AccountMenuFooter = component('AccountMenuFooter')
    .use(mui)
    .use(theme)
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .style(({ theme }) => ({
        Container: {
            root: {
                '&&': {
                    justifyContent: 'center',
                    background: theme !== undefined ? theme.palette.grey[400] : 'none',
                    display: 'flex'
                }
            }
        },
        Button: {
            root: {
                '&&': {
                    fontSize: 12,
                    fontWeight: 500,
                    margin: '0px',
                    textTransform: 'none',
                    color: theme !== undefined ? theme.palette.grey[800] : 'none'
                }
            }
        }
    }))
    .render(({ $, navigation }) => {
        const $Button = $(Button, 'Button');

        const showLogout = !!navigation.account;

        return (
            <$.div.Container>
                {
                    showLogout
                    ? (
                        <$Button onClick={() => navigation.auth.logout() }>
                            Logga ut
                        </$Button>
                    )
                    : null
                }
            </$.div.Container>
        );
    })
    .compile();
