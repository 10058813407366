import auth0 = require('auth0-js');
import Cookies = require('js-cookie');
import store = require('store');
import { IAuthOptions, IAuthService } from '../interfaces';

export class AuthService implements IAuthService {
    private auth0: auth0.WebAuth;
    private tokenRenewalTimeout: any;

    public constructor(private options: IAuthOptions) {
        this.auth0 = new auth0.WebAuth({
            domain: options.domain,
            audience: options.audience,
            clientID: options.clientId,
            redirectUri: options.authUri,
            responseType: 'token id_token',
            scope: 'openid profile'
        });
    }

    public login() {
        this.auth0.authorize();
    }

    public resume() {
        const redirectUri = window.location.href;

        return this.handleAuthentication()
            .then((authed) => {
                if (!this.isAuthenticated()) {
                    return this.login();
                }

                if (authed) {
                    window.location.href = redirectUri;
                }
            })
            .catch((err) => {
                this.logout();
            });
    }

    public handleAuthentication(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    window.location.hash = '';
                    this.setSession(authResult);
                    return resolve(true);
                } else if (err) {
                    return reject(err);
                }

                resolve(false);
            });
        });
    }

    public isAuthenticated(): boolean {
        if (!store.get('expires_at')) {
            return false;
        }

        const expiresAt = JSON.parse(store.get('expires_at'));
        return new Date().getTime() < expiresAt;
    }

    public logout() {
        store.remove('id_token');
        store.remove('token');
        store.remove('expires_at');

        Cookies.remove('token');

        clearTimeout(this.tokenRenewalTimeout);

        this.auth0.logout({
            returnTo: this.options.logoutUri
        });
    }

    private setSession(authResult: auth0.Auth0DecodedHash) {
        const expiresAt = JSON.stringify(((authResult.expiresIn as number) * 1000) + new Date().getTime());

        store.set('id_token', authResult.idToken);
        store.set('token', authResult.accessToken);
        store.set('expires_at', expiresAt);

        Cookies.set('token', authResult.accessToken as string);

        this.scheduleRenewal();
    }

    private renewToken() {
        this.auth0.checkSession({}, (err, result) => {
            if (err) {
                return this.login();
            }

            this.setSession(result);
        });
    }

    private scheduleRenewal() {
        const expiresAt = JSON.parse(store.get('expires_at'));
        const delay = expiresAt - Date.now();

        if (delay > 0) {
            this.tokenRenewalTimeout = setTimeout(() => {
                this.renewToken();
            }, delay);
        }
    }
}

export const createAuthFromContext = () => {
    const basePath = window.location.origin;

    return new AuthService({
        domain: (window as any).APP_CONFIG.auth0.domain,
        audience: (window as any).APP_CONFIG.auth0.audience,
        clientId: (window as any).APP_CONFIG.auth0.clientId,
        authUri: basePath,
        logoutUri: basePath
    });
};
