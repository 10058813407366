import { component, types } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import Button from '@material-ui/core/Button';
import Search from '@material-ui/icons/Search';
import * as React from 'react';
import { ExtendedColorSet, INavigation, TintSet } from '../../interfaces';
import { UIIcon } from '../icon';
import { ChuiInputBase } from '../inputBase';

let searchTimeout: any;

export const extendedColorSetType = types.maybe(types.complex<ExtendedColorSet>());
export const tintSetType = types.maybe(types.complex<TintSet>());

export const XSearchbarIcon = component('XSearchbarIcon')
    .use(mui)
    .use(theme)
    .props({
        iconColor: extendedColorSetType.withDefault('primary'),
        iconTint: tintSetType.withDefault('main')
    })
    .style(() => ({
        IconButton: {
            'padding': 10,
            'background': 'none',
            'boxShadow': 'none',
            'borderRadius': 0,
            'border': 0,
            '&:hover': {
                background: 'rgba(255,255,255,.2)',
                boxShadow: 'none',
                borderRadius: 0
            }
        }
    }))
    .render(({
        $,
        iconColor,
        iconTint
    }) => {
        const $IconButton = $(Button, 'IconButton');

        return (
            <$IconButton
                variant="contained"
                aria-label="search"
            >
                <UIIcon
                    Icon={Search}
                    color={iconColor}
                    tint={iconTint}
                />
            </$IconButton>
        );
    })
    .compile();

export const XSearchbar = component('XSearchbar')
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .props({
        tags: types.arrayOf(types.str),
        companyId: types.maybe(types.str),
        modelYearId: types.maybe(types.str),
        elevation: types.maybe(types.num).withDefault(0),
        backgroundColor: extendedColorSetType.withDefault('paper'),
        backgroundTint: tintSetType.withDefault('main'),
        iconColor: extendedColorSetType.withDefault('primary'),
        iconTint: tintSetType.withDefault('main'),
        textColor: extendedColorSetType.withDefault('primary'),
        textTint: tintSetType.withDefault('main')
    })
    .reduce(({ companyId, modelYearId, navigation, tags, ...props }) => ({
        navigation,
        setXSearch: (event: any) => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }

            navigation.setXSearch({
                search: event.target.value,
                tags,
                companyId,
                modelYearId
            });

            searchTimeout = setTimeout(async () => {
                await navigation.loadXSearchResults();
            }, 200);
        },
        ...props
    }))
    .render(({
        $,
        backgroundColor,
        backgroundTint,
        elevation,
        iconColor,
        iconTint,
        navigation,
        textColor,
        textTint,
        setXSearch
    }) => {

        const value = navigation.xSearchQuery ? navigation.xSearchQuery.search : '';

        return (
            <ChuiInputBase
                value={value}
                onChange={setXSearch}
                elevation={elevation}
                backgroundColor={backgroundColor}
                backgroundTint={backgroundTint}
                textColor={textColor}
                textTint={textTint}
            >
                <XSearchbarIcon
                    iconColor={iconColor}
                    iconTint={iconTint}
                />
            </ChuiInputBase>
        );
    })
    .compile();
