import { IAuthService } from '../interfaces';

export class MockAuth implements IAuthService {
    public login() {
        return;
    }
    public resume() {
        return;
    }
    public async handleAuthentication() {
        return false;
    }
    public isAuthenticated() {
        return true;
    }
    public logout() {
        return;
    }
}
