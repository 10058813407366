import { component, ITypeTranslatedProps, types } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import Button from '@material-ui/core/Button';
import { Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { IAppMenuProps, IMenu, IMenuItem } from '../../interfaces';
import { IMenuIcons, Menu } from '../menu';

export const getInvertedColorStyling = (theme: Theme, color: string) => ({
    color: theme.palette.getContrastText(color)
});

export const appMenuProps = {
    menu: types.complex<IMenu>(),
    icons: types.maybe(types.complex<IMenuIcons>().withDefault({}))
};

export type AppMenuProps = ITypeTranslatedProps<typeof appMenuProps>;

export const AppMenu = component('AppMenu')
    .use(mui)
    .use(theme)
    .inject<boolean, 'inverted'>('inverted', 'inverted')
    .props(appMenuProps)
    .style(({ inverted, theme }) => {
        const MenuButton: any = {
            root: {
                minWidth: 0,
                minHeight: 0,
                textTransform: 'none' as 'none',
                fontSize: '0.75rem',
                color: '#000'
            }
        };

        if (inverted && theme) {
            const {
                primary
            } = theme.palette;

            MenuButton.textPrimary = getInvertedColorStyling(theme, primary.main);
        }

        return { MenuButton };
    })
    .state({
        anchorEl: types.complex<HTMLElement | null>()
    }, {
        setAnchorEl: () => (anchorEl: HTMLElement | null) => ({
            anchorEl
        })
    })
    .reduce(({ setAnchorEl, ...props }) => ({
        ...props,
        clickOpenMenu: (menuItem: IMenuItem, target: HTMLElement) => {
            setAnchorEl(target);
            menuItem.click();
        },
        focusOpenMenu: (menuItem: IMenuItem, target: HTMLElement) => {
            setAnchorEl(target);
            menuItem.focus();
        }
    }))
    .render(({ $, menu, icons, anchorEl, clickOpenMenu, focusOpenMenu }) => {
        const $MenuButton = $(Button, 'MenuButton');

        return (
            <>
                {
                    menu.menuTree.map((menuItem) => (
                        <React.Fragment key={menuItem.id}>
                            <$MenuButton
                                size="small"
                                variant="text"
                                color="inherit"
                                onClick={(e) => clickOpenMenu(menuItem, e.target as HTMLElement)}
                                onMouseEnter={(e) => focusOpenMenu(menuItem, e.target as HTMLElement)}
                            >
                                {menuItem.label}
                            </$MenuButton>
                            <Menu
                                anchorEl={menuItem.isExpanded ? anchorEl : null}
                                items={menuItem.children}
                                onClose={menu.closeAll}
                                placement="bottom-start"
                                icons={icons}
                            />
                        </React.Fragment>
                    ))
                }
            </>
        );
    })
    .compile();

export const getAppMenuComponentWithMenu = (menu: IMenu) => ({ icons }: IAppMenuProps) => (
    <AppMenu
        icons={icons}
        menu={menu}
    />
);
