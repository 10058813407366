import { component } from '@lardy/core';
import { mui } from '@lardy/mui';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Search from '@material-ui/icons/Search';
import * as React from 'react';
import { INavigation } from '../../../interfaces';

export const ApplicationMenuSearchbar = component('ApplicationMenuHeader')
    .use(mui)
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .style(() => ({
        Root: {
            margin: '30px 0',
            padding: '0px 0px 0px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 380
        },
        IconButton: {
            padding: 10,
            borderRadius: 0
        },
        InputBase: {
            marginLeft: 8,
            flex: 1
        }
    }))
    .render(({ $, navigation }) => {
        const $Root = $(Paper, 'Root');
        const $IconButton = $(Button, 'IconButton');
        const $InputBase = $(InputBase, 'InputBase');

        return (
            <$Root square>
                <$InputBase
                    placeholder="Search applications"
                    inputProps={{ 'aria-label': 'search applications' }}
                    value={navigation.applicationSearch}
                    onChange={(e: any) => navigation.setApplicationSearch(e.target.value)}
                />
                <$IconButton
                    variant="contained"
                    color="primary"
                    aria-label="search"
                >
                    <Search />
                </$IconButton>
            </$Root>
        );
    })
    .compile();
