import * as EventEmitter from 'eventemitter3';
import { action, computed, observable } from 'mobx';
import { ComponentType } from 'react';
import { IAppMenuProps, IMenu, IMenuData, IMenuItem, MenuEvent } from '../interfaces';
import { getAppMenuComponentWithMenu } from '../views/appMenu';
import { MenuItem } from './menuItem';

export class Menu implements IMenu {

    @computed public get menuItemData() {
        return this._data.menuItemData;
    }

    @computed public get id() {
        return this._data.id;
    }

    @computed public get title() {
        return this._data.title;
    }

    @computed public get menuItems(): IMenuItem[] {
        return this.menuItemData.map((menuItemData) => {
            return new MenuItem(this, menuItemData.id);
        });
    }

    @computed public get menuTree() {
        return this.menuItems.filter((menuItem) => {
            return menuItem.parentId === undefined;
        });
    }
    @observable public expandedMenuItemIds: string[] = [];
    @observable public hoveringMenuItemId?: string;

    public controller: EventEmitter<MenuEvent> = new EventEmitter();

    @observable private _data: IMenuData;

    public constructor(data: IMenuData) {
        this._data = data;
    }

    @action public closeAll = () => {
        this.expandedMenuItemIds = [];
    }

    @action public setExpandedMenuItemIds(ids: string[]) {
        this.expandedMenuItemIds = ids;
    }

    @action public setHoveringMenuItemId(id: string) {
        this.hoveringMenuItemId = id;
    }

    @action public setMenuItemModalActive(menuItemId: string, active: boolean) {
        const index = this.menuItemData.findIndex((data) => {
            return data.id === menuItemId;
        });

        if (index === -1) {
            throw new Error('Menu item not found: ' + menuItemId);
        }

        this.menuItemData[index].active = active;
    }

    public getRootComponent = (): ComponentType<IAppMenuProps> => {
        return getAppMenuComponentWithMenu(this);
    }
}
