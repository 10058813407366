import { component, types } from '@lardy/core';
import * as mui from '@lardy/mui';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import * as React from 'react';
import { hasAncestor, onClickOutside } from '../../decorators/onClickOutside';

export const PopoverInner = component('PopoverInner')
    .use(mui.mui)
    .props({
        onClose: types.fn<() => void>(),
        setArrowEl: types.fn<(el: HTMLDivElement | null) => void>(),
        id: types.str
    })
    .decorate(onClickOutside(({ onClose, id }: any, el) => {
        if (!hasAncestor(el, `chaz-global---ignore-clickoutside-popupMenu-${id}`)) {
            onClose();
        }
    }))
    .style(() => ({
        Arrow: {
            root: {
                '&&': {
                    'position': 'absolute' as 'absolute',
                    'fontSize': 7,
                    'width': '3em',
                    'height': '3em',

                    '&:before': {
                        content: '""',
                        margin: 'auto',
                        display: 'block',
                        width: '0',
                        height: '0',
                        borderStyle: 'solid',
                        transform: 'translateX(-50%)'
                    },

                    '[x-placement*=\"bottom\"] &': {
                        width: 0,
                        height: 0,
                        borderLeft: '1em solid transparent',
                        borderRight: '1em solid transparent',
                        borderBottom: '1em solid black',
                        marginTop: '-1em'
                    },

                    '[x-placement*=\"bottom\"] &:before': {
                        borderWidth: '0 1em 1em 1em',
                        borderColor: 'transparent transparent white transparent'
                    },

                    '[x-placement*=\"top\"] &': {
                        bottom: '0',
                        width: '0',
                        height: '0',
                        borderLeft: '1em solid transparent',
                        borderRight: '1em solid transparent',
                        borderTop: '1em solid #fff',
                        marginBottom: '-0.9em'
                    },

                    '[x-placement*=\"top\"] &:before': {
                        borderWidth: '1em 1em 0 1em',
                        borderColor: 'white transparent transparent transparent'
                    },

                    '[x-placement*=\"right\"] &': {
                        left: '0',
                        width: '0',
                        height: '0',
                        borderTop: '1em solid transparent',
                        borderBottom: '1em solid transparent',
                        borderRight: '1em solid #fff',
                        marginLeft: '-0.9em'
                    },

                    '[x-placement*=\"right\"] &:before': {
                        borderWidth: '1em 1em 1em 0',
                        borderColor: 'transparent white transparent transparent'
                    },

                    '[x-placement*=\"left\"] &': {
                        right: '0',
                        width: '0',
                        height: '0',
                        borderTop: '1em solid transparent',
                        borderBottom: '1em solid transparent',
                        borderLeft: '1em solid #fff',
                        marginRight: '-0.9em'
                    },

                    '[x-placement*=\"left\"] &:before': {
                        borderWidth: '1em 0 1em 1em',
                        borderColor: 'transparent transparent white transparent'
                    }
                }
            }
        },
        Paper: {
            minWidth: 320,
            padding: '20px auto'
        }
    }))
    .render(({ $, children, id, setArrowEl }) => {
        const $Arrow = $('span', 'Arrow');
        const $Paper = $(Paper, 'Paper');

        return (
            <>
                <$Arrow ref={setArrowEl} />
                <$Paper square>
                    <span className={`chaz-global---ignore-clickoutside-popupMenu-${id}`}>
                        {children}
                    </span>
                </$Paper>
            </>
        );
    })
    .compile();

export const Popover = component('Popover')
    .use(mui.mui)
    .style(() => ({
        Popper: {
            root: {
                '&&': {
                    zIndex: 1300
                }
            }
        }
    }))
    .props({
        anchorEl: types.maybe(types.complex<HTMLDivElement>()),
        placement: types.complex<'bottom-start' | 'bottom' | 'bottom-end'>(),
        onClose: types.maybe(types.fn<() => void>()),
        id: types.str
    })
    .state({
        arrowEl: types.complex<HTMLDivElement | null>().withDefault(null)
    }, {
        setArrowEl: () => (arrowEl: HTMLDivElement | null) => {
            return{
                arrowEl
            };
        }
    })
    .reduce((props) => ({
        ...props,
        onClose: () => {
            if (props.onClose) {
                props.onClose();
            }
        },
        setArrowEl: (ref: HTMLDivElement | null) => {
            if (ref !== null && props.arrowEl !== ref) {
                props.setArrowEl(ref);
            }
        }
    }))
    .render(({ $, anchorEl, arrowEl, setArrowEl, children, placement, onClose, id }) => {
        const $Popper = $(Popper, 'Popper');

        return (
            <>
                <$Popper
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    placement={placement}
                    disablePortal={false}
                    modifiers={{
                        flip: {
                            enabled: true
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'scrollParent'
                        },
                        arrow: {
                            enabled: true,
                            element: arrowEl
                        }
                    }}
                >
                    <PopoverInner
                        onClose={onClose}
                        setArrowEl={setArrowEl}
                        id={id}
                    >
                        {children}
                    </PopoverInner>
                </$Popper>
            </>
        );
    })
    .compile();
