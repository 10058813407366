import { component, types } from '@lardy/core';
import { mui } from '@lardy/mui';
import Apps from '@material-ui/icons/Apps';
import * as React from 'react';
import { INavigation } from '../../interfaces';
import { PopupMenu } from '../popupMenu';
import { ApplicationMenuInner } from './applicationMenuInner';

export const ApplicationMenu = component('ApplicationMenu')
    .use(mui)
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .props({
        color: types.maybe(
            types.complex<'inherit' | 'disabled' | 'error' | 'action' | 'primary' | 'secondary'>()
                .withDefault('inherit')
        ),
        tint: types.maybe(
            types.complex<'dark' | 'main' | 'light'>()
                .withDefault('main')
        )
    })
    .reduce(({ color, navigation, tint }) => ({
        color,
        onOpen: () => {
            navigation.loadApplications();
        },
        tint
    }))
    .render(({ color, onOpen, tint }) => {
        return (
            <PopupMenu
                Icon={Apps}
                onOpen={onOpen}
                id="popup-menu-application"
                color={color}
                tint={tint}
            >
                <ApplicationMenuInner />
            </PopupMenu>
        );
    })
    .compile();
