import {
    IAccountData, ICompanyNodeData, IDataService,
    IModelYearData, IXSearchResult, TApplicationData
} from '../interfaces';

export interface IMockDataServiceOpts {
    accountDelay?: number;
    applicationsDelay?: number;
    companyTreeDelay?: number;
    modelYearsDelay?: number;
    xSearchDelay?: number;
}

export class MockDataService implements IDataService {
    private opts: IMockDataServiceOpts = {};

    public constructor(opts?: IMockDataServiceOpts) {
        if (opts) {
            this.opts = opts;
        }
    }

    public getAccount(): Promise<IAccountData> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    userId: '493f2797-de67-4edb-a239-5b219286b968',
                    emailAddress: 'max.bolotin@fbinhouse.se',
                    name: 'Max Bolotin',
                    role: {
                        roleId: 'c796e0d9-734f-43c5-96ec-75cc1810b332',
                        name: 'Administrator',
                        permissions: [
                            'all'
                        ],
                        profileCategories: [],
                        external: false,
                        rootCompanyOnly: false,
                        system: true
                    },
                    companies: [
                        {
                            companyId: null,
                            companyNumber: null,
                            parentCompanyId: null,
                            name: null,
                            branchCode: null,
                            branchCodeAliases: null,
                            localisationId: null,
                            authId: null,
                            afPassword: null,
                            info: null,
                            ppnm__bulkImportUrl: '',
                            ppnm__bulkExportUrl: ''
                        }
                    ],
                    active: true,
                    dailyDigest: false,
                    confirmed: true,
                    lastLoginDate: '2018-10-31T12:07:14.548Z',
                    reminderDate: '2018-06-29T00:01:25.684Z'
                } as IAccountData);
            }, this.opts.accountDelay || 0);
        });
    }

    public getApplications(): Promise<TApplicationData[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve([
                    {
                        id: '1',
                        title: 'Digitala Marknadsverktyget',
                        logo: 'D',
                        tag: 'dms',
                        protocol: 'https',
                        hostname: 'dms.fbinhouse.se',
                        favorite: true,
                        visible: true,
                        subdomainData: [],
                        theme: {
                            primaryColor: 'rgb(21, 101, 192)',
                            secondaryColor: '#4caf50'
                        }
                    },
                    {
                        id: '2',
                        title: 'Volvo Dealership Web',
                        logo: 'V',
                        tag: 'vdw',
                        protocol: 'https',
                        hostname: 'vdw.fbinhouse.se',
                        favorite: false,
                        visible: true,
                        subdomainData: [],
                        theme: {
                            primaryColor: 'rgba(36, 93, 242, 1)',
                            secondaryColor: 'rgba(251, 212, 78, 1)'
                        }
                    },
                    {
                        id: '3',
                        title: 'Volvo Magasin',
                        logo: 'VM',
                        tag: 'volvomagasin',
                        protocol: 'https',
                        hostname: 'volvomagasin.fbinhouse.se',
                        favorite: true,
                        visible: true,
                        subdomainData: [],
                        theme: {
                            primaryColor: 'rgb(253, 70, 131)',
                            secondaryColor: 'rgb(45, 181, 48)'
                        }
                    },
                    {
                        id: '4',
                        title: 'Teknisk data',
                        logo: 'T',
                        tag: 'volvotechdata',
                        protocol: 'https',
                        hostname: 'volvotechdata.fbinhouse.se',
                        favorite: false,
                        visible: true,
                        subdomainData: [],
                        theme: {
                            primaryColor: 'rgba(36, 93, 242, 1)',
                            secondaryColor: 'rgba(251, 212, 78, 1)'
                        }
                    },
                    {
                        id: '5',
                        title: 'Prislistor',
                        logo: 'PL',
                        tag: 'volvopricelist',
                        protocol: 'https',
                        hostname: 'volvopricelist.fbinhouse.se',
                        favorite: false,
                        visible: true,
                        subdomainData: [],
                        theme: {
                            primaryColor: 'rgba(36, 93, 242, 1)',
                            secondaryColor: 'rgba(251, 212, 78, 1)'
                        }
                    },
                    {
                        id: '6',
                        title: 'Pro Partner Nyhetsbrev',
                        logo: 'PP',
                        tag: 'propartner',
                        protocol: 'https',
                        hostname: 'propartner.fbinhouse.se',
                        favorite: false,
                        visible: true,
                        subdomainData: [],
                        theme: {
                            primaryColor: 'rgba(36, 93, 242, 1)',
                            secondaryColor: 'rgba(251, 212, 78, 1)'
                        }
                    },
                    {
                        id: 'my-pages',
                        title: 'Volvo Chaz My pages',
                        logo: 'M',
                        tag: 'mypages',
                        protocol: 'https',
                        hostname: 'mypages.fbinhouse.se',
                        favorite: false,
                        visible: false,
                        subdomainData: [
                            {
                                id: 'dashboard',
                                title: 'Dashboard',
                                pathnames: ['/', '/dashboard'],
                                source: '',
                                tag: 'dashboard'
                            },
                            {
                                id: 'account',
                                title: 'Account',
                                pathnames: ['/account'],
                                source: '',
                                tag: 'account'
                            }
                        ],
                        theme: {
                            primaryColor: 'rgba(36, 93, 242, 1)',
                            secondaryColor: 'rgba(251, 212, 78, 1)'
                        }
                    }
                ]);
            }, this.opts.applicationsDelay || 0);
        });
    }

    public getCompanyTree(): Promise<ICompanyNodeData[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve([
                    {
                        companyId: 'cfea8de1-c1d0-4061-8853-3f309d8b7020',
                        companyNumber: '484',
                        parentCompanyId: null,
                        name: 'Rejmes Bil Halmstad',
                        branchCode: '0226',
                        branchCodeAliases: [],
                        localisationId: 'SE5497',
                        authId: 484,
                        afPassword: 'jiboro',
                        info: {
                        profile: {
                            id: '0226',
                            name: 'Rejmes Personvagnar AB',
                            structure_type: 'Center',
                            district: '0226',
                            occupation: 'Sälj                Service',
                            center_district: '0226',
                            visit_address: 'Kristinehedsvägen',
                            visit_post_number: '30244',
                            visit_post_town: 'Halmstad',
                            mail_address: 'Kristinehedsvägen',
                            mail_post_number: '30244',
                            mail_post_town: 'Halmstad',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '010-7883000',
                            fax: '035-280 91 57',
                            company_email: 'info@rejmesbil.se',
                            used_cars_email: 'intressehalmstad@rejmesbil.se',
                            new_cars_email: 'intressehalmstad@rejmesbil.se',
                            new_contact_email: 'anna.mahrstrom@rejmesbil.se',
                            home_page: 'http://www.rejmesbil.se/',
                            country: 'Sweden',
                            latitude: '56.6840146',
                            longitude: '12.8905325',
                            business_group: '0226',
                            price_localization: 'SE5497'
                        },
                        facilities: []
                        }
                    },
                    {
                        companyId: '65e4cdbe-3a02-49dc-9541-bf99dc18a06f',
                        companyNumber: 'S235',
                        parentCompanyId: 'cfea8de1-c1d0-4061-8853-3f309d8b7020',
                        name: 'Rejmes Bil Laholm',
                        branchCode: '0235',
                        branchCodeAliases: [],
                        localisationId: 'SE5497',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0235',
                            name: 'Rejmes Personvagnar AB',
                            structure_type: 'Filial',
                            district: '0235',
                            occupation: 'Sälj                Service',
                            center_district: '0226',
                            visit_address: 'Industrigatan 6',
                            visit_post_number: '31234',
                            visit_post_town: 'Laholm',
                            mail_address: 'Industrigatan 6',
                            mail_post_number: '31234',
                            mail_post_town: 'Laholm',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '010-7883000',
                            fax: '0430-73209',
                            company_email: 'info@rejmesbil.se',
                            used_cars_email: 'intresselaholm@rejmesbil.se',
                            new_cars_email: 'intresselaholm@rejmesbil.se',
                            new_contact_email: 'anna.mahrstrom@rejmesbil.se',
                            home_page: 'http://www.rejmesbil.se/',
                            country: 'Sweden',
                            latitude: '56.5011633',
                            longitude: '13.0295234',
                            business_group: '0226',
                            price_localization: 'SE5497'
                        },
                        facilities: [
                            {
                            name: 'Butik',
                            telephone: '',
                            sort_order: 0,
                            opening_hours: {
                                monday: {
                                from: '08:00',
                                to: '16:00'
                                },
                                tuesday: {
                                from: '08:00',
                                to: '16:00'
                                },
                                wednesday: {
                                from: '08:00',
                                to: '16:00'
                                },
                                thursday: {
                                from: '08:00',
                                to: '16:00'
                                },
                                friday: {
                                from: '08:00',
                                to: '16:00'
                                }
                            },
                            exceptions: [
                                {
                                reason: 'Jul afton',
                                date: '2018-12-23T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                }
                            ],
                            department: 'store'
                            },
                            {
                            name: 'Showroom/butik',
                            telephone: '',
                            sort_order: 1,
                            opening_hours: {
                                monday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                tuesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                wednesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                thursday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                friday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                saturday: {
                                from: '10:00',
                                to: '15:00'
                                }
                            },
                            exceptions: [
                                {
                                reason: 'Nyårsafton',
                                date: '2017-12-30T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Midsommarafton',
                                date: '2017-06-22T22:00:00.000Z',
                                opening: '',
                                closing: '17:00',
                                closed: false
                                }
                            ],
                            department: 'showroom'
                            },
                            {
                            name: 'Verkstad',
                            telephone: '',
                            sort_order: 2,
                            opening_hours: {
                                monday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                thursday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                friday: {
                                from: '07:00',
                                to: '16:00'
                                }
                            },
                            exceptions: [],
                            department: 'service'
                            }
                        ]
                        }
                    },
                    {
                        companyId: '6eea46b7-a68f-442c-b0f5-f47685e39800',
                        companyNumber: 'S225',
                        parentCompanyId: 'cfea8de1-c1d0-4061-8853-3f309d8b7020',
                        name: 'Rejmes Bil Torup',
                        branchCode: '0225',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0225',
                            name: 'Rejmes Personvagnar AB',
                            structure_type: 'Filial',
                            district: '0225',
                            occupation: 'Service             Sälj',
                            center_district: '0226',
                            visit_address: 'Nissastigen',
                            visit_post_number: '31441',
                            visit_post_town: 'Torup',
                            mail_address: 'Nissastigen',
                            mail_post_number: '31441',
                            mail_post_town: 'Torup',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0345-48650',
                            fax: '0345-48658',
                            company_email: 'info@rejmesbil.se',
                            used_cars_email: 'cecilia.persson@rejmesbil.se',
                            new_cars_email: 'anders.olsson@rejmesbil.se',
                            new_contact_email: 'anna.mahrstrom@rejmesbil.se                       SE',
                            home_page: 'www.rejmesbil.se',
                            country: 'Sweden',
                            latitude: '56.9502',
                            longitude: '13.08019'
                        }
                        }
                    },
                    {
                        companyId: 'c25e7ffd-0c83-45e1-9806-9ec742b37089',
                        companyNumber: 'S240',
                        parentCompanyId: 'cfea8de1-c1d0-4061-8853-3f309d8b7020',
                        name: 'Rejmes Bil Falkenberg',
                        branchCode: '0240',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0240',
                            name: 'Rejmes Personvagnar AB',
                            structure_type: 'Filial',
                            district: '0240',
                            occupation: 'Sälj                Service',
                            center_district: '0226',
                            visit_address: 'Varbergsvägen 59-61',
                            visit_post_number: '31101',
                            visit_post_town: 'Falkenberg',
                            mail_address: 'Varbergsvägen 59-61',
                            mail_post_number: '31136',
                            mail_post_town: 'Falkenberg',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '010-7883000',
                            fax: '0346-48537',
                            company_email: '',
                            used_cars_email: 'intressefalkenberg@rejmesbil.se',
                            new_cars_email: 'intressefalkenberg@rejmesbil.se',
                            new_contact_email: 'anna.mahrstrom@rejmesbil.se',
                            home_page: 'http://www.rejmesbil.se/',
                            country: 'Sweden',
                            latitude: '56.9148728',
                            longitude: '12.5043944',
                            business_group: '0226',
                            price_localization: 'SE5497'
                        },
                        facilities: [
                            {
                            name: 'Öppettider Showroom',
                            telephone: '',
                            sort_order: 0,
                            opening_hours: {
                                monday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                tuesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                wednesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                thursday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                friday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                saturday: {
                                from: '10:00',
                                to: '15:00'
                                },
                                sunday: null
                            },
                            exceptions: [],
                            department: 'showroom'
                            },
                            {
                            name: 'Öppettider Butik',
                            telephone: '',
                            sort_order: 1,
                            opening_hours: {
                                friday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                thursday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                monday: {
                                from: '07:00',
                                to: '16:00'
                                }
                            },
                            exceptions: [],
                            department: 'store'
                            },
                            {
                            name: 'Öppettider Verkstad',
                            telephone: '',
                            sort_order: 2,
                            opening_hours: {
                                friday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                thursday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                monday: {
                                from: '07:00',
                                to: '16:00'
                                }
                            },
                            exceptions: [],
                            department: ''
                            }
                        ]
                        }
                    },
                    {
                        companyId: 'dc197091-aeae-4839-9a71-02ba453d426a',
                        companyNumber: '266',
                        parentCompanyId: null,
                        name: 'Volvo Default',
                        branchCode: '0790',
                        branchCodeAliases: [],
                        localisationId: 'SE0000',
                        authId: 266,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '451b6900-23c2-492a-9998-3373f24c4e6a',
                        companyNumber: '520',
                        parentCompanyId: null,
                        name: 'Lidbil AB Lidköping',
                        branchCode: '0237',
                        branchCodeAliases: [],
                        localisationId: 'SE9687',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0237',
                            name: 'Lidbil AB',
                            structure_type: 'Center',
                            district: '0237',
                            occupation: 'Sälj                Service',
                            center_district: '0237',
                            visit_address: 'Mellbygatan 48',
                            visit_post_number: '53119',
                            visit_post_town: 'Lidköping',
                            mail_address: 'Box 911',
                            mail_post_number: '53119',
                            mail_post_town: 'Lidköping',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0510-86600',
                            fax: '0510-86608',
                            company_email: 'info@lidbil.se',
                            used_cars_email: 'gabriella.fernhag@lidbil.se',
                            new_cars_email: 'gabriella.fernhag@lidbil.se',
                            new_contact_email: 'erik.andersson@lidbil.se',
                            home_page: 'http://www.lidbil.se/',
                            country: 'Sweden',
                            latitude: '58.4964031',
                            longitude: '13.1486884',
                            business_group: '0237',
                            price_localization: 'SE9687'
                        }
                        }
                    },
                    {
                        companyId: '611d935c-f6bc-4b56-9502-9a7465e5bdf6',
                        companyNumber: 'S239',
                        parentCompanyId: '451b6900-23c2-492a-9998-3373f24c4e6a',
                        name: 'Lidbil Vara',
                        branchCode: '0239',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0239',
                            name: 'Lidbil AB',
                            structure_type: 'Filial',
                            district: '0239',
                            occupation: 'Sälj                Service',
                            center_district: '0237',
                            visit_address: 'Torggatan 54',
                            visit_post_number: '53450',
                            visit_post_town: 'Vara',
                            mail_address: 'Torggatan 54',
                            mail_post_number: '53450',
                            mail_post_town: 'Vara',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0512-33540',
                            fax: '0512-16262',
                            company_email: 'info@lidbil.se',
                            used_cars_email: 'gabriella.fernhag@lidbil.se',
                            new_cars_email: 'joakim.kjellgren@lidbil.se',
                            new_contact_email: 'erik.andersson@lidbil.se',
                            home_page: 'http://www.lidbil.se/',
                            country: 'Sweden',
                            latitude: '58.2560332',
                            longitude: '12.9630537',
                            business_group: '0237',
                            price_localization: 'SE9687'
                        }
                        }
                    },
                    {
                        companyId: '91f5ed88-f04b-4669-906a-b8dc802567a4',
                        companyNumber: '509',
                        parentCompanyId: null,
                        name: 'Kristianstads Automobil AB',
                        branchCode: '0133',
                        branchCodeAliases: [],
                        localisationId: 'SE9846',
                        authId: null,
                        afPassword: 'kagipa',
                        info: {
                        profile: {
                            id: '0133',
                            name: 'Kristianstads Automobil Personvagnar AB',
                            structure_type: 'Center',
                            district: '0133',
                            occupation: 'Sälj                Service',
                            center_district: '0133',
                            visit_address: 'Blekingevägen 107',
                            visit_post_number: '29150',
                            visit_post_town: 'Kristianstad',
                            mail_address: 'Box 540',
                            mail_post_number: '29125',
                            mail_post_town: 'Kristianstad',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '044-206000',
                            fax: '044-122275',
                            company_email: '',
                            used_cars_email: 'henrik.bergdahl@kristianstadsautomobil.se',
                            new_cars_email: 'mattias.persson@kristianstadsautomobil.se',
                            new_contact_email: 'fredrik.skanse@kristianstadsautomobil.se',
                            home_page: 'http://www.kristianstadsautomobil.se/',
                            country: 'Sweden',
                            latitude: '56.0284227',
                            longitude: '14.2112486',
                            business_group: '0133',
                            price_localization: 'SE9846'
                        }
                        }
                    },
                    {
                        companyId: 'e716f91e-6739-4f25-8b42-aba5e8a523af',
                        companyNumber: '507',
                        parentCompanyId: null,
                        name: 'Ahlberg Bil AB Karlshamn',
                        branchCode: '0125',
                        branchCodeAliases: [],
                        localisationId: 'SE2588',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0125',
                            name: 'Johan Ahlberg Bil AB',
                            structure_type: 'Center',
                            district: '0125',
                            occupation: 'Sälj                Service',
                            center_district: '0125',
                            visit_address: 'Korpadalsvägen 2, Asarum',
                            visit_post_number: '37451',
                            visit_post_town: 'Karlshamn',
                            mail_address: 'Box 33',
                            mail_post_number: '37421',
                            mail_post_town: 'Karlshamn',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0454-33000',
                            fax: '0454-33099',
                            company_email: 'annelie.svensson@ahlbergbil.se',
                            used_cars_email: 'per.eriksson@ahlbergbil.se',
                            new_cars_email: 'per.eriksson@ahlbergbil.se',
                            new_contact_email: 'roland.persson@ahlbergbil.se',
                            home_page: 'http://www.ahlbergbil.se/',
                            country: 'Sweden',
                            latitude: '56.1938098',
                            longitude: '14.8441416',
                            business_group: '0125',
                            price_localization: 'SE2588'
                        }
                        }
                    },
                    {
                        companyId: '0aefb51a-29cc-42fc-8f42-37c242948f34',
                        companyNumber: 'S361',
                        parentCompanyId: 'e716f91e-6739-4f25-8b42-aba5e8a523af',
                        name: 'Ahlberg Bil Ljungby',
                        branchCode: '0361',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0361',
                            name: 'Johan Ahlberg Bil AB',
                            structure_type: 'Center',
                            district: '0361',
                            occupation: 'Sälj                Service',
                            center_district: '0361',
                            visit_address: 'Långgatan 25',
                            visit_post_number: '341 32',
                            visit_post_town: 'Ljungby',
                            mail_address: 'Box 192',
                            mail_post_number: '34124',
                            mail_post_town: 'Ljungby',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0372-67900',
                            fax: '0372-67901',
                            company_email: 'info.ly@ahlbergbil.se',
                            used_cars_email: 'pontus.hoglund@ahlbergbil.se',
                            new_cars_email: 'pontus.hoglund@ahlbergbil.se',
                            new_contact_email: 'pontus.hoglund@ahlbergbil.se',
                            home_page: 'http://www.ahlbergbil.se/',
                            country: 'Sweden',
                            latitude: '56.8183508',
                            longitude: '13.9164893',
                            business_group: '0125',
                            price_localization: 'SE2588'
                        },
                        facilities: [
                            {
                            name: 'Ahlberg Bil Ljungby',
                            telephone: '0372 - 679 00',
                            sort_order: 0,
                            opening_hours: {
                                monday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                tuesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                wednesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                thursday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                friday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                saturday: {
                                from: '10:00',
                                to: '13:00'
                                }
                            },
                            exceptions: [],
                            department: 'showroom'
                            },
                            {
                            name: 'Ahlberg Bil Ljungby',
                            telephone: '',
                            sort_order: 1,
                            opening_hours: {
                                monday: {
                                from: '07:00',
                                to: '16:30'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '16:30'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '16:30'
                                },
                                thursday: {
                                from: '07:00',
                                to: '16:30'
                                },
                                friday: {
                                from: '07:00',
                                to: '16:30'
                                },
                                saturday: null,
                                sunday: null
                            },
                            exceptions: [],
                            department: 'service'
                            }
                        ]
                        }
                    },
                    {
                        companyId: 'f3ebbe22-5277-4124-b2f5-8e435e04f71f',
                        companyNumber: 'S129',
                        parentCompanyId: 'e716f91e-6739-4f25-8b42-aba5e8a523af',
                        name: 'Ahlberg Bil Karlskrona',
                        branchCode: '0129',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0129',
                            name: 'Johan Ahlberg Bil AB',
                            structure_type: 'Filial',
                            district: '0129',
                            occupation: 'Sälj                Service',
                            center_district: '0125',
                            visit_address: 'Gullbernavägen 22',
                            visit_post_number: '37147',
                            visit_post_town: 'Karlskrona',
                            mail_address: 'Gullbernavägen 22',
                            mail_post_number: '37147',
                            mail_post_town: 'Karlskrona',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0455-615840',
                            fax: '0455-20000',
                            company_email: 'annelie.svensson@ahlbergbil.se',
                            used_cars_email: 'fredrik.huselius@ahlbergbil.se',
                            new_cars_email: 'fredrik.huselius@ahlbergbil.se',
                            new_contact_email: 'fredrik.huselius@ahlbergbil.se',
                            home_page: 'http://www.ahlbergbil.se/',
                            country: 'Sweden',
                            latitude: '56.2025552',
                            longitude: '15.6368507',
                            business_group: '0125',
                            price_localization: 'SE2588'
                        }
                        }
                    },
                    {
                        companyId: '35c6898d-d58b-4a86-9c87-2fab65e1a1e2',
                        companyNumber: 'S135',
                        parentCompanyId: 'e716f91e-6739-4f25-8b42-aba5e8a523af',
                        name: 'Ahlberg Bil Sölvesborg',
                        branchCode: '0135',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '68e358a7-1637-47b6-a054-eec31259a1ed',
                        companyNumber: 'S126',
                        parentCompanyId: 'e716f91e-6739-4f25-8b42-aba5e8a523af',
                        name: 'Ahlberg Bil Olofström',
                        branchCode: '0126',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: '2a462379-b087-4f45-986e-4b5bd0995078',
                        companyNumber: '516',
                        parentCompanyId: null,
                        name: 'Bil-Nilsson Alingsås',
                        branchCode: '0201',
                        branchCodeAliases: [],
                        localisationId: 'SE2375',
                        authId: null,
                        afPassword: 'lopado',
                        info: {
                        profile: {
                            id: '0201',
                            name: 'Bil-Nilsson i Alingsås AB',
                            structure_type: 'Center',
                            district: '0201',
                            occupation: 'Sälj                Service',
                            center_district: '0201',
                            visit_address: 'Stråhles Allé 6',
                            visit_post_number: '44132',
                            visit_post_town: 'Alingsås',
                            mail_address: 'Box 654',
                            mail_post_number: '44118',
                            mail_post_town: 'Alingsås',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0322-668866',
                            fax: '0322-668801',
                            company_email: 'info@bilnilsson.com',
                            used_cars_email: 'info@bilnilsson.com',
                            new_cars_email: 'andreas.pettersson@bilnilsson.com',
                            new_contact_email: 'info@bilnilsson.com',
                            home_page: 'http://www.bilnilsson.com/',
                            country: 'Sweden',
                            latitude: '57.9248457',
                            longitude: '12.5320394',
                            business_group: '0201',
                            price_localization: 'SE2375'
                        }
                        }
                    },
                    {
                        companyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        companyNumber: '517',
                        parentCompanyId: null,
                        name: 'Bilbolaget Nord Östersund',
                        branchCode: '0637',
                        branchCodeAliases: [],
                        localisationId: 'SE9427',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0637',
                            name: 'Bilbolaget Nord AB',
                            structure_type: 'Center',
                            district: '0637',
                            occupation: 'Sälj                Service',
                            center_district: '0637',
                            visit_address: 'Fagerbacken 53',
                            visit_post_number: '83125',
                            visit_post_town: 'Östersund',
                            mail_address: 'Box 3004',
                            mail_post_number: '83103',
                            mail_post_town: 'Östersund',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '063-149400',
                            fax: '063-574126',
                            company_email: 'ostersund@bilbolaget.com',
                            used_cars_email: 'mattias.gunnarsson@bilbolaget.com',
                            new_cars_email: 'Ylva.andersson@bilbolaget.com',
                            new_contact_email: 'markus.svanstrom@bilbolaget.com',
                            home_page: 'http://www.bilbolaget.com/',
                            country: 'Sweden',
                            latitude: '63.1704169',
                            longitude: '14.6790637',
                            business_group: '0637',
                            price_localization: 'SE9427'
                        }
                        }
                    },
                    {
                        companyId: '9bac5b18-46cf-4eac-8384-29a286807c11',
                        companyNumber: 'S729',
                        parentCompanyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        name: 'Bilbolaget Nord Umeå',
                        branchCode: '0729',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0729',
                            name: 'Bilbolaget Nord AB',
                            structure_type: 'Center',
                            district: '0729',
                            occupation: 'Sälj                Service',
                            center_district: '0729',
                            visit_address: 'Verkstadsgatan 9-11',
                            visit_post_number: '90124',
                            visit_post_town: 'Umeå',
                            mail_address: 'Box 1422',
                            mail_post_number: '90124',
                            mail_post_town: 'Umeå',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '090-177100',
                            fax: '090-177200',
                            company_email: 'umea@bilbolaget.com',
                            used_cars_email: 'peter.olofsson@bilbolaget.com',
                            new_cars_email: 'peter.olofsson@bilbolaget.com',
                            new_contact_email: 'jakob.ederlof@bilbolaget.com',
                            home_page: 'http://www.bilbolaget.com/',
                            country: 'Sweden',
                            latitude: '63.8137034',
                            longitude: '20.2474729',
                            business_group: '0637',
                            price_localization: 'SE9427'
                        }
                        }
                    },
                    {
                        companyId: 'e1005f51-7058-4bee-8738-a359cb5da6cc',
                        companyNumber: 'S740',
                        parentCompanyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        name: 'Bilbolaget Nord Lycksele',
                        branchCode: '0740',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0740',
                            name: 'Bilbolaget Nord AB',
                            structure_type: 'Filial',
                            district: '0740',
                            occupation: 'Sälj                Service',
                            center_district: '0729',
                            visit_address: 'Lilltjärnsvägen 8',
                            visit_post_number: '92133',
                            visit_post_town: 'Lycksele',
                            mail_address: 'Lilltjärnsvägen 8',
                            mail_post_number: '92133',
                            mail_post_town: 'Lycksele',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0950-34700',
                            fax: '0950-34719',
                            company_email: 'lycksele@bilbolaget.com',
                            used_cars_email: 'stellan.berg@bilbolaget.com',
                            new_cars_email: 'stellan.berg@bilbolaget.com',
                            new_contact_email: 'fredrik.astrom@bilbolaget.com',
                            home_page: 'http://www.bilbolaget.com/',
                            country: 'Sweden',
                            latitude: '64.5996546',
                            longitude: '18.6666754',
                            business_group: '0637',
                            price_localization: 'SE9427'
                        }
                        }
                    },
                    {
                        companyId: '701165c6-a982-4e00-83b6-84026ba424f1',
                        companyNumber: 'S635',
                        parentCompanyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        name: 'Bilbolaget Nord Örnsköldsvik',
                        branchCode: '0635',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0635',
                            name: 'Bilbolaget Nord AB',
                            structure_type: 'Center',
                            district: '0635',
                            occupation: 'Sälj                Service',
                            center_district: '0635',
                            visit_address: 'Ångermanlandsgatan 28',
                            visit_post_number: '89138',
                            visit_post_town: 'Örnsköldsvik',
                            mail_address: 'Box 222',
                            mail_post_number: '89125',
                            mail_post_town: 'Örnsköldsvik',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0660-86700',
                            fax: '0660-13700',
                            company_email: 'ornskoldsvik@bilbolaget.com',
                            used_cars_email: 'andreas.bystrom@bilbolaget.com',
                            new_cars_email: 'andreas.bystrom@bilbolaget.com',
                            new_contact_email: 'anders.bergstrom@bilbolaget.com',
                            home_page: 'http://www.bilbolaget.com/',
                            country: 'Sweden',
                            latitude: '63.3023987',
                            longitude: '18.7210399',
                            business_group: '0637',
                            price_localization: 'SE9427'
                        }
                        }
                    },
                    {
                        companyId: '52086b83-e025-46a4-b201-a4fab82996f6',
                        companyNumber: 'S620',
                        parentCompanyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        name: 'Bilbolaget Nord Sollefteå',
                        branchCode: '0620',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0620',
                            name: 'Bilbolaget Nord AB',
                            structure_type: 'Filial',
                            district: '0620',
                            occupation: 'Sälj                Service',
                            center_district: '0635',
                            visit_address: 'Storgatan 135 A',
                            visit_post_number: '88141',
                            visit_post_town: 'Sollefteå',
                            mail_address: 'Storgatan 135 A',
                            mail_post_number: '88141',
                            mail_post_town: 'Sollefteå',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0620-57400',
                            fax: '0620-12502',
                            company_email: 'solleftea@bilbolaget.com',
                            used_cars_email: 'mats.norlen@bilbolaget.com',
                            new_cars_email: 'mats.norlen@bilbolaget.com',
                            new_contact_email: 'hans.stromqvist@bilbolaget.com',
                            home_page: 'http://www.bilbolaget.com/',
                            country: 'Sweden',
                            latitude: '63.1623283',
                            longitude: '17.305498',
                            business_group: '0637',
                            price_localization: 'SE9427'
                        }
                        }
                    },
                    {
                        companyId: '0534bc98-3ee0-4206-96ca-0ba0c6ecd5c3',
                        companyNumber: 'S613',
                        parentCompanyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        name: 'Bilbolaget Nord Härnösand',
                        branchCode: '0613',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0613',
                            name: 'Bilbolaget Nord AB',
                            structure_type: 'Filial',
                            district: '0613',
                            occupation: 'Sälj                Service',
                            center_district: '0635',
                            visit_address: 'Industrigatan 3',
                            visit_post_number: '87153',
                            visit_post_town: 'Härnösand',
                            mail_address: 'Box 209',
                            mail_post_number: '87124',
                            mail_post_town: 'Härnösand',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0611-521010',
                            fax: '0611-521039',
                            company_email: 'harnosand@bilbolaget.com',
                            used_cars_email: 'harnosand@bilbolaget.com',
                            new_cars_email: 'harnosand@bilbolaget.com',
                            new_contact_email: 'mats.norlen@bilbolaget.com',
                            home_page: 'http://www.bilbolaget.com/',
                            country: 'Sweden',
                            latitude: '62.6399661',
                            longitude: '17.9113676',
                            business_group: '0637',
                            price_localization: 'SE9427'
                        }
                        }
                    },
                    {
                        companyId: '1b552de5-f403-4290-868f-374ea7b6182a',
                        companyNumber: 'S639',
                        parentCompanyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        name: 'Bilbolaget Nord Strömsund',
                        branchCode: '0639',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0639',
                            name: 'Bilbolaget Nord AB',
                            structure_type: 'Filial',
                            district: '0639',
                            occupation: 'Sälj                Service',
                            center_district: '0637',
                            visit_address: 'Lövbergavägen 15',
                            visit_post_number: '83333',
                            visit_post_town: 'Strömsund',
                            mail_address: 'Box 540',
                            mail_post_number: '83324',
                            mail_post_town: 'Strömsund',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0670-291040',
                            fax: '0670-291041',
                            company_email: 'stromsund@bilbolaget.com',
                            used_cars_email: 'markus.svanstrom@bilbolaget.com',
                            new_cars_email: 'markus.svanstrom@bilbolaget.com',
                            new_contact_email: 'markus.svanstrom@bilbolaget.com',
                            home_page: 'http://www.bilbolaget.com/',
                            country: 'Sweden',
                            latitude: '63.8551392',
                            longitude: '15.5609224',
                            business_group: '0637',
                            price_localization: 'SE9427'
                        }
                        }
                    },
                    {
                        companyId: '7f726188-8e20-4e71-aa64-85cfb0b7f01b',
                        companyNumber: 'S709',
                        parentCompanyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        name: 'Bilbolaget Luleå',
                        branchCode: '0709',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {
                        profile: {
                            id: '0709',
                            name: 'Bilbolaget Norrbotten AB',
                            structure_type: 'Center',
                            district: '0709',
                            occupation: 'Sälj                Service',
                            center_district: '0709',
                            visit_address: 'Gammelstadsvägen 23',
                            visit_post_number: '97334',
                            visit_post_town: 'Luleå',
                            mail_address: 'Box 805',
                            mail_post_number: '97125',
                            mail_post_town: 'Luleå',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0920-203200',
                            fax: '0920-203299',
                            company_email: '',
                            used_cars_email: 'bilhall.lulea@bilbolaget.com',
                            new_cars_email: 'fredrik.soderholm@bilbolaget.com',
                            new_contact_email: 'lars.wanhainen@nordiskabil.se',
                            home_page: 'http://www.bilbolaget.com/',
                            country: 'Sweden',
                            latitude: '65.5954211',
                            longitude: '22.1512538',
                            business_group: '0637',
                            price_localization: 'SE9427'
                        }
                        }
                    },
                    {
                        companyId: '9ce06d8a-a3d2-4f5e-90b6-00d0cc0e10df',
                        companyNumber: 'S713',
                        parentCompanyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        name: 'Bilbolaget Boden',
                        branchCode: '0713',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: 'cf61b773-4df1-48cd-8f27-afd1b9243ecf',
                        companyNumber: 'S721',
                        parentCompanyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        name: 'Bilbolaget Piteå',
                        branchCode: '0721',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {
                        profile: {
                            id: '0721',
                            name: 'Bilbolaget i Norrbotten AB',
                            structure_type: 'Filial',
                            district: '0721',
                            occupation: 'Sälj                Service',
                            center_district: '0709',
                            visit_address: 'Turbovägen 1',
                            visit_post_number: '94128',
                            visit_post_town: 'Piteå',
                            mail_address: 'Box 805',
                            mail_post_number: '97125',
                            mail_post_town: 'Luleå',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0911-221200',
                            fax: '0911-221299',
                            company_email: 'bilhall.pitea@bilbolaget.com',
                            used_cars_email: 'bilhall.pitea@bilbolaget.com',
                            new_cars_email: 'bilhall.pitea@bilbolaget.com',
                            new_contact_email: 'stefan.magnusson@nordiskabil.se',
                            home_page: 'http://www.bilbolaget.com/',
                            country: 'Sweden',
                            latitude: '65.3191903',
                            longitude: '21.4173029',
                            business_group: '0637',
                            price_localization: 'SE9427'
                        }
                        }
                    },
                    {
                        companyId: '85ffc2de-7d34-48bf-922c-0c95ecf71378',
                        companyNumber: 'S715',
                        parentCompanyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        name: 'Bilbolaget Kalix',
                        branchCode: '0715',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {
                        profile: {
                            id: '0715',
                            name: 'Bilbolaget Norrbotten AB',
                            structure_type: 'Filial',
                            district: '0715',
                            occupation: 'Sälj                Service',
                            center_district: '0709',
                            visit_address: 'Stabsvägen 11',
                            visit_post_number: '95251',
                            visit_post_town: 'Kalix',
                            mail_address: 'Box 805',
                            mail_post_number: '97125',
                            mail_post_town: 'Luleå',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0923-69200',
                            fax: '0923-69229',
                            company_email: 'bilhall.kalix@bilbolaget.com',
                            used_cars_email: 'bilhall.kalix@bilbolaget.com',
                            new_cars_email: 'bilhall.kalix@bilbolaget.com',
                            new_contact_email: 'lars.wanhainen@nordiskabil.se',
                            home_page: 'http://www.bilbolaget.com/',
                            country: 'Sweden',
                            latitude: '65.8501554',
                            longitude: '23.1177885',
                            business_group: '0637',
                            price_localization: 'SE9427'
                        }
                        }
                    },
                    {
                        companyId: '2c020cb5-8cb9-477d-bcce-e3d111881e1c',
                        companyNumber: 'S750',
                        parentCompanyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        name: 'Bilbolaget Kiruna',
                        branchCode: '0750',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {
                        profile: {
                            id: '0750',
                            name: 'Bilbolaget Norrbotten AB',
                            structure_type: 'Filial',
                            district: '0750',
                            occupation: 'Sälj                Service',
                            center_district: '0701',
                            visit_address: 'Industrivägen 5',
                            visit_post_number: '98128',
                            visit_post_town: 'Kiruna',
                            mail_address: 'Box 807',
                            mail_post_number: '98128',
                            mail_post_town: 'Kiruna',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0980-398 400',
                            fax: '0980-398 401',
                            company_email: '',
                            used_cars_email: 'niclas.nilsson-ranta@bilbolaget.com',
                            new_cars_email: 'niclas.nilsson-ranta@bilbolaget.com',
                            new_contact_email: 'roger.pilestedt@erikssonbil.se',
                            home_page: '',
                            country: 'Sweden',
                            latitude: '67.8485903',
                            longitude: '20.2619565',
                            business_group: '0637',
                            price_localization: 'SE9427'
                        }
                        }
                    },
                    {
                        companyId: 'e4b6d6c4-8e54-4567-b71b-58ef2f845070',
                        companyNumber: 'S701',
                        parentCompanyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        name: 'Bilbolaget Gällivare',
                        branchCode: '0701',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {
                        profile: {
                            id: '0701',
                            name: 'Bilbolaget Norrbotten AB',
                            structure_type: 'Center',
                            district: '0701',
                            occupation: 'Sälj                Service',
                            center_district: '0701',
                            visit_address: 'Bilvägen 2',
                            visit_post_number: '98228',
                            visit_post_town: 'Gällivare',
                            mail_address: 'Box 809',
                            mail_post_number: '98228',
                            mail_post_town: 'Gällivare',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0970-699 300',
                            fax: '0970-699 301',
                            company_email: '',
                            used_cars_email: 'niclas.nilsson-ranta@bilbolaget.com',
                            new_cars_email: 'niclas.nilsson-ranta@bilbolaget.com',
                            new_contact_email: 'roger.edholm@erikssonbil.se',
                            home_page: 'http://www.bilbolaget.com/',
                            country: 'Sweden',
                            latitude: '67.1496326',
                            longitude: '20.661119',
                            business_group: '0637',
                            price_localization: 'SE9427'
                        }
                        }
                    },
                    {
                        companyId: 'ba949a76-e735-4aad-9351-2120e1c44a12',
                        companyNumber: 'S723',
                        parentCompanyId: '3cc0b459-3f1a-4cf7-a0ec-ca52624844b4',
                        name: 'Bilbolaget Boden',
                        branchCode: '0723',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: '0759ac64-0074-4cf2-b245-b7d8199ceb19',
                        companyNumber: '521',
                        parentCompanyId: null,
                        name: 'Bildepån Varberg',
                        branchCode: '0265',
                        branchCodeAliases: [],
                        localisationId: 'SE9767',
                        authId: null,
                        afPassword: 'kusika',
                        info: {
                        profile: {
                            id: '0265',
                            name: 'AB Varberg Bil-Depot',
                            structure_type: 'Center',
                            district: '0265',
                            occupation: 'Sälj                Service',
                            center_district: '0265',
                            visit_address: 'Susvindsvägen 17',
                            visit_post_number: '43232',
                            visit_post_town: 'Varberg',
                            mail_address: 'Box 1308',
                            mail_post_number: '43218',
                            mail_post_town: 'Varberg',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0340-504000',
                            fax: '0340-611154',
                            company_email: 'bildepot@bildepot.se',
                            used_cars_email: 'fredrik.bengtsson@bildepot.se',
                            new_cars_email: 'fredrik.bengtsson@bildepot.se',
                            new_contact_email: 'lillemor.kristoferson@bildepot.se',
                            home_page: 'http://www.bildepot.se/',
                            country: 'Sweden',
                            latitude: '57.123023',
                            longitude: '12.2781215',
                            business_group: '0265',
                            price_localization: 'SE9767'
                        }
                        }
                    },
                    {
                        companyId: '49bdd08b-3d73-491a-b503-7413e32e8afc',
                        companyNumber: '519',
                        parentCompanyId: null,
                        name: 'Bildeve Helsingborg',
                        branchCode: '0113',
                        branchCodeAliases: [],
                        localisationId: 'SE6762',
                        authId: null,
                        afPassword: 'huguke',
                        info: {
                        profile: {
                            id: '0113',
                            name: 'Bildeve AB',
                            structure_type: 'Center',
                            district: '0113',
                            occupation: 'Sälj                Service',
                            center_district: '0113',
                            visit_address: 'Bergavägen 4',
                            visit_post_number: '25466',
                            visit_post_town: 'Helsingborg',
                            mail_address: 'Box 22042',
                            mail_post_number: '25022',
                            mail_post_town: 'Helsingborg',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '042-172500',
                            fax: '042-172515',
                            company_email: 'infocenter@bildeve.se',
                            used_cars_email: 'johan.gustafsson@bildeve.se',
                            new_cars_email: 'prospekt@bildeve.se',
                            new_contact_email: 'susanne.lindmark@bildeve.se',
                            home_page: 'http://www.bildeve.se/',
                            country: 'Sweden',
                            latitude: '56.0701203',
                            longitude: '12.7195958',
                            business_group: '0113',
                            price_localization: 'SE6762'
                        },
                        facilities: [
                            {
                            name: 'Bilförsäljningen',
                            telephone: '042-17 25 00',
                            sort_order: '1',
                            opening_hours: {
                                monday: {
                                from: '08:00',
                                to: '18:00'
                                },
                                tuesday: {
                                from: '08:00',
                                to: '18:00'
                                },
                                wednesday: {
                                from: '08:00',
                                to: '18:00'
                                },
                                thursday: {
                                from: '08:00',
                                to: '18:00'
                                },
                                friday: {
                                from: '08:00',
                                to: '18:00'
                                },
                                saturday: {
                                from: '11:00',
                                to: '15:00'
                                },
                                sunday: {
                                from: '11:00',
                                to: '15:00'
                                }
                            },
                            exceptions: [
                                {
                                reason: 'Julafton',
                                date: '2019-12-23T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Juldagen',
                                date: '2019-12-24T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Annadag jul',
                                date: '2019-12-25T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Nyårsafton',
                                date: '2019-12-30T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Nyårsdagen',
                                date: '2019-12-31T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Trettondag jul',
                                date: '2020-01-05T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                }
                            ],
                            department: 'showroom'
                            },
                            {
                            name: 'Verkstad och reservdelar',
                            telephone: '042-17 25 00',
                            sort_order: '2',
                            opening_hours: {
                                monday: {
                                from: '07:30',
                                to: '16:30'
                                },
                                tuesday: {
                                from: '07:30',
                                to: '16:30'
                                },
                                wednesday: {
                                from: '07:30',
                                to: '16:30'
                                },
                                thursday: {
                                from: '07:30',
                                to: '16:30'
                                },
                                friday: {
                                from: '07:30',
                                to: '16:30'
                                }
                            },
                            exceptions: [],
                            department: 'service'
                            },
                            {
                            name: 'Butik och marknadsplats',
                            telephone: '042-17 25 00',
                            sort_order: '3',
                            opening_hours: {
                                monday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                thursday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                friday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                saturday: {
                                from: '11:00',
                                to: '15:00'
                                },
                                sunday: {
                                from: '11:00',
                                to: '15:00'
                                }
                            },
                            exceptions: [],
                            department: 'store'
                            },
                            {
                            name: 'Hertz biluthyrning',
                            telephone: '042-17 25 00',
                            sort_order: 3,
                            opening_hours: {
                                monday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                thursday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                friday: {
                                from: '07:00',
                                to: '18:00'
                                }
                            },
                            exceptions: [],
                            department: ''
                            }
                        ]
                        }
                    },
                    {
                        companyId: '90d255d9-6150-43f9-9e45-db4931ddc997',
                        companyNumber: 'S131',
                        parentCompanyId: '49bdd08b-3d73-491a-b503-7413e32e8afc',
                        name: 'Bildeve Landskrona',
                        branchCode: '0131',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0131',
                            name: 'Bildeve AB',
                            structure_type: 'Filial',
                            district: '0131',
                            occupation: 'Sälj                Service',
                            center_district: '0113',
                            visit_address: 'Hälsingborgsvägen 44',
                            visit_post_number: '26141',
                            visit_post_town: 'Landskrona',
                            mail_address: 'Hälsingborgsvägen 44',
                            mail_post_number: '26141',
                            mail_post_town: 'Landskrona',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0418-449770',
                            fax: '0418-449785',
                            company_email: 'info@bildeve.se',
                            used_cars_email: 'johan.gustafsson@bildeve.se',
                            new_cars_email: 'infocenter@bildeve.se',
                            new_contact_email: 'susanne.lindmark@bildeve.se',
                            home_page: 'http://www.bildeve.se/',
                            country: 'Sweden',
                            latitude: '55.886415',
                            longitude: '12.831565',
                            business_group: '0113',
                            price_localization: 'SE6762'
                        }
                        }
                    },
                    {
                        companyId: 'd2fe0060-dba2-4e70-95b7-f7e3f8b0c8fa',
                        companyNumber: 'S114',
                        parentCompanyId: '49bdd08b-3d73-491a-b503-7413e32e8afc',
                        name: 'Bildeve Höganäs',
                        branchCode: '0114',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        facilities: [
                            {
                            name: 'Bildeve Höganäs',
                            telephone: '042-332486',
                            sort_order: 0,
                            opening_hours: {
                                monday: {
                                from: '07:30',
                                to: '16:30'
                                },
                                tuesday: {
                                from: '07:30',
                                to: '16:30'
                                },
                                wednesday: {
                                from: '07:30',
                                to: '16:30'
                                },
                                thursday: {
                                from: '07:30',
                                to: '16:30'
                                },
                                friday: {
                                from: '07:30',
                                to: '16:30'
                                },
                                saturday: null
                            },
                            exceptions: [],
                            department: 'service'
                            }
                        ]
                        }
                    },
                    {
                        companyId: '5d4770a5-8f5d-4d16-a9c5-a099416f7103',
                        companyNumber: '515',
                        parentCompanyId: null,
                        name: 'Borås Bil AB',
                        branchCode: '0205',
                        branchCodeAliases: [],
                        localisationId: 'SE3361',
                        authId: null,
                        afPassword: 'dumisa',
                        info: {
                        profile: {
                            id: '0205',
                            name: 'Borås Bil Personbilar AB',
                            structure_type: 'Center',
                            district: '0205',
                            occupation: 'Sälj                Service',
                            center_district: '0205',
                            visit_address: 'Hultagatan 51',
                            visit_post_number: '50750',
                            visit_post_town: 'Borås',
                            mail_address: 'Box 111 10',
                            mail_post_number: '50711',
                            mail_post_town: 'Borås',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '033-151600',
                            fax: '033-151610',
                            company_email: 'info@borasbil.se',
                            used_cars_email: 'ulf.dahlqvist@borasbil.se',
                            new_cars_email: 'web@borasbil.se',
                            new_contact_email: 'per.godlund@borasbil.se',
                            home_page: 'http://www.borasbil.se/',
                            country: 'Sweden',
                            latitude: '57.7225507',
                            longitude: '12.9906446',
                            business_group: '0205',
                            price_localization: 'SE3361'
                        },
                        facilities: [
                            {
                            name: 'Verkstad',
                            telephone: '',
                            sort_order: 0,
                            opening_hours: {},
                            exceptions: [],
                            department: 'service'
                            }
                        ]
                        }
                    },
                    {
                        companyId: 'fbff315e-6d42-400f-a91e-bcba1965a15a',
                        companyNumber: 'S209',
                        parentCompanyId: '5d4770a5-8f5d-4d16-a9c5-a099416f7103',
                        name: 'Borås Bil Svenljunga',
                        branchCode: '0209',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '498e65c6-330a-4ee3-bf53-fa3117e62f5e',
                        companyNumber: 'S282',
                        parentCompanyId: '5d4770a5-8f5d-4d16-a9c5-a099416f7103',
                        name: 'Bogesunds Bil Tranemo',
                        branchCode: '0282',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0282',
                            name: 'Bogesunds Bil',
                            structure_type: 'Filial',
                            district: '0282',
                            occupation: 'Sälj                Service',
                            center_district: '0205',
                            visit_address: 'Limmaredsvägen 11',
                            visit_post_number: '51432',
                            visit_post_town: 'Tranemo',
                            mail_address: 'Limmaredsvägen 11',
                            mail_post_number: '51432',
                            mail_post_town: 'Tranemo',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0325-47400',
                            fax: '0325-47404',
                            company_email: 'info@borasbil.se',
                            used_cars_email: 'max.kallio@borasbil.se',
                            new_cars_email: 'max.kallio@borasbil.se',
                            new_contact_email: 'magnus.low@borasbil.se',
                            home_page: 'http://www.bogesundsbil.se/',
                            country: 'Sweden',
                            latitude: '57.4932613',
                            longitude: '13.339588',
                            business_group: '0205',
                            price_localization: 'SE3361'
                        }
                        }
                    },
                    {
                        companyId: 'eff2f750-709c-46a1-8bfa-9324cd0c8dcb',
                        companyNumber: 'S281',
                        parentCompanyId: '5d4770a5-8f5d-4d16-a9c5-a099416f7103',
                        name: 'Bogesunds Bil Ulricehamn',
                        branchCode: '0281',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0281',
                            name: 'Bogesunds Bil',
                            structure_type: 'Filial',
                            district: '0281',
                            occupation: 'Sälj                Service',
                            center_district: '0205',
                            visit_address: 'Falköpingsvägen 10',
                            visit_post_number: '52337',
                            visit_post_town: 'Ulricehamn',
                            mail_address: 'Box 233',
                            mail_post_number: '52325',
                            mail_post_town: 'Ulricehamn',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0321-27700',
                            fax: '0321-27710',
                            company_email: 'info@borasbil.se',
                            used_cars_email: 'pelle.torstensson@borasbil.se',
                            new_cars_email: 'pelle.torstensson@borasbil.se',
                            new_contact_email: 'magnus.low@borasbil.se',
                            home_page: 'http://www.bogesundsbil.se/',
                            country: 'Sweden',
                            latitude: '57.8066657',
                            longitude: '13.4218084',
                            business_group: '0205',
                            price_localization: 'SE3361'
                        },
                        facilities: [
                            {
                            name: 'Showroom',
                            telephone: '',
                            sort_order: 0,
                            opening_hours: {
                                monday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                tuesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                wednesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                thursday: {
                                from: '09:00',
                                to: '16:00'
                                },
                                friday: null,
                                saturday: null,
                                sunday: null
                            },
                            exceptions: [],
                            department: 'showroom'
                            },
                            {
                            name: 'Verkstad',
                            telephone: '',
                            sort_order: 1,
                            opening_hours: {
                                monday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                thursday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                friday: null
                            },
                            exceptions: [],
                            department: ''
                            },
                            {
                            name: 'Kassa/Reservdelar',
                            telephone: '',
                            sort_order: 3,
                            opening_hours: {
                                monday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                thursday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                friday: null
                            },
                            exceptions: [],
                            department: 'store'
                            }
                        ]
                        }
                    },
                    {
                        companyId: '17d49866-d610-4f61-a919-e8aa02da5f89',
                        companyNumber: 'S208',
                        parentCompanyId: '5d4770a5-8f5d-4d16-a9c5-a099416f7103',
                        name: 'Kinna Bil',
                        branchCode: '0208',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0208',
                            name: 'Kinna Bil',
                            structure_type: 'Filial',
                            district: '0208',
                            occupation: 'Sälj                Service',
                            center_district: '0205',
                            visit_address: 'Verkstadsgatan 4',
                            visit_post_number: '51157',
                            visit_post_town: 'Kinna',
                            mail_address: 'Box 257',
                            mail_post_number: '51123',
                            mail_post_town: 'Kinna',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0320-19600',
                            fax: '0320-19610',
                            company_email: 'info@borasbil.se',
                            used_cars_email: 'miro.tallvod@borasbil.se',
                            new_cars_email: 'miro.tallvod@borasbil.se',
                            new_contact_email: 'stig.axelsson@borasbil.se',
                            home_page: 'http://www.kinnabil.se/',
                            country: 'Sweden',
                            latitude: '57.5145419',
                            longitude: '12.7099966',
                            business_group: '0205',
                            price_localization: 'SE3361'
                        }
                        }
                    },
                    {
                        companyId: '27d2a533-9f73-4dea-a49c-6208581901c4',
                        companyNumber: '513',
                        parentCompanyId: null,
                        name: 'Bra Bil Göteborg',
                        branchCode: '0272',
                        branchCodeAliases: [],
                        localisationId: 'SE4152',
                        authId: null,
                        afPassword: 'hugibe',
                        info: {
                        profile: {
                            id: '0272',
                            name: 'Bra Bil i Göteborg AB / Hisings Backa',
                            structure_type: 'Center',
                            district: '0272',
                            occupation: 'Sälj                Service',
                            center_district: '0272',
                            visit_address: 'Importgatan 3',
                            visit_post_number: '42246',
                            visit_post_town: 'Göteborg',
                            mail_address: 'Importgatan 3',
                            mail_post_number: '42246',
                            mail_post_town: 'Hisings Backa',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '031-380 5000',
                            fax: '031-380 5274',
                            company_email: 'info.vc.vasby@brabil.se',
                            used_cars_email: 'patrik.stenstrom@brabil.se',
                            new_cars_email: 'patrik.stenstrom@brabil.se',
                            new_contact_email: 'info.fc.goteborg@brabil.se',
                            home_page: 'http://www.brabil.se/',
                            country: 'Sweden',
                            latitude: '57.7460151',
                            longitude: '11.9920325',
                            business_group: '0272',
                            price_localization: 'SE4152'
                        }
                        }
                    },
                    {
                        companyId: 'd0035eae-f889-4650-a4cb-e1da23af5102',
                        companyNumber: 'S580',
                        parentCompanyId: '27d2a533-9f73-4dea-a49c-6208581901c4',
                        name: 'Bra Bil Enköping',
                        branchCode: '0580',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0580',
                            name: 'Bra Bil i Enköping AB',
                            structure_type: 'Center',
                            district: '0580',
                            occupation: 'Sälj                Service',
                            center_district: '0580',
                            visit_address: 'Traktorgatan 5',
                            visit_post_number: '74537',
                            visit_post_town: 'Enköping',
                            mail_address: 'Box 121',
                            mail_post_number: '74523',
                            mail_post_town: 'Enköping',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0171-23700',
                            fax: '0171-35860',
                            company_email: 'info.vc.vasby@brabil.se',
                            used_cars_email: 'christian.pihlqvist@brabil.se',
                            new_cars_email: 'mikael.tomberg@brabil.se',
                            new_contact_email: 'mikael.tomberg@brabil.se',
                            home_page: 'http://www.brabil.se/',
                            country: 'Sweden',
                            latitude: '59.6544673',
                            longitude: '17.0822005',
                            business_group: '0272',
                            price_localization: 'SE4152'
                        }
                        }
                    },
                    {
                        companyId: '57c78f11-c3c2-41fe-b303-e26af65cede2',
                        companyNumber: 'S579',
                        parentCompanyId: '27d2a533-9f73-4dea-a49c-6208581901c4',
                        name: 'Bra Bil Bålsta',
                        branchCode: '0579',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0579',
                            name: 'Bra Bil i Enköping AB',
                            structure_type: 'Filial',
                            district: '0579',
                            occupation: 'Sälj                Service',
                            center_district: '0580',
                            visit_address: 'Arosvägen',
                            visit_post_number: '74631',
                            visit_post_town: 'Bålsta',
                            mail_address: 'Arosvägen',
                            mail_post_number: '74631',
                            mail_post_town: 'Bålsta',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0171-468140',
                            fax: '0171-58584',
                            company_email: 'info.vc.vasby@brabil.se',
                            used_cars_email: 'martin.ramstrom@brabil.se',
                            new_cars_email: 'martin.ramstrom@brabil.se',
                            new_contact_email: 'kari.ronder@brabil.se',
                            home_page: 'http://www.brabil.se/',
                            country: 'Sweden',
                            latitude: '59.5577249',
                            longitude: '17.5342552',
                            business_group: '0272',
                            price_localization: 'SE4152'
                        }
                        }
                    },
                    {
                        companyId: '93959793-3b42-4c5d-867b-701c6ea49dda',
                        companyNumber: 'S440',
                        parentCompanyId: '27d2a533-9f73-4dea-a49c-6208581901c4',
                        name: 'Bra Bil Upplands Väsby',
                        branchCode: '0440',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0440',
                            name: 'Bra Bil i Väsby AB / Upplands Väsby',
                            structure_type: 'Center',
                            district: '0440',
                            occupation: 'Sälj                Service',
                            center_district: '0440',
                            visit_address: 'Truckvägen 20',
                            visit_post_number: '19452',
                            visit_post_town: 'Stockholm',
                            mail_address: 'Truckvägen 20',
                            mail_post_number: '19452',
                            mail_post_town: 'Upplands Väsby',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '08-1200 6000',
                            fax: '08-12006280',
                            company_email: 'info.vc.vasby@brabil.se',
                            used_cars_email: 'andreas.sundqvist@brabil.se',
                            new_cars_email: 'andreas.sundqvist@brabil.se',
                            new_contact_email: 'hakan.nilsson@brabil.se',
                            home_page: 'http://www.brabil.se/',
                            country: 'Sweden',
                            latitude: '59.5160845',
                            longitude: '17.9261226',
                            business_group: '0272',
                            price_localization: 'SE4152'
                        }
                        }
                    },
                    {
                        companyId: '5c48d7f1-60c2-440a-a31d-fd0050def8bd',
                        companyNumber: 'S442',
                        parentCompanyId: '27d2a533-9f73-4dea-a49c-6208581901c4',
                        name: 'Bra Bil Vallentuna',
                        branchCode: '0442',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0442',
                            name: 'Bra Bil i Väsby AB / Vallentuna',
                            structure_type: 'Filial',
                            district: '0442',
                            occupation: 'Sälj                Service',
                            center_district: '0440',
                            visit_address: 'Smidesvägen 2',
                            visit_post_number: '18636',
                            visit_post_town: 'Stockholm',
                            mail_address: '',
                            mail_post_number: '',
                            mail_post_town: '',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '08-1200 6000',
                            fax: '08-12006285',
                            company_email: 'info.vc.vasby@brabil.se',
                            used_cars_email: 'andreas.sundqvist@brabil.se',
                            new_cars_email: 'andreas.sundqvist@brabil.se',
                            new_contact_email: 'hakan.nilsson@brabil.se',
                            home_page: 'http://www.brabil.se/',
                            country: 'Sweden',
                            latitude: '59.5313957',
                            longitude: '18.0837105',
                            business_group: '0272',
                            price_localization: 'SE4152'
                        }
                        }
                    },
                    {
                        companyId: 'ccda782d-3afc-42d5-b06a-4b2d2a4468ab',
                        companyNumber: 'S581',
                        parentCompanyId: '27d2a533-9f73-4dea-a49c-6208581901c4',
                        name: 'Bra Bil Kungsängen',
                        branchCode: '0581',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0581',
                            name: 'Bra Bil i Väsby AB / Kungsängen',
                            structure_type: 'Filial',
                            district: '0581',
                            occupation: 'Sälj                Service',
                            center_district: '0440',
                            visit_address: 'Mätarvägen 2',
                            visit_post_number: '19637',
                            visit_post_town: 'Stockholm',
                            mail_address: 'Box 2077',
                            mail_post_number: '19602',
                            mail_post_town: 'Kungsängen',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '08-1200 6000',
                            fax: '08-12006288',
                            company_email: 'info.vc.vasby@brabil.se',
                            used_cars_email: 'andreas.sundqvist@brabil.se',
                            new_cars_email: 'andreas.sundqvist@brabil.se',
                            new_contact_email: 'hakan.nilsson@brabil.se',
                            home_page: 'http://www.brabil.se/',
                            country: 'Sweden',
                            latitude: '59.5036637',
                            longitude: '17.740454',
                            business_group: '0272',
                            price_localization: 'SE4152'
                        },
                        facilities: []
                        }
                    },
                    {
                        companyId: '06472587-adcc-4bda-aa1f-1be621beafa4',
                        companyNumber: 'S296',
                        parentCompanyId: '27d2a533-9f73-4dea-a49c-6208581901c4',
                        name: 'Bra Bil Landvetter',
                        branchCode: '0296',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: '3192b5e2-0da5-410f-a02a-d5719ccebf60',
                        companyNumber: '499',
                        parentCompanyId: null,
                        name: 'Finnvedens Bil Värnamo',
                        branchCode: '0358',
                        branchCodeAliases: [],
                        localisationId: 'SE7835',
                        authId: null,
                        afPassword: 'pumake',
                        info: {
                        profile: {
                            id: '0358',
                            name: 'Finnvedens Bil AB',
                            structure_type: 'Center',
                            district: '0358',
                            occupation: 'Sälj                Service',
                            center_district: '0358',
                            visit_address: 'Nydalavägen',
                            visit_post_number: '33140',
                            visit_post_town: 'Värnamo',
                            mail_address: 'Box 244',
                            mail_post_number: '33122',
                            mail_post_town: 'Värnamo',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0370-42500',
                            fax: '0370-42511',
                            company_email: 'info@finnvedensbil.se',
                            used_cars_email: 'info@finnvedensbil.se',
                            new_cars_email: 'info@finnvedensbil.se',
                            new_contact_email: 'robert.larsson@finnvedensbil.se',
                            home_page: 'http://www.finnvedensbil.se/',
                            country: 'Sweden',
                            latitude: '57.1868024',
                            longitude: '14.0620941',
                            business_group: '0358',
                            price_localization: 'SE7835'
                        }
                        }
                    },
                    {
                        companyId: '82f8e8a6-68fe-48bf-b94a-031b83e0a3df',
                        companyNumber: 'S360',
                        parentCompanyId: '3192b5e2-0da5-410f-a02a-d5719ccebf60',
                        name: 'Finnvedens Bil Gislaved',
                        branchCode: '0360',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0360',
                            name: 'Finnvedens Bil AB',
                            structure_type: 'Filial',
                            district: '0360',
                            occupation: 'Sälj                Service',
                            center_district: '0358',
                            visit_address: 'Baldersvägen 37',
                            visit_post_number: '33235',
                            visit_post_town: 'Gislaved',
                            mail_address: 'Box 24',
                            mail_post_number: '332 21',
                            mail_post_town: 'Gislaved',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0371-586650',
                            fax: '0371-586656',
                            company_email: 'info@finnvedensbil.se',
                            used_cars_email: 'info@finnvedensbil.se',
                            new_cars_email: 'info@finnvedensbil.se',
                            new_contact_email: 'robert.larsson@finnvedensbil.se',
                            home_page: 'http://www.finnvedensbil.se/',
                            country: 'Sweden',
                            latitude: '57.2979989',
                            longitude: '13.5678669',
                            business_group: '0358',
                            price_localization: 'SE7835'
                        },
                        facilities: [
                            {
                            name: 'Finnvedens Bil Gislaved',
                            telephone: '',
                            sort_order: '1',
                            opening_hours: {
                                monday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                tuesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                wednesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                thursday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                friday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                saturday: {
                                from: '10:00',
                                to: '13:00'
                                }
                            },
                            exceptions: [],
                            department: 'showroom'
                            },
                            {
                            name: 'Verkstad',
                            telephone: '',
                            sort_order: '2',
                            opening_hours: {
                                monday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                thursday: {
                                from: '07:00',
                                to: '16:00'
                                },
                                friday: {
                                from: '07:00',
                                to: '16:00'
                                }
                            },
                            exceptions: [],
                            department: 'service'
                            },
                            {
                            name: 'Reservdelar',
                            telephone: '',
                            sort_order: '3',
                            opening_hours: {
                                monday: {
                                from: '08:00',
                                to: '17:00'
                                },
                                tuesday: {
                                from: '08:00',
                                to: '17:00'
                                },
                                wednesday: {
                                from: '08:00',
                                to: '17:00'
                                },
                                thursday: {
                                from: '08:00',
                                to: '17:00'
                                },
                                friday: {
                                from: '08:00',
                                to: '17:00'
                                }
                            },
                            exceptions: [],
                            department: 'store'
                            },
                            {
                            name: 'Lunchstängt Verkstad',
                            telephone: '',
                            sort_order: '4',
                            opening_hours: {
                                monday: {
                                from: '12:00',
                                to: '12:45'
                                },
                                tuesday: {
                                from: '12:00',
                                to: '12:45'
                                },
                                wednesday: {
                                from: '12:00',
                                to: '12:45'
                                },
                                thursday: {
                                from: '12:00',
                                to: '12:45'
                                },
                                friday: {
                                from: '12:00',
                                to: '12:45'
                                },
                                saturday: null,
                                sunday: null
                            },
                            exceptions: [],
                            department: ''
                            }
                        ]
                        }
                    },
                    {
                        companyId: '299bc027-2c99-4e58-8984-2ce29d61c407',
                        companyNumber: 'S362',
                        parentCompanyId: '3192b5e2-0da5-410f-a02a-d5719ccebf60',
                        name: 'Finnvedens Bil Smålandsstenar',
                        branchCode: '0362',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '8b60af8c-7d2c-47db-b883-52d6946589ac',
                        companyNumber: '541',
                        parentCompanyId: '3192b5e2-0da5-410f-a02a-d5719ccebf60',
                        name: 'Finnvedens Bil Kristinehamn',
                        branchCode: '0529',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {
                        profile: {
                            id: '0529',
                            name: 'Finnvedens Bil AB',
                            structure_type: 'Center',
                            district: '0529',
                            occupation: 'Sälj                Service',
                            center_district: '0529',
                            visit_address: 'Västgötagatan 45',
                            visit_post_number: '68142',
                            visit_post_town: 'Kristinehamn',
                            mail_address: 'Box 136',
                            mail_post_number: '68123',
                            mail_post_town: 'Kristinehamn',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0550-316 00',
                            fax: '0550-10575',
                            company_email: 'info@finnvedensbil.se',
                            used_cars_email: 'johan.bergstrom@finnvedensbil.se',
                            new_cars_email: 'johan.bergstrom@finnvedensbil.se',
                            new_contact_email: 'henrik.forsberg@finnvedensbil.se',
                            home_page: 'http://www.finnvedensbil.se/',
                            country: 'Sweden',
                            latitude: '59.2993291',
                            longitude: '14.119984',
                            business_group: '0358',
                            price_localization: 'SE7835'
                        }
                        }
                    },
                    {
                        companyId: 'b02d6662-64dd-49f0-9c5a-c01c68219931',
                        companyNumber: '510',
                        parentCompanyId: null,
                        name: 'Forslunds i Skellefteå',
                        branchCode: '0725',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0725',
                            name: 'Forslunds i Skellefteå AB',
                            structure_type: 'Center',
                            district: '0725',
                            occupation: 'Sälj                Service',
                            center_district: '0725',
                            visit_address: 'Tjärnvägen 7',
                            visit_post_number: '93161',
                            visit_post_town: 'Skellefteå',
                            mail_address: 'Box 746',
                            mail_post_number: '93127',
                            mail_post_town: 'Skellefteå',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0910-738500',
                            fax: '0910-738585',
                            company_email: 'info@forslunds.se',
                            used_cars_email: 'erik.eriksson@forslunds.se',
                            new_cars_email: 'erik.eriksson@forslunds.se',
                            new_contact_email: 'mikael.lindgren@forslunds.se',
                            home_page: 'http://www.forslunds.se/',
                            country: 'Sweden',
                            latitude: '64.7304895',
                            longitude: '20.9729628',
                            business_group: '0725',
                            price_localization: 'SE_NATIONAL'
                        }
                        }
                    },
                    {
                        companyId: '763a93b8-4c56-423e-8c21-afa355152743',
                        companyNumber: '511',
                        parentCompanyId: null,
                        name: 'Gösta Samuelsson Bil Ludvika',
                        branchCode: '0545',
                        branchCodeAliases: [],
                        localisationId: 'SE1015',
                        authId: null,
                        afPassword: 'i9GEpe',
                        info: {
                        profile: {
                            id: '0545',
                            name: 'Gösta Samuelsson Bil i Ludvika AB',
                            structure_type: 'Center',
                            district: '0545',
                            occupation: 'Sälj                Service',
                            center_district: '0545',
                            visit_address: 'Bilvägen 1',
                            visit_post_number: '77142',
                            visit_post_town: 'Ludvika',
                            mail_address: 'Box 880',
                            mail_post_number: '77128',
                            mail_post_town: 'Ludvika',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0240-87800',
                            fax: '0240-87878',
                            company_email: 'info@gsbil.se',
                            used_cars_email: 'magnus.grabo@gsbil.se',
                            new_cars_email: 'magnus.grabo@gsbil.se',
                            new_contact_email: 'tapio.tiusanen@gostasamuelssonbil.se',
                            home_page: 'http://www.gsbil.se/',
                            country: 'Sweden',
                            latitude: '60.1243477',
                            longitude: '15.182619',
                            business_group: '0545',
                            price_localization: 'SE1015'
                        }
                        }
                    },
                    {
                        companyId: 'cf878822-a633-49d6-8a37-e499a022da6a',
                        companyNumber: '508',
                        parentCompanyId: null,
                        name: 'Liljas Bil Kalmar',
                        branchCode: '0121',
                        branchCodeAliases: [],
                        localisationId: 'SE6280',
                        authId: null,
                        afPassword: 'migigo',
                        info: {
                        profile: {
                            id: '0121',
                            name: 'Liljas Personbilar AB',
                            structure_type: 'Center',
                            district: '0121',
                            occupation: 'Sälj                Service',
                            center_district: '0121',
                            visit_address: 'Trångsundsvägen 20',
                            visit_post_number: '393 56',
                            visit_post_town: 'Kalmar',
                            mail_address: 'Box 500',
                            mail_post_number: '39125',
                            mail_post_town: 'Kalmar',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0480-94400',
                            fax: '0480-12115',
                            company_email: '',
                            used_cars_email: 'mikael.andersson@liljasbil.se',
                            new_cars_email: 'mikael.andersson@liljasbil.se',
                            new_contact_email: 'lena.johansson@liljasbil.se',
                            home_page: 'http://www.liljasbil.se/',
                            country: 'Sweden',
                            latitude: '56.6886348',
                            longitude: '16.318135',
                            business_group: '0121',
                            price_localization: 'SE6280'
                        }
                        }
                    },
                    {
                        companyId: 'f5b1f92c-0a62-4435-be23-b3f5052c4169',
                        companyNumber: 'S188',
                        parentCompanyId: 'cf878822-a633-49d6-8a37-e499a022da6a',
                        name: 'Liljas Personbilar Växjö',
                        branchCode: '0188',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0188',
                            name: 'Liljas Personbilar AB',
                            structure_type: 'Center',
                            district: '0188',
                            occupation: 'Sälj                Service',
                            center_district: '0188',
                            visit_address: 'Ljungadalsgatan 1',
                            visit_post_number: '35246',
                            visit_post_town: 'Växjö',
                            mail_address: 'Box 3123',
                            mail_post_number: '35043',
                            mail_post_town: 'Växjö',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0470-775600',
                            fax: '0470-18902',
                            company_email: '',
                            used_cars_email: 'Anton.eriksson@liljasbil.se',
                            new_cars_email: 'Anton.eriksson@liljasbil.se',
                            new_contact_email: 'ewa.karlsson@liljasbil.se',
                            home_page: 'http://www.liljasbil.se/',
                            country: 'Sweden',
                            latitude: '56.8835805',
                            longitude: '14.7713173',
                            business_group: '0121',
                            price_localization: 'SE6280'
                        }
                        }
                    },
                    {
                        companyId: 'ac718224-e46b-4061-95d2-db15f1d0bd3d',
                        companyNumber: 'S191',
                        parentCompanyId: 'cf878822-a633-49d6-8a37-e499a022da6a',
                        name: 'Liljas Personbilar Tingsryd',
                        branchCode: '0191',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0191',
                            name: 'Liljas Personbilar AB',
                            structure_type: 'Filial',
                            district: '0191',
                            occupation: 'Sälj                Service',
                            center_district: '0188',
                            visit_address: 'Storgatan 113',
                            visit_post_number: '36230',
                            visit_post_town: 'Tingsryd',
                            mail_address: 'Box 84',
                            mail_post_number: '36230',
                            mail_post_town: 'Tingsryd',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0477-31355',
                            fax: '0477-18071',
                            company_email: '',
                            used_cars_email: 'johan.gill@liljasbil.se',
                            new_cars_email: 'johan.gill@liljasbil.se',
                            new_contact_email: 'ewa.karlsson@liljasbil.se',
                            home_page: 'http://www.liljasbil.se/',
                            country: 'Sweden',
                            latitude: '56.5189839',
                            longitude: '14.9858792',
                            business_group: '0121',
                            price_localization: 'SE6280'
                        }
                        }
                    },
                    {
                        companyId: '54135b83-d324-4e43-af7b-cae0f9070a94',
                        companyNumber: 'S123',
                        parentCompanyId: 'cf878822-a633-49d6-8a37-e499a022da6a',
                        name: 'Liljas Personbilar Nybro',
                        branchCode: '0123',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0123',
                            name: 'Liljas Personbilar AB',
                            structure_type: 'Filial',
                            district: '0123',
                            occupation: 'Sälj                Service',
                            center_district: '0121',
                            visit_address: 'Jutarnas väg 5',
                            visit_post_number: '38245',
                            visit_post_town: 'Nybro',
                            mail_address: 'Servicegatan 1',
                            mail_post_number: '38245',
                            mail_post_town: 'Nybro',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0481-44400',
                            fax: '0481-10850',
                            company_email: '',
                            used_cars_email: 'magnus.landstrom@liljasbil.se',
                            new_cars_email: 'ola.petersson@liljasbil.se',
                            new_contact_email: 'lena.johansson@liljasbil.se',
                            home_page: 'http://www.liljasbil.se/',
                            country: 'Sweden',
                            latitude: '56.7430379',
                            longitude: '15.8931528',
                            business_group: '0121',
                            price_localization: 'SE6280'
                        }
                        }
                    },
                    {
                        companyId: '2bdbb108-52d2-4ab6-9576-b2ac9d13b27f',
                        companyNumber: 'S124',
                        parentCompanyId: 'cf878822-a633-49d6-8a37-e499a022da6a',
                        name: 'Liljas Personbilar Emmaboda',
                        branchCode: '0124',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0124',
                            name: 'Liljas Personbilar AB',
                            structure_type: 'Filial',
                            district: '0124',
                            occupation: 'Sälj                Service',
                            center_district: '0121',
                            visit_address: 'Granitvägen 2',
                            visit_post_number: '36131',
                            visit_post_town: 'Emmaboda',
                            mail_address: 'Granitvägen 2',
                            mail_post_number: '36131',
                            mail_post_town: 'Emmaboda',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0471-25200',
                            fax: '0471-13944',
                            company_email: '',
                            used_cars_email: 'per.albertsson@liljasbil.se',
                            new_cars_email: 'per.albertsson@liljasbil.se',
                            new_contact_email: 'lena.johansson@liljasbil.se',
                            home_page: 'http://www.liljasbil.se/',
                            country: 'Sweden',
                            latitude: '56.627338',
                            longitude: '15.5471336',
                            business_group: '0121',
                            price_localization: 'SE6280'
                        }
                        }
                    },
                    {
                        companyId: '45c93965-f33e-48d4-909b-10877d811678',
                        companyNumber: '514',
                        parentCompanyId: null,
                        name: 'Rolf Ericson Bil Borlänge',
                        branchCode: '0505',
                        branchCodeAliases: [],
                        localisationId: 'SE1483',
                        authId: null,
                        afPassword: 'sebobi',
                        info: {
                        profile: {
                            id: '0505',
                            name: 'Rolf Ericson Bil i Dalarna AB',
                            structure_type: 'Center',
                            district: '0505',
                            occupation: 'Sälj                Service',
                            center_district: '0505',
                            visit_address: 'Smedjebacksvägen',
                            visit_post_number: '78463',
                            visit_post_town: 'Borlänge',
                            mail_address: 'Box 5046',
                            mail_post_number: '79105',
                            mail_post_town: 'Falun',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0243-257700',
                            fax: '0243-257724',
                            company_email: 'info@rebil.se',
                            used_cars_email: 'begbil@rebil.se',
                            new_cars_email: 'nybil@rebil.se',
                            new_contact_email: 'nisse.jonsson@rebil.se',
                            home_page: 'http://www.rebil.se/',
                            country: 'Sweden',
                            latitude: '60.4412241',
                            longitude: '15.4828979',
                            business_group: '0505',
                            price_localization: 'SE1483'
                        }
                        }
                    },
                    {
                        companyId: 'cb9065e6-bdd9-482e-a9a1-c8c59d9e939c',
                        companyNumber: 'S509',
                        parentCompanyId: '45c93965-f33e-48d4-909b-10877d811678',
                        name: 'Rolf Ericson Bil Falun',
                        branchCode: '0509',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0509',
                            name: 'Rolf Ericson Bil i Dalarna AB',
                            structure_type: 'Filial',
                            district: '0509',
                            occupation: 'Sälj                Service',
                            center_district: '0505',
                            visit_address: 'Korsnäsvägen 136-138',
                            visit_post_number: '79143',
                            visit_post_town: 'Falun',
                            mail_address: 'Box 5046',
                            mail_post_number: '79105',
                            mail_post_town: 'Falun',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '023-58800',
                            fax: '023-58888',
                            company_email: 'info@rebil.se',
                            used_cars_email: 'begbil@rebil.se',
                            new_cars_email: 'nybil@rebil.se',
                            new_contact_email: 'karin.eriksson@rebil.se',
                            home_page: 'http://www.rebil.se/',
                            country: 'Sweden',
                            latitude: '60.595652',
                            longitude: '15.6840879',
                            business_group: '0505',
                            price_localization: 'SE1483'
                        }
                        }
                    },
                    {
                        companyId: 'cd36e988-30d6-4f66-85d8-b704fc5827b5',
                        companyNumber: '518',
                        parentCompanyId: null,
                        name: 'Skobes Bil AB Tranås',
                        branchCode: '0345',
                        branchCodeAliases: [],
                        localisationId: 'SE1789',
                        authId: null,
                        afPassword: 'padate',
                        info: {
                        profile: {
                            id: '0345',
                            name: 'Skobes Bil Mitt AB',
                            structure_type: 'Filial',
                            district: '0345',
                            occupation: 'Sälj                Service',
                            center_district: '0322',
                            visit_address: 'Ågatan 36',
                            visit_post_number: '57331',
                            visit_post_town: 'Tranås',
                            mail_address: 'Box 1021',
                            mail_post_number: '57329',
                            mail_post_town: 'Tranås',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0140-69400',
                            fax: '0140-69403',
                            company_email: 'niklas.johansson@skobes.se',
                            used_cars_email: 'niklas.johansson@skobes.se',
                            new_cars_email: 'niklas.johansson@skobes.se',
                            new_contact_email: 'niklas.johansson@skobes.se',
                            home_page: 'http://www.skobes.se/',
                            country: 'Sweden',
                            latitude: '58.0352985',
                            longitude: '14.9781405',
                            business_group: '0345',
                            price_localization: 'SE1789'
                        }
                        }
                    },
                    {
                        companyId: 'ec1334ae-b9be-4fda-a87d-3043758607a1',
                        companyNumber: 'S322',
                        parentCompanyId: 'cd36e988-30d6-4f66-85d8-b704fc5827b5',
                        name: 'Skobes Bil Motala',
                        branchCode: '0322',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0322',
                            name: 'Skobes Bil Mitt AB',
                            structure_type: 'Center',
                            district: '0322',
                            occupation: 'Sälj                Service',
                            center_district: '0322',
                            visit_address: 'Vintergatan 9',
                            visit_post_number: '59105',
                            visit_post_town: 'Motala',
                            mail_address: 'Box 5001',
                            mail_post_number: '59105',
                            mail_post_town: 'Motala',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0141-227500',
                            fax: '0141-227503',
                            company_email: 'niklas.johansson@skobes.se',
                            used_cars_email: 'dick.berg@skobes.se',
                            new_cars_email: 'dick.berg@skobes.se',
                            new_contact_email: 'dick.berg@skobes.se',
                            home_page: 'http://www.skobes.se/',
                            country: 'Sweden',
                            latitude: '58.5359755',
                            longitude: '15.0160009',
                            business_group: '0345',
                            price_localization: 'SE1789'
                        }
                        }
                    },
                    {
                        companyId: '0f1955bc-6b34-45ed-b3b6-6aadbe845e28',
                        companyNumber: 'S350',
                        parentCompanyId: 'cd36e988-30d6-4f66-85d8-b704fc5827b5',
                        name: 'Skobes Bil Vetlanda',
                        branchCode: '0350',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0350',
                            name: 'Skobes Bil Mitt AB',
                            structure_type: 'Filial',
                            district: '0350',
                            occupation: 'Sälj                Service',
                            center_district: '0322',
                            visit_address: 'Kolvägen 23',
                            visit_post_number: '57435',
                            visit_post_town: 'Vetlanda',
                            mail_address: 'Box 193',
                            mail_post_number: '57422',
                            mail_post_town: 'Vetlanda',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0383-59600',
                            fax: '0383-18488',
                            company_email: 'magnus.green@skobes.se',
                            used_cars_email: 'magnus.green@skobes.se',
                            new_cars_email: 'magnus.green@skobes.se',
                            new_contact_email: 'magnus.green@karlbergsbil.se',
                            home_page: 'http://www.skobes.se/',
                            country: 'Sweden',
                            latitude: '57.4452198',
                            longitude: '15.06518',
                            business_group: '0345',
                            price_localization: 'SE1789'
                        }
                        }
                    },
                    {
                        companyId: '7e0af544-4ebf-4be2-98b8-80cb9e316d0a',
                        companyNumber: 'S328',
                        parentCompanyId: 'cd36e988-30d6-4f66-85d8-b704fc5827b5',
                        name: 'Skobes Bil Nyköping',
                        branchCode: '0328',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0328',
                            name: 'Skobes Bil Nord AB',
                            structure_type: 'Center',
                            district: '0328',
                            occupation: 'Sälj                Service',
                            center_district: '0328',
                            visit_address: 'Gumsbackevägen 4',
                            visit_post_number: '61138',
                            visit_post_town: 'Nyköping',
                            mail_address: 'Box 601',
                            mail_post_number: '61110',
                            mail_post_town: 'Nyköping',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0155-75200',
                            fax: '0155-75203',
                            company_email: 'jerry.thorn@skobes.se',
                            used_cars_email: 'jonas.wassberg@skobes.se',
                            new_cars_email: 'jerry.thorn@skobes.se',
                            new_contact_email: 'tobias.skobe@skobes.se',
                            home_page: '',
                            country: 'Sweden',
                            latitude: '58.7493445',
                            longitude: '16.97665',
                            business_group: '0345',
                            price_localization: 'SE1789'
                        }
                        }
                    },
                    {
                        companyId: '685fed65-8936-494e-bad8-eafaaf6d5555',
                        companyNumber: 'S309',
                        parentCompanyId: 'cd36e988-30d6-4f66-85d8-b704fc5827b5',
                        name: 'Skobes Bil Katrineholm',
                        branchCode: '0309',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0309',
                            name: 'Skobes Bil Nord AB',
                            structure_type: 'Filial',
                            district: '0309',
                            occupation: 'Sälj                Service',
                            center_district: '0328',
                            visit_address: 'Starrvägen 9',
                            visit_post_number: '64121',
                            visit_post_town: 'Katrineholm',
                            mail_address: 'Box 79',
                            mail_post_number: '64121',
                            mail_post_town: 'Katrineholm',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0150-73300',
                            fax: '0150-73303',
                            company_email: 'mariusz.eriksson@skobes.se',
                            used_cars_email: 'mariusz.eriksson@skobes.se',
                            new_cars_email: 'mariusz.eriksson@skobes.se',
                            new_contact_email: 'alexander.forss@skobes.se',
                            home_page: 'http://www.skobes.se/',
                            country: 'Sweden',
                            latitude: '59.0011711',
                            longitude: '16.2235606',
                            business_group: '0345',
                            price_localization: 'SE1789'
                        }
                        }
                    },
                    {
                        companyId: '81689374-946a-48a2-bb72-e0d1a54bd0e9',
                        companyNumber: 'S403',
                        parentCompanyId: 'cd36e988-30d6-4f66-85d8-b704fc5827b5',
                        name: 'Skobes Bil Flen',
                        branchCode: '0403',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0403',
                            name: 'Skobes Bil Nord AB',
                            structure_type: 'Filial',
                            district: '0403',
                            occupation: 'Sälj                Service',
                            center_district: '0328',
                            visit_address: 'Bolmängsgatan 1',
                            visit_post_number: '64232',
                            visit_post_town: 'Flen',
                            mail_address: 'Bolmängsgatan 1',
                            mail_post_number: '64232',
                            mail_post_town: 'Flen',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0157-12386',
                            fax: '0157-51326',
                            company_email: '',
                            used_cars_email: 'mariusz.eriksson@skobes.se',
                            new_cars_email: 'mariusz.eriksson@skobes.se',
                            new_contact_email: 'alexander.forss@skobes.se',
                            home_page: '',
                            country: 'Sweden',
                            latitude: '59.0627869',
                            longitude: '16.5895371',
                            business_group: '0345',
                            price_localization: 'SE1789'
                        }
                        }
                    },
                    {
                        companyId: 'dd7f0a06-c559-4100-aaf2-b0287688e473',
                        companyNumber: 'S353',
                        parentCompanyId: 'cd36e988-30d6-4f66-85d8-b704fc5827b5',
                        name: 'Skobes Bil Vimmerby',
                        branchCode: '0353',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0353',
                            name: 'Skobes Bil Öst AB',
                            structure_type: 'Center',
                            district: '0353',
                            occupation: 'Sälj                Service',
                            center_district: '0353',
                            visit_address: 'Maskingatan 5',
                            visit_post_number: '59840',
                            visit_post_town: 'Vimmerby',
                            mail_address: 'Box 54',
                            mail_post_number: '59821',
                            mail_post_town: 'Vimmerby',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0492-66200',
                            fax: '0492-10663',
                            company_email: 'daniel.lindstrom@skobes.se',
                            used_cars_email: 'Daniel.lindstrom@skobes.se',
                            new_cars_email: 'Daniel.lindstrom@skobes.se',
                            new_contact_email: 'jimmy.nilsson@karlbergsbil.se',
                            home_page: 'http://www.skobes.se/',
                            country: 'Sweden',
                            latitude: '57.6611992',
                            longitude: '15.8500318',
                            business_group: '0345',
                            price_localization: 'SE1789'
                        }
                        }
                    },
                    {
                        companyId: 'ba208168-8a19-411b-b66e-8ca16f40a4c5',
                        companyNumber: 'S337',
                        parentCompanyId: 'cd36e988-30d6-4f66-85d8-b704fc5827b5',
                        name: 'Skobes Bil Oskarshamn',
                        branchCode: '0337',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0337',
                            name: 'Skobes Bil Öst AB',
                            structure_type: 'Filial',
                            district: '0337',
                            occupation: 'Sälj                Service',
                            center_district: '0353',
                            visit_address: 'Inkörningsvägen 2',
                            visit_post_number: '57236',
                            visit_post_town: 'Oskarshamn',
                            mail_address: 'Box 515',
                            mail_post_number: '57236',
                            mail_post_town: 'Oskarshamn',
                            region: '',
                            address_2: '',
                            phone: '0491-89700',
                            fax: '',
                            company_email: 'johan.franzen@skobes.se',
                            used_cars_email: 'johan.franzen@skobes.se',
                            new_cars_email: 'johan.franzen@skobes.se',
                            new_contact_email: '',
                            home_page: 'http://www.skobes.se/',
                            country: 'Sweden',
                            latitude: '57.2515579',
                            longitude: '16.4438748',
                            business_group: '0345',
                            price_localization: 'SE1789'
                        }
                        }
                    },
                    {
                        companyId: '32746919-3a0e-4fea-acdd-5f4c19904d83',
                        companyNumber: 'S364',
                        parentCompanyId: 'cd36e988-30d6-4f66-85d8-b704fc5827b5',
                        name: 'Skobes Bil Västervik',
                        branchCode: '0364',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0364',
                            name: 'Skobes Bil Öst AB',
                            structure_type: 'Filial',
                            district: '0364',
                            occupation: 'Sälj                Service',
                            center_district: '0353',
                            visit_address: 'Breviksvägen 70',
                            visit_post_number: '59342',
                            visit_post_town: 'Västervik',
                            mail_address: 'Box 2044',
                            mail_post_number: '59302',
                            mail_post_town: 'Västervik',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0490-69500',
                            fax: '0490-35137',
                            company_email: 'cornelius.passchier@skobes.se',
                            used_cars_email: 'niklas.henningsson@skobes.se',
                            new_cars_email: 'niklas.henningsson@skobes.se',
                            new_contact_email: 'cornelius@karlbergsbil.se',
                            home_page: 'http://www.skobes.se/',
                            country: 'Sweden',
                            latitude: '57.7676547',
                            longitude: '16.6044128',
                            business_group: '0345',
                            price_localization: 'SE1789'
                        }
                        }
                    },
                    {
                        companyId: '06da5b3c-6e8c-4a3e-a644-13602f8636c0',
                        companyNumber: 'S355',
                        parentCompanyId: 'cd36e988-30d6-4f66-85d8-b704fc5827b5',
                        name: 'Skobes Bil Kisa',
                        branchCode: '0355',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0355',
                            name: 'Skobes Bil Öst AB',
                            structure_type: 'Filial',
                            district: '0355',
                            occupation: 'Sälj                Service',
                            center_district: '0353',
                            visit_address: 'Kalmarvägen 20',
                            visit_post_number: '59038',
                            visit_post_town: 'Kisa',
                            mail_address: 'Kalmarvägen 20',
                            mail_post_number: '59040',
                            mail_post_town: 'Kisa',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0494-15300',
                            fax: '0494-13293',
                            company_email: 'daniel.lindstrom@skobes.se',
                            used_cars_email: 'bjorn.wallroth@skobes.se',
                            new_cars_email: 'bjorn.wallroth@skobes.se',
                            new_contact_email: 'jimmy.nilsson@karlbergsbil.se',
                            home_page: 'http://www.skobes.se/',
                            country: 'Sweden',
                            latitude: '57.982232',
                            longitude: '15.6378541',
                            business_group: '0345',
                            price_localization: 'SE1789'
                        }
                        }
                    },
                    {
                        companyId: 'e9cdd440-caa9-4aef-bb22-19b2e8278d55',
                        companyNumber: 'S340',
                        parentCompanyId: 'cd36e988-30d6-4f66-85d8-b704fc5827b5',
                        name: 'Skobes Bil Högsby',
                        branchCode: '0340',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: 'c31c91aa-c5a8-4e8f-adc3-26fda61f4a44',
                        companyNumber: '532',
                        parentCompanyId: null,
                        name: 'Tage Rejmes Norrköping',
                        branchCode: '0370',
                        branchCodeAliases: [
                        '0372',
                        '0373'
                        ],
                        localisationId: 'SE8479',
                        authId: null,
                        afPassword: 'kaleha',
                        info: {
                        profile: {
                            id: '0370',
                            name: 'Tage Rejmes i Norrköping Bil AB',
                            structure_type: 'Center',
                            district: '0370',
                            occupation: 'Sälj                Service',
                            center_district: '0370',
                            visit_address: 'Lindövägen 60',
                            visit_post_number: '60105',
                            visit_post_town: 'Norrköping',
                            mail_address: 'Box 407',
                            mail_post_number: '60105',
                            mail_post_town: 'Norrköping',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '011-219000',
                            fax: '011-137110',
                            company_email: '',
                            used_cars_email: 'akif.jusupovic@rejmes.se',
                            new_cars_email: 'johan.rundgren@rejmes.se',
                            new_contact_email: 'jorgen.wibner@rejmes.se',
                            home_page: 'http://www.rejmes.se/',
                            country: 'Sweden',
                            latitude: '58.5903624',
                            longitude: '16.216141',
                            business_group: '0370',
                            price_localization: 'SE8479'
                        }
                        }
                    },
                    {
                        companyId: 'bc64a1fe-5a89-4522-99a0-40fbaabb5e53',
                        companyNumber: 'S314',
                        parentCompanyId: 'c31c91aa-c5a8-4e8f-adc3-26fda61f4a44',
                        name: 'Tage Rejmes Mjölby',
                        branchCode: '0314',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0314',
                            name: 'Tage Rejmes i Linköping Bil AB',
                            structure_type: 'Filial',
                            district: '0314',
                            occupation: 'Sälj                Service',
                            center_district: '0313',
                            visit_address: 'Kungsvägen 1',
                            visit_post_number: '59551',
                            visit_post_town: 'Mjölby',
                            mail_address: 'Kungsvägen 1',
                            mail_post_number: '59551',
                            mail_post_town: 'Mjölby',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0142-492100',
                            fax: '0142-16109',
                            company_email: 'info@rejmes.se',
                            used_cars_email: 'erik.lorin@rejmes.se',
                            new_cars_email: 'erik.lorin@rejmes.se',
                            new_contact_email: 'karin.franzon@rejmes.se',
                            home_page: 'http://www.rejmes.se/',
                            country: 'Sweden',
                            latitude: '58.3319912',
                            longitude: '15.1501665',
                            business_group: '0370',
                            price_localization: 'SE8479'
                        }
                        }
                    },
                    {
                        companyId: '205a3d31-8358-4d02-a2f8-28c0b96f615d',
                        companyNumber: 'S315',
                        parentCompanyId: 'c31c91aa-c5a8-4e8f-adc3-26fda61f4a44',
                        name: 'Tage Rejmes Åtvidaberg',
                        branchCode: '0315',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0315',
                            name: 'Tage Rejmes i Linköping Bil AB',
                            structure_type: 'Filial',
                            district: '0315',
                            occupation: 'Sälj                Service',
                            center_district: '0313',
                            visit_address: 'Prästängsvägen 1',
                            visit_post_number: '59730',
                            visit_post_town: 'Åtvidaberg',
                            mail_address: 'Box 177',
                            mail_post_number: '59724',
                            mail_post_town: 'Åtvidaberg',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0120-652000',
                            fax: '0120-652049',
                            company_email: 'info@rejmes.se',
                            used_cars_email: 'marcus.carlberg@rejmes.se',
                            new_cars_email: 'marcus.carlberg@rejmes.se',
                            new_contact_email: 'marcus.carlberg@rejmes.se',
                            home_page: 'http://www.rejmes.se/',
                            country: 'Sweden',
                            latitude: '58.203651',
                            longitude: '15.9998344',
                            business_group: '0370',
                            price_localization: 'SE8479'
                        }
                        }
                    },
                    {
                        companyId: 'fd71111f-0e84-4773-9a62-aa0ac360c175',
                        companyNumber: 'S343',
                        parentCompanyId: 'c31c91aa-c5a8-4e8f-adc3-26fda61f4a44',
                        name: 'Tage Rejmes Söderköping',
                        branchCode: '0343',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '18f9cd98-7353-4b30-b5fc-74dca9ba4554',
                        companyNumber: 'S344',
                        parentCompanyId: 'c31c91aa-c5a8-4e8f-adc3-26fda61f4a44',
                        name: 'Tage Rejmes Valdemarsvik',
                        branchCode: '0344',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '2ca92cad-2a2b-4e1a-b703-a12d0dd89d31',
                        companyNumber: 'S565',
                        parentCompanyId: 'c31c91aa-c5a8-4e8f-adc3-26fda61f4a44',
                        name: 'Tage Rejmes Örebro',
                        branchCode: '0565',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0565',
                            name: 'Tage Rejmes i Örebro Bil AB',
                            structure_type: 'Center',
                            district: '0565',
                            occupation: 'Sälj                Service',
                            center_district: '0565',
                            visit_address: 'Bettorpsgatan 4-6',
                            visit_post_number: '70369',
                            visit_post_town: 'Örebro',
                            mail_address: 'Box 2042',
                            mail_post_number: '70002',
                            mail_post_town: 'Örebro',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '019-301700',
                            fax: '019-310705',
                            company_email: 'info@rejmes.se',
                            used_cars_email: 'hakan.arnelind@rejmes.se',
                            new_cars_email: 'karin.barton@rejmes.se',
                            new_contact_email: 'monika.pettersson@rejmes.se',
                            home_page: 'http://www.rejmes.se/',
                            country: 'Sweden',
                            latitude: '59.2978026',
                            longitude: '15.2316586',
                            business_group: '0370',
                            price_localization: 'SE8479'
                        }
                        }
                    },
                    {
                        companyId: 'b4e86307-64d0-41bb-8338-5ef7d12c6ede',
                        companyNumber: 'S313',
                        parentCompanyId: 'c31c91aa-c5a8-4e8f-adc3-26fda61f4a44',
                        name: 'Tage Rejmes Linköping',
                        branchCode: '0313',
                        branchCodeAliases: [
                        '0312'
                        ],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0313',
                            name: 'Tage Rejmes i Linköping Bil AB',
                            structure_type: 'Center',
                            district: '0313',
                            occupation: 'Sälj                Service',
                            center_district: '0313',
                            visit_address: 'Vigfastgatan 1',
                            visit_post_number: '58278',
                            visit_post_town: 'Linköping',
                            mail_address: 'Box 333',
                            mail_post_number: '58103',
                            mail_post_town: 'Linköping',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '013-203500',
                            fax: '013-135755',
                            company_email: 'info@rejmes.se',
                            used_cars_email: 'kundservice.linkoping@rejmes.se',
                            new_cars_email: 'Erik.Brodin@rejmes.se',
                            new_contact_email: 'karin.franzon@rejmes.se',
                            home_page: 'http://www.rejmes.se/',
                            country: 'Sweden',
                            latitude: '58.4174056',
                            longitude: '15.6389305',
                            business_group: '0370',
                            price_localization: 'SE8479'
                        }
                        }
                    },
                    {
                        companyId: 'c3889e2b-377f-43a2-83d5-f5b5f8bf848b',
                        companyNumber: 'S568',
                        parentCompanyId: 'c31c91aa-c5a8-4e8f-adc3-26fda61f4a44',
                        name: 'Tage Rejmes Hallsberg',
                        branchCode: '0568',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0568',
                            name: 'Tage Rejmes i Örebro Bil AB',
                            structure_type: 'Filial',
                            district: '0568',
                            occupation: 'Sälj                Service',
                            center_district: '0565',
                            visit_address: 'Tegelgatan 7',
                            visit_post_number: '69432',
                            visit_post_town: 'Hallsberg',
                            mail_address: 'Tegelgatan 7',
                            mail_post_number: '69432',
                            mail_post_town: 'Hallsberg',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0582-762600',
                            fax: '0582-762611',
                            company_email: 'info@rejmes.se',
                            used_cars_email: 'lars.svensson@rejmes.se',
                            new_cars_email: 'lars.svensson@rejmes.se',
                            new_contact_email: 'monika.pettersson@rejmes.se',
                            home_page: 'http://www.rejmes.se/',
                            country: 'Sweden',
                            latitude: '59.069469',
                            longitude: '15.101371',
                            business_group: '0370',
                            price_localization: 'SE8479'
                        }
                        }
                    },
                    {
                        companyId: '1271732a-3942-4e74-a933-edc1cc1e43f2',
                        companyNumber: 'S371',
                        parentCompanyId: 'c31c91aa-c5a8-4e8f-adc3-26fda61f4a44',
                        name: 'Tage Rejmes Finspång',
                        branchCode: '0371',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0371',
                            name: 'Tage Rejmes i Norrköping Bil AB',
                            structure_type: 'Filial',
                            district: '0371',
                            occupation: 'Sälj                Service',
                            center_district: '0370',
                            visit_address: 'Norrköpingsvägen 25',
                            visit_post_number: '61221',
                            visit_post_town: 'Finspång',
                            mail_address: 'Norrköpingsvägen 25',
                            mail_post_number: '61221',
                            mail_post_town: 'Finspång',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0122-23400',
                            fax: '0122-12840',
                            company_email: '',
                            used_cars_email: 'akif.jusupovic@rejmes.se',
                            new_cars_email: 'johan.rundgren@rejmes.se',
                            new_contact_email: 'jorgen.wibner@rejmes.se',
                            home_page: 'http://www.rejmes.se/',
                            country: 'Sweden',
                            latitude: '58.703898',
                            longitude: '15.7954759',
                            business_group: '0370',
                            price_localization: 'SE8479'
                        }
                        }
                    },
                    {
                        companyId: '692bde12-326a-4bb5-beed-9e9f5e139ea4',
                        companyNumber: 'S538',
                        parentCompanyId: 'c31c91aa-c5a8-4e8f-adc3-26fda61f4a44',
                        name: 'Tage Rejmes Askersund',
                        branchCode: '0538',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0538',
                            name: 'Tage Rejmes i Örebro Bil AB',
                            structure_type: 'Filial',
                            district: '0538',
                            occupation: 'Sälj                Service',
                            center_district: '0565',
                            visit_address: 'Duvnäsvägen 2',
                            visit_post_number: '69632',
                            visit_post_town: 'Askersund',
                            mail_address: 'Duvnäsvägen 2',
                            mail_post_number: '69632',
                            mail_post_town: 'Askersund',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0583-561100',
                            fax: '0583-711080',
                            company_email: 'info@rejmes.se',
                            used_cars_email: 'peter.dawidsson@rejmes.se',
                            new_cars_email: 'peter.dawidsson@rejmes.se',
                            new_contact_email: 'monika.pettersson@rejmes.se',
                            home_page: 'http://www.rejmes.se/',
                            country: 'Sweden',
                            latitude: '58.8901327',
                            longitude: '14.8975217',
                            business_group: '0370',
                            price_localization: 'SE8479'
                        }
                        }
                    },
                    {
                        companyId: '672e00ce-30d9-4623-a29b-3543fe06b5b4',
                        companyNumber: 'S566',
                        parentCompanyId: 'c31c91aa-c5a8-4e8f-adc3-26fda61f4a44',
                        name: 'Tage Rejmes Lindesberg',
                        branchCode: '0566',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0566',
                            name: 'Tage Rejmes i Örebro Bil AB',
                            structure_type: 'Filial',
                            district: '0566',
                            occupation: 'Sälj                Service',
                            center_district: '0565',
                            visit_address: 'Bergslagsvägen',
                            visit_post_number: '71134',
                            visit_post_town: 'Lindesberg',
                            mail_address: 'Box 46',
                            mail_post_number: '71121',
                            mail_post_town: 'Lindesberg',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0581-762300',
                            fax: '0581-10024',
                            company_email: 'info@rejmes.se',
                            used_cars_email: 'peter.ericson@rejmes.se',
                            new_cars_email: 'peter.ericson@rejmes.se',
                            new_contact_email: 'monika.pettersson@rejmes.se',
                            home_page: 'http://www.rejmes.se/',
                            country: 'Sweden',
                            latitude: '59.6061547',
                            longitude: '15.2025713',
                            business_group: '0370',
                            price_localization: 'SE8479'
                        }
                        }
                    },
                    {
                        companyId: '15a408b7-a50a-4e0c-b96c-2efe30cb79b2',
                        companyNumber: '000',
                        parentCompanyId: null,
                        name: 'Allmänna Bilaffären',
                        branchCode: '0000',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        facilities: [
                            {
                            name: 'Verkstad',
                            telephone: '',
                            sort_order: 0,
                            opening_hours: {
                                monday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                tuesday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                wednesday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                thursday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                friday: {
                                from: '09:00',
                                to: '17:0'
                                }
                            }
                            },
                            {
                            name: 'Däckhotell',
                            telephone: '',
                            sort_order: 1,
                            opening_hours: {
                                monday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                tuesday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                wednesday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                thursday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                friday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                saturday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                sunday: {
                                from: '09:00',
                                to: '17:00'
                                }
                            }
                            }
                        ]
                        }
                    },
                    {
                        companyId: '8a8d1086-b98e-4d32-a8a8-0eb1b3088642',
                        companyNumber: '547',
                        parentCompanyId: null,
                        name: 'Nybergs Bil Jönköping',
                        branchCode: '0305',
                        branchCodeAliases: [],
                        localisationId: 'SE1532',
                        authId: null,
                        afPassword: 'dosabo',
                        info: {
                        profile: {
                            id: '0305',
                            name: 'Nybergs Bil AB',
                            structure_type: 'Center',
                            district: '0305',
                            occupation: 'Sälj                Service',
                            center_district: '0305',
                            visit_address: 'Odengatan 38',
                            visit_post_number: '55323',
                            visit_post_town: 'Jönköping',
                            mail_address: 'Box 2213',
                            mail_post_number: '55002',
                            mail_post_town: 'Jönköping',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '036-304600',
                            fax: '036-304673',
                            company_email: 'nybergsbil@nybergsbil.se',
                            used_cars_email: 'thomas.lindquist@nybergsbil.se',
                            new_cars_email: 'mattias.geller@nybergsbil.se',
                            new_contact_email: 'susanne.enger@nybergsbil.se',
                            home_page: 'http://www.nybergsbil.se/',
                            country: 'Sweden',
                            latitude: '57.7786592',
                            longitude: '14.1855256',
                            business_group: '0305',
                            price_localization: 'SE1532'
                        }
                        }
                    },
                    {
                        companyId: '89628773-aba9-4f1f-9072-70f0db0f3d83',
                        companyNumber: 'S335',
                        parentCompanyId: '8a8d1086-b98e-4d32-a8a8-0eb1b3088642',
                        name: 'Nybergs Bil Eksjö',
                        branchCode: '0335',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '9cd6d36c-48f7-4ee8-a513-8df77289c97f',
                        companyNumber: 'S333',
                        parentCompanyId: '8a8d1086-b98e-4d32-a8a8-0eb1b3088642',
                        name: 'Nybergs Bil Nässjö',
                        branchCode: '0333',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0333',
                            name: 'Nybergs Bil AB',
                            structure_type: 'Filial',
                            district: '0333',
                            occupation: 'Sälj                Service',
                            center_district: '0305',
                            visit_address: 'Sörängsvägen 64',
                            visit_post_number: '57138',
                            visit_post_town: 'Nässjö',
                            mail_address: 'Sörängsvägen 64',
                            mail_post_number: '57138',
                            mail_post_town: 'Nässjö',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0380-373737',
                            fax: '0380-373727',
                            company_email: 'nybergsbil@nybergsbil.se',
                            used_cars_email: 'nassjo@nybergsbil.se',
                            new_cars_email: 'nassjo@nybergsbil.se',
                            new_contact_email: 'susanne.enger@nybergsbil.se',
                            home_page: 'http://www.nybergsbil.se/',
                            country: 'Sweden',
                            latitude: '57.6471995',
                            longitude: '14.7313166',
                            business_group: '0305',
                            price_localization: 'SE1532'
                        }
                        }
                    },
                    {
                        companyId: '8584b333-ab1e-451b-998d-4c00a8b32d86',
                        companyNumber: '001',
                        parentCompanyId: null,
                        name: 'Volvo Bil',
                        branchCode: '0686',
                        branchCodeAliases: [],
                        localisationId: 'SE8841',
                        authId: null,
                        afPassword: 'degake',
                        info: {
                        profile: {
                            id: '0686',
                            name: 'Volvo Bil i Gbg AB (för Volvoanställda)',
                            structure_type: 'Filial',
                            district: '0686',
                            occupation: 'Sälj',
                            center_district: '068x',
                            visit_address: 'Huggåsvägen',
                            visit_post_number: '41878',
                            visit_post_town: 'Göteborg',
                            mail_address: 'Huggåsvägen',
                            mail_post_number: '41878',
                            mail_post_town: 'Göteborg',
                            region: '',
                            address_2: '',
                            phone: '031-3808000',
                            fax: '031-380 8005',
                            company_email: 'info@volvobil.se',
                            used_cars_email: 'saljservice@volvobil.se',
                            new_cars_email: 'saljservice@volvobil.se',
                            new_contact_email: '',
                            home_page: 'www.volvobil.se',
                            country: 'Sweden',
                            latitude: '57.7180056',
                            longitude: '11.846334',
                            business_group: '0686',
                            price_localization: 'SE8841'
                        },
                        facilities: [
                            {
                            name: 'Försäljning & Butik',
                            telephone: '',
                            sort_order: '0',
                            opening_hours: {
                                sunday: null,
                                saturday: null,
                                friday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                thursday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                monday: {
                                from: '07:00',
                                to: '17:00'
                                }
                            },
                            exceptions: [
                                {
                                reason: 'Nationaldagen',
                                date: '2018-06-05T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Sommarstängt Helg',
                                date: '2018-07-13T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Sommarstängt Helg',
                                date: '2018-07-14T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Sommarstängt Helg',
                                date: '2018-07-20T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Sommarstängt Helg',
                                date: '2018-07-21T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Sommarstängt Helg',
                                date: '2018-07-27T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Sommarstängt Helg',
                                date: '2018-07-28T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Sommarstängt Helg',
                                date: '2018-08-03T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Sommarstängt Helg',
                                date: '2018-08-04T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Midsommarafton',
                                date: '2018-06-21T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Midsommardagen',
                                date: '2018-06-22T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: '',
                                date: '2018-06-23T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Alla helgons dag',
                                date: '2019-11-01T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Julafton',
                                date: '2019-12-23T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Juldagen',
                                date: '2019-12-24T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Annan dag jul',
                                date: '2019-12-25T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Nyårsafton',
                                date: '2019-12-30T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Nyårsdagen',
                                date: '2019-12-31T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Trettondedag jul',
                                date: '2020-01-05T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Konferens för Volvo Bil',
                                date: '2020-01-28T23:00:00.000Z',
                                opening: '07:00',
                                closing: '11:00'
                                },
                                {
                                reason: 'Långfredag',
                                date: '2020-04-09T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Annandag Påsk',
                                date: '2020-04-12T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Första Maj',
                                date: '2020-04-30T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Kristi Himmelfärds dag',
                                date: '2020-05-20T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Midsommarafton',
                                date: '2020-06-18T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                }
                            ],
                            department: 'showroom'
                            },
                            {
                            name: 'Serviceverkstad kvällservice',
                            telephone: '',
                            sort_order: 1,
                            opening_hours: {
                                monday: {
                                from: '16:00',
                                to: '00:30'
                                },
                                tuesday: {
                                from: '16:00',
                                to: '00:30'
                                },
                                wednesday: {
                                from: '16:00',
                                to: '00:30'
                                },
                                thursday: {
                                from: '16:00',
                                to: '00:30'
                                },
                                friday: null
                            },
                            exceptions: [
                                {
                                reason: 'Nationaldagen',
                                date: '2018-06-05T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: '',
                                date: '2018-06-21T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Julafton',
                                date: '2019-12-23T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Juldagen',
                                date: '2019-12-24T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Annandag jul',
                                date: '2019-12-25T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Nyårsafton',
                                date: '2019-12-30T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Nyårsdagen',
                                date: '2019-12-31T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Trettondedag jul',
                                date: '2020-01-05T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Konferens för Volvo Bil',
                                date: '2020-01-28T23:00:00.000Z',
                                opening: '18:00',
                                closing: '00:30'
                                },
                                {
                                reason: 'Annandag Påsk',
                                date: '2020-04-12T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Första Maj',
                                date: '2020-04-30T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Kristi Himmelfärds dag',
                                date: '2020-05-20T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Midsommarafton',
                                date: '2020-06-18T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                }
                            ],
                            department: 'carwash'
                            },
                            {
                            name: 'Serviceverkstad',
                            telephone: '',
                            sort_order: 2,
                            opening_hours: {
                                monday: {
                                from: '07:00',
                                to: '15:30'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '15:30'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '15:30'
                                },
                                thursday: {
                                from: '07:00',
                                to: '15:30'
                                },
                                friday: {
                                from: '07:00',
                                to: '15:30'
                                }
                            },
                            exceptions: [
                                {
                                reason: 'Nationaldagen',
                                date: '2018-06-05T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Midsommarafton',
                                date: '2018-06-21T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Julafton',
                                date: '2019-12-23T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Juldagen',
                                date: '2019-12-24T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Annandag jul',
                                date: '2019-12-25T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Nyårsafton',
                                date: '2019-12-30T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Nyårsdagen',
                                date: '2019-12-31T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Trettondedag jul',
                                date: '2020-01-05T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Konferens för Volvo Bil',
                                date: '2020-01-28T23:00:00.000Z',
                                opening: '07:00',
                                closing: '11:00'
                                },
                                {
                                reason: 'Långfredag',
                                date: '2020-04-09T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Annandag Påsk',
                                date: '2020-04-12T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Första Maj',
                                date: '2020-04-30T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Kristi Himmelfärds dag',
                                date: '2020-05-20T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Midsommarafton',
                                date: '2020-06-18T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                }
                            ],
                            department: 'service'
                            },
                            {
                            name: 'Biluthyrning',
                            telephone: '',
                            sort_order: 3,
                            opening_hours: {
                                monday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                thursday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                friday: {
                                from: '07:00',
                                to: '17:00'
                                }
                            },
                            exceptions: [
                                {
                                reason: 'Nationaldagen',
                                date: '2018-06-05T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Julafton',
                                date: '2019-12-23T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Juldagen',
                                date: '2019-12-24T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Annandag jul',
                                date: '2019-12-25T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Nyårsafton',
                                date: '2019-12-30T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Nyårsdagen',
                                date: '2019-12-31T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Trettondedag jul',
                                date: '2020-01-05T23:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Konferens för Volvo Bil',
                                date: '2020-01-28T23:00:00.000Z',
                                opening: '07:00',
                                closing: '11:00'
                                },
                                {
                                reason: 'Långfredag',
                                date: '2020-04-09T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Annandag Påsk',
                                date: '2020-04-12T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Första Maj',
                                date: '2020-04-30T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Kristi Himmelfärds dag',
                                date: '2020-05-20T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                },
                                {
                                reason: 'Midsommarafton',
                                date: '2020-06-18T22:00:00.000Z',
                                opening: '',
                                closing: '',
                                closed: true
                                }
                            ],
                            department: 'store'
                            }
                        ]
                        }
                    },
                    {
                        companyId: '6147345a-da94-43b8-b3d8-21068eed9168',
                        companyNumber: 'S681',
                        parentCompanyId: '8584b333-ab1e-451b-998d-4c00a8b32d86',
                        name: 'Volvo Bil - Volvocity',
                        branchCode: '0681',
                        branchCodeAliases: [
                        '0771',
                        '0773',
                        '0774',
                        '0779',
                        '0786',
                        '0787',
                        '0788'
                        ],
                        localisationId: 'SE8841',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '55c9cc6d-bb29-455e-98dc-a0f5e247e8ef',
                        companyNumber: 'S687',
                        parentCompanyId: '8584b333-ab1e-451b-998d-4c00a8b32d86',
                        name: 'Volvo Bil - Arendal',
                        branchCode: '0687',
                        branchCodeAliases: [],
                        localisationId: 'SE8841',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: 'c4c0b106-310a-42b3-bb8d-db962d7c147d',
                        companyNumber: 'S683',
                        parentCompanyId: '8584b333-ab1e-451b-998d-4c00a8b32d86',
                        name: 'Volvo Bil - Lundby',
                        branchCode: '0683',
                        branchCodeAliases: [],
                        localisationId: 'SE8841',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '976e50cb-d991-4386-bec7-aa66156bf637',
                        companyNumber: 'S685',
                        parentCompanyId: '8584b333-ab1e-451b-998d-4c00a8b32d86',
                        name: 'Volvo Bil - Tuve',
                        branchCode: '0685',
                        branchCodeAliases: [],
                        localisationId: 'SE8841',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        companyNumber: '002',
                        parentCompanyId: null,
                        name: 'Bilia',
                        branchCode: '0292',
                        branchCodeAliases: [],
                        localisationId: 'SE1076',
                        authId: null,
                        afPassword: 'lagiho',
                        info: {
                        profile: {
                            id: '0292',
                            name: 'Bilia Personbilar AB, Region Väst / Sisjön',
                            structure_type: 'Center',
                            district: '0292',
                            occupation: 'Sälj                Service',
                            center_district: '0292',
                            visit_address: 'Norra Långebergsgatan 3',
                            visit_post_number: '40097',
                            visit_post_town: 'Göteborg',
                            mail_address: 'Box 9300',
                            mail_post_number: '40097',
                            mail_post_town: 'Göteborg',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '031-896178',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'elisabeth.henriksen@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '57.6478303',
                            longitude: '11.9535845',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: 'a343c6f3-1311-4278-9708-9f2abab2d6e6',
                        companyNumber: 'S387',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Lerum',
                        branchCode: '0387',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '8ca908e7-93f5-4d2b-b470-83ae0fd356d2',
                        companyNumber: 'S284',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Sävedalen',
                        branchCode: '0284',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0284',
                            name: 'Bilia Personbilar AB / Sävedalen',
                            structure_type: 'Filial',
                            district: '0284',
                            occupation: 'Sälj                Service',
                            center_district: '0292',
                            visit_address: 'Göteborgsvägen 36',
                            visit_post_number: '43360',
                            visit_post_town: 'Göteborg',
                            mail_address: 'Göteborgsvägen 36',
                            mail_post_number: '43360',
                            mail_post_town: 'Sävedalen',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '031-3408741',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'kenny.brovall@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '57.7317456',
                            longitude: '12.0655702',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: '9aa2ee40-b7a9-4caa-b5b5-1013b6db729b',
                        companyNumber: 'S222',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Kungsbacka',
                        branchCode: '0222',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0222',
                            name: 'Bilia Personbilar AB, Region Väst',
                            structure_type: 'Center',
                            district: '0222',
                            occupation: 'Sälj                Service',
                            center_district: '0222',
                            visit_address: 'Hallabäcksvägen 2',
                            visit_post_number: '43439',
                            visit_post_town: 'Kungsbacka',
                            mail_address: 'Hallabäcksvägen 2',
                            mail_post_number: '43439',
                            mail_post_town: 'Kungsbacka',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0300-573811',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'elisabeth.henriksen@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '57.5101477',
                            longitude: '12.0646638',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: 'dcf8e0cc-8107-4a38-841c-efddd509bb71',
                        companyNumber: 'S294',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Almedal',
                        branchCode: '0294',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '1ceb9afc-482f-4184-815f-0b801b434cb3',
                        companyNumber: 'S295',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Nordstan',
                        branchCode: '0295',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        facilities: []
                        }
                    },
                    {
                        companyId: '3481b17d-9424-4a39-9470-c65bb0d049b6',
                        companyNumber: 'S289',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Hisingen',
                        branchCode: '0289',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: 'fc212622-595e-45a9-89aa-88ddd9441eab',
                        companyNumber: 'S229',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Kungälv',
                        branchCode: '0229',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0229',
                            name: 'Bilia Personbilar AB, Region Väst',
                            structure_type: 'Center',
                            district: '0229',
                            occupation: 'Sälj                Service',
                            center_district: '0229',
                            visit_address: 'Bultgatan 38',
                            visit_post_number: '44240',
                            visit_post_town: 'Kungälv',
                            mail_address: 'Bultgatan 38',
                            mail_post_number: '44240',
                            mail_post_town: 'Kungälv',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0303-35 27 19',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'elisabeth.henriksen@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '57.8811076',
                            longitude: '11.9488065',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        },
                        facilities: [
                            {
                            name: 'Bilia Kungälv',
                            telephone: '010-497 80 00',
                            sort_order: 0,
                            opening_hours: {
                                monday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                tuesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                wednesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                thursday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                friday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                saturday: {
                                from: '11:00',
                                to: '15:00'
                                }
                            },
                            exceptions: [],
                            department: 'showroom'
                            },
                            {
                            name: 'Bilia Kungälv',
                            telephone: '',
                            sort_order: 1,
                            opening_hours: {
                                monday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                thursday: {
                                from: '07:00',
                                to: '17:00'
                                },
                                friday: {
                                from: '07:00',
                                to: '16:30'
                                }
                            },
                            exceptions: [],
                            department: 'service'
                            }
                        ]
                        }
                    },
                    {
                        companyId: 'c8c05da5-a48e-43d2-ae8f-6743b5d97919',
                        companyNumber: 'S231',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Stenungsund',
                        branchCode: '0231',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0231',
                            name: 'Bilia Personbilar AB, Region Väst',
                            structure_type: 'Filial',
                            district: '0231',
                            occupation: 'Sälj                Service',
                            center_district: '0229',
                            visit_address: 'Doterödsvägen 40',
                            visit_post_number: '44432',
                            visit_post_town: 'Stenungsund',
                            mail_address: 'Doterödsgatan 40',
                            mail_post_number: '44432',
                            mail_post_town: 'Stenungsund',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0303-352821',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: '',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '58.0739567',
                            longitude: '11.8396472',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: '8cf79cc2-4f67-4a65-9324-181ddcc53ecb',
                        companyNumber: 'S465',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Uppsala',
                        branchCode: '0465',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0465',
                            name: 'Bilia Personbilar AB',
                            structure_type: 'Filial',
                            district: '0465',
                            occupation: 'Sälj                Service',
                            center_district: '0484',
                            visit_address: 'Fyrislundsgatan 76',
                            visit_post_number: '75450',
                            visit_post_town: 'Uppsala',
                            mail_address: 'Fyrislundsgatan 76',
                            mail_post_number: '75450',
                            mail_post_town: 'Uppsala',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '018-128699',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'christer.edin@bilia.se',
                            home_page: 'http://www.bilia.se/kontakt/omraden/uppsala/uppsala/',
                            country: 'Sweden',
                            latitude: '59.8536769',
                            longitude: '17.6938591',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: 'dd3f36fd-acbb-4d8d-b7e1-c98251f8d1c3',
                        companyNumber: 'S484',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Solna',
                        branchCode: '0484',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0484',
                            name: 'Bilia Personbilar AB / Solna',
                            structure_type: 'Center',
                            district: '0484',
                            occupation: 'Sälj                Service',
                            center_district: '0484',
                            visit_address: 'Frösundaleden 4',
                            visit_post_number: '16980',
                            visit_post_town: 'Stockholm',
                            mail_address: 'Frösundaleden 4',
                            mail_post_number: '16980',
                            mail_post_town: 'Solna',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '08-7347010',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'johan.frisk@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '59.3649916',
                            longitude: '18.018476',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: '4dc1523e-6602-4238-b867-4d0d797a7fc4',
                        companyNumber: 'S486',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Alvik',
                        branchCode: '0486',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '75bba1cc-f5d5-4a6b-b91f-e401792677b5',
                        companyNumber: 'S590',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Jakobsberg',
                        branchCode: '0590',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: 'ee72eb3f-26d3-4bf5-aeac-ca5895e83793',
                        companyNumber: 'S490',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Vinsta',
                        branchCode: '0490',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: 'e25a9e25-4c66-414c-b8a2-90a28bc8a9fb',
                        companyNumber: 'S494',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Kista',
                        branchCode: '0494',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '1547d0de-ec7b-40cd-a364-6a29e56c198e',
                        companyNumber: 'S478',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Lidingö',
                        branchCode: '0478',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '7d666c22-ada7-49a5-a566-3a3f6b7c5eae',
                        companyNumber: 'S427',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Haninge',
                        branchCode: '0427',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0427',
                            name: 'Bilia Personbilar AB / Haninge',
                            structure_type: 'Center',
                            district: '0427',
                            occupation: 'Sälj                Service',
                            center_district: '0427',
                            visit_address: 'Kilowattsvägen 11',
                            visit_post_number: '13644',
                            visit_post_town: 'Stockholm',
                            mail_address: 'Kilowattsvägen 11',
                            mail_post_number: '13644',
                            mail_post_town: 'Handen',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '08-4706211',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'jan.duse@bilia.se',
                            home_page: '',
                            country: 'Sweden',
                            latitude: '59.1652096',
                            longitude: '18.1616882',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: '0680b299-e4c1-4b1c-af0d-87068f368006',
                        companyNumber: 'S461',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Nacka',
                        branchCode: '0461',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0461',
                            name: 'Bilia Personbilar AB / Nacka',
                            structure_type: 'Center',
                            district: '0461',
                            occupation: 'Sälj                Service',
                            center_district: '0461',
                            visit_address: 'Jarlabergsvägen 2',
                            visit_post_number: '13124',
                            visit_post_town: 'Stockholm',
                            mail_address: 'Jarlabergsvägen 2',
                            mail_post_number: '13124',
                            mail_post_town: 'Nacka',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '08-50578294',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'jan.georgenson@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '59.313326',
                            longitude: '18.1690154',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: '821b8816-35d1-41b1-9eed-f2c3c0134814',
                        companyNumber: 'S462',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Tyresö',
                        branchCode: '0462',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '409d0651-4a84-4ac6-94af-90daf9dceb4e',
                        companyNumber: 'S497',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Täby',
                        branchCode: '0497',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0497',
                            name: 'Bilia Personbilar AB / Täby',
                            structure_type: 'Center',
                            district: '0497',
                            occupation: 'Sälj                Service',
                            center_district: '0497',
                            visit_address: 'Enhagsvägen 6',
                            visit_post_number: '18740',
                            visit_post_town: 'Stockholm',
                            mail_address: 'Enhagsvägen 6',
                            mail_post_number: '18740',
                            mail_post_town: 'Täby',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'anders.berg2@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '59.4393689',
                            longitude: '18.0493129',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: 'cf9fe4af-c5e3-4919-92a4-8ab65fb82d80',
                        companyNumber: 'S425',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Södertälje',
                        branchCode: '0425',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0425',
                            name: 'Bilia Personbilar AB, Region Stockholm',
                            structure_type: 'Center',
                            district: '0425',
                            occupation: 'Sälj                Service',
                            center_district: '0425',
                            visit_address: 'Morabergsvägen 12',
                            visit_post_number: '15242',
                            visit_post_town: 'Södertälje',
                            mail_address: 'Morabergsvägen 12',
                            mail_post_number: '15242',
                            mail_post_town: 'Södertälje',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '08-4706111',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'jan.duse@bilia.se',
                            home_page: 'http://www.bilia.se/kontakt/omraden/stockholmsomradet/sodert',
                            country: 'Sweden',
                            latitude: '59.2030268',
                            longitude: '17.6587394',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: '70fcc021-844c-49e6-9ab6-6e23d78c185e',
                        companyNumber: 'S415',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Segeltorp',
                        branchCode: '0415',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0415',
                            name: 'Bilia Personbilar AB, Region Stockholm / Segeltorp',
                            structure_type: 'Center',
                            district: '0415',
                            occupation: 'Sälj                Service',
                            center_district: '0415',
                            visit_address: 'Häradsvägen 255',
                            visit_post_number: '14172',
                            visit_post_town: 'Stockholm',
                            mail_address: 'Häradsvägen 255',
                            mail_post_number: '14172',
                            mail_post_town: 'Segeltorp',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '08-7448175',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'erik.rydheimer@bilia.se',
                            home_page: 'http://bilia.se/',
                            country: 'Sweden',
                            latitude: '59.2849843',
                            longitude: '17.945541',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: '40e6e7e5-974b-435f-aa37-0bc410461132',
                        companyNumber: 'S422',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Södermalm',
                        branchCode: '0422',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '890e6ab6-b70d-4729-a9db-a0ef83bce022',
                        companyNumber: 'S428',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Tumba',
                        branchCode: '0428',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '09f20505-7067-46e9-8677-2616f21dfa27',
                        companyNumber: 'S563',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Västerås',
                        branchCode: '0563',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0563',
                            name: 'Bilia Personbilar AB, Region Mälardalen',
                            structure_type: 'Center',
                            district: '0563',
                            occupation: 'Sälj                Service',
                            center_district: '0563',
                            visit_address: 'Strömledningsgatan 11',
                            visit_post_number: '72137',
                            visit_post_town: 'Västerås',
                            mail_address: 'Box 801',
                            mail_post_number: '72122',
                            mail_post_town: 'Västerås',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '021-17 88 07',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'ulla.samuelsson@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '59.642078',
                            longitude: '16.5658672',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        },
                        facilities: [
                            {
                            name: 'Bilia Västerås',
                            telephone: '010-497 25 00',
                            sort_order: 0,
                            opening_hours: {
                                monday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                thursday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                friday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                saturday: {
                                from: '11:00',
                                to: '15:00'
                                },
                                sunday: {
                                from: '11:00',
                                to: '15:00'
                                }
                            },
                            exceptions: [],
                            department: 'showroom'
                            }
                        ]
                        }
                    },
                    {
                        companyId: '22b3a76d-de11-4ad4-8ed7-8c3ac8d26756',
                        companyNumber: 'S521',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Hedemora',
                        branchCode: '0521',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0521',
                            name: 'Bilia Personbilar AB, Region Mälardalen',
                            structure_type: 'Filial',
                            district: '0521',
                            occupation: 'Sälj                Service',
                            center_district: '0563',
                            visit_address: 'Gussarvsgatan 31',
                            visit_post_number: '77630',
                            visit_post_town: 'Hedemora',
                            mail_address: 'Box 7',
                            mail_post_number: '77621',
                            mail_post_town: 'Hedemora',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0225-154 10',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'ulla.samuelsson@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '60.2840407',
                            longitude: '15.992093',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: '6becaee3-b578-4070-93e7-cb0414f6f16c',
                        companyNumber: 'S534',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Arboga',
                        branchCode: '0534',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: 'c694e722-af89-4ed7-af36-dd45898ae1f0',
                        companyNumber: 'S519',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Avesta',
                        branchCode: '0519',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0519',
                            name: 'Bilia Personbilar AB, Region Mälardalen',
                            structure_type: 'Filial',
                            district: '0519',
                            occupation: 'Sälj                Service',
                            center_district: '0563',
                            visit_address: 'Bergsnäsgatan 2',
                            visit_post_number: '77441',
                            visit_post_town: 'Avesta',
                            mail_address: 'Box 43',
                            mail_post_number: '77421',
                            mail_post_town: 'Avesta',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0226-53605',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'ulla.samuelsson@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '60.1418817',
                            longitude: '16.1944672',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: '06c9b928-da7d-40c3-92f1-bc7b96467e03',
                        companyNumber: 'S536',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Köping',
                        branchCode: '0536',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0536',
                            name: 'Bilia Personbilar AB, Region Mälardalen',
                            structure_type: 'Filial',
                            district: '0536',
                            occupation: 'Sälj                Service',
                            center_district: '0563',
                            visit_address: 'Ringvägen 75',
                            visit_post_number: '73133',
                            visit_post_town: 'Köping',
                            mail_address: 'Ringvägen 75',
                            mail_post_number: '73133',
                            mail_post_town: 'Köping',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0221-58 48 09',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'ulla.samuelsson@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '59.5199299',
                            longitude: '15.9735705',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: 'b0090f15-cb71-414a-bc58-778853b2b2ed',
                        companyNumber: 'S405',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Eskilstuna',
                        branchCode: '0405',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0405',
                            name: 'Bilia Personbilar AB, Region Mälardalen',
                            structure_type: 'Center',
                            district: '0405',
                            occupation: 'Sälj                Service',
                            center_district: '0405',
                            visit_address: 'Mått Johanssons väg 54',
                            visit_post_number: '63346',
                            visit_post_town: 'Eskilstuna',
                            mail_address: 'Box 540',
                            mail_post_number: '63107',
                            mail_post_town: 'Eskilstuna',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '016-131275',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'per.larsson@bilia.se',
                            home_page: '',
                            country: 'Sweden',
                            latitude: '59.3890446',
                            longitude: '16.4681606',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: '7b86567f-8309-4534-8f62-8ce1e60e6c50',
                        companyNumber: 'S537',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Hallstahammar',
                        branchCode: '0537',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0537',
                            name: 'Bilia Personbilar AB, Region Mälardalen',
                            structure_type: 'Filial',
                            district: '0537',
                            occupation: 'Sälj                Service',
                            center_district: '0563',
                            visit_address: 'Västeråsvägen 5',
                            visit_post_number: '73432',
                            visit_post_town: 'Hallstahammar',
                            mail_address: 'Västeråsvägen 5',
                            mail_post_number: '73432',
                            mail_post_town: 'Hallstahammar',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0220-518 70',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'ulla.samuelsson@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '59.60988',
                            longitude: '16.2436',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: 'fb3a06e3-1952-4e60-84db-8fd60d47f028',
                        companyNumber: 'S520',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Fagersta',
                        branchCode: '0520',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0520',
                            name: 'Bilia Personbilar AB, Region Mälardalen',
                            structure_type: 'Filial',
                            district: '0520',
                            occupation: 'Sälj                Service',
                            center_district: '0563',
                            visit_address: 'Virsbovägen 8',
                            visit_post_number: '73730',
                            visit_post_town: 'Fagersta',
                            mail_address: 'Virsbovägen 8',
                            mail_post_number: '73730',
                            mail_post_town: 'Fagersta',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0223-142 75',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'ulla.samuelsson@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '59.9821819',
                            longitude: '15.8088722',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: 'd153173e-b6f1-43af-a296-84d481ca9bd7',
                        companyNumber: 'S582',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Sala',
                        branchCode: '0582',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0582',
                            name: 'Bilia Personbilar AB, Region Mälardalen',
                            structure_type: 'Filial',
                            district: '0582',
                            occupation: 'Sälj                Service',
                            center_district: '0563',
                            visit_address: 'Oriongatan 1',
                            visit_post_number: '73340',
                            visit_post_town: 'Sala',
                            mail_address: 'Box 114',
                            mail_post_number: '73322',
                            mail_post_town: 'Sala',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0224-36755',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'ulla.samuelsson@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '59.9082548',
                            longitude: '16.6186722',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: 'c1f0d8fc-ada5-4934-a24f-aad7429685e7',
                        companyNumber: 'S407',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Strängnäs',
                        branchCode: '0407',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0407',
                            name: 'Bilia Personbilar AB, Region Mälardalen',
                            structure_type: 'Filial',
                            district: '0407',
                            occupation: 'Sälj                Service',
                            center_district: '0405',
                            visit_address: 'Eskilstunavägen 30',
                            visit_post_number: '64534',
                            visit_post_town: 'Strängnäs',
                            mail_address: 'Eskilstunavägen 30',
                            mail_post_number: '64534',
                            mail_post_town: 'Strängnäs',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0152-10330',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'henrik.andersson@bilia.se',
                            home_page: '',
                            country: 'Sweden',
                            latitude: '59.3769388',
                            longitude: '17.0136441',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: '919419ec-7af8-4c2c-991e-1865904e691a',
                        companyNumber: 'S245',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Skövde',
                        branchCode: '0245',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0245',
                            name: 'Bilia Personbilar AB, Region Skaraborg',
                            structure_type: 'Center',
                            district: '0245',
                            occupation: 'Sälj                Service',
                            center_district: '0245',
                            visit_address: 'Mellomkvarnsvägen 2',
                            visit_post_number: '54139',
                            visit_post_town: 'Skövde',
                            mail_address: 'Box 616',
                            mail_post_number: '54129',
                            mail_post_town: 'Skövde',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0500-480015',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'bert.andersson@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '58.418906',
                            longitude: '13.8705551',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        },
                        facilities: [
                            {
                            name: 'Bilia Skövde Showroom',
                            telephone: '',
                            sort_order: 0,
                            opening_hours: {
                                monday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                tuesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                wednesday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                thursday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                friday: {
                                from: '09:00',
                                to: '18:00'
                                },
                                saturday: {
                                from: '10:00',
                                to: '14:00'
                                },
                                sunday: {
                                from: '11:00',
                                to: '15:00'
                                }
                            },
                            exceptions: [],
                            department: 'showroom'
                            },
                            {
                            name: 'Bilia Skövde Verkstad',
                            telephone: '',
                            sort_order: 1,
                            opening_hours: {
                                monday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                tuesday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                wednesday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                thursday: {
                                from: '07:00',
                                to: '18:00'
                                },
                                friday: {
                                from: '07:00',
                                to: '17:00'
                                }
                            },
                            exceptions: [],
                            department: 'service'
                            }
                        ]
                        }
                    },
                    {
                        companyId: '04865c2f-c708-47e2-9459-58032abea9cc',
                        companyNumber: 'S247',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Falköping',
                        branchCode: '0247',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0247',
                            name: 'Bilia Personbilar AB, Region Skaraborg',
                            structure_type: 'Filial',
                            district: '0247',
                            occupation: 'Sälj                Service',
                            center_district: '0245',
                            visit_address: 'Lovenegatan 1',
                            visit_post_number: '52141',
                            visit_post_town: 'Falköping',
                            mail_address: 'Lovenegatan 1',
                            mail_post_number: '52141',
                            mail_post_town: 'Falköping',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0515-13772',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'magnus.lindman@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '58.1692409',
                            longitude: '13.5801909',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: 'e18523bb-12e9-4526-bc33-150ffa139953',
                        companyNumber: 'S251',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Mariestad',
                        branchCode: '0251',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0251',
                            name: 'Bilia Personbilar AB, Region Skaraborg',
                            structure_type: 'Filial',
                            district: '0251',
                            occupation: 'Sälj                Service',
                            center_district: '0245',
                            visit_address: 'Hammarsmedsgatan 27',
                            visit_post_number: '54235',
                            visit_post_town: 'Mariestad',
                            mail_address: 'Hammarsmedsgatan 27',
                            mail_post_number: '54235',
                            mail_post_town: 'Mariestad',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0501-68125',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'kenneth.bergstrom@bilia.se',
                            home_page: 'http://www.bilia.se/',
                            country: 'Sweden',
                            latitude: '58.6776785',
                            longitude: '13.8208257',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: '5688930c-8941-4493-baed-24a97600d45d',
                        companyNumber: 'S253',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Skara',
                        branchCode: '0253',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0253',
                            name: 'Bilia Personbilar AB, Region Skaraborg',
                            structure_type: 'Filial',
                            district: '0253',
                            occupation: 'Sälj                Service',
                            center_district: '0245',
                            visit_address: 'Hospitalgatan 13',
                            visit_post_number: '53237',
                            visit_post_town: 'Skara',
                            mail_address: 'Hospitalgatan 13',
                            mail_post_number: '53237',
                            mail_post_town: 'Skara',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0511-24705',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'ulf.johansson@bilia.se',
                            home_page: '',
                            country: 'Sweden',
                            latitude: '58.3782569',
                            longitude: '13.4187031',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: 'be02e067-7775-45a7-a9cf-c90017334a21',
                        companyNumber: 'S152',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Jägerso',
                        branchCode: '0152',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0152',
                            name: 'Bilia Personbilar AB, Region Syd / Jägersro',
                            structure_type: 'Center',
                            district: '0152',
                            occupation: 'Sälj                Service',
                            center_district: '0152',
                            visit_address: 'Agnesfridsvägen 119, Jägersro',
                            visit_post_number: '21375',
                            visit_post_town: 'Malmö',
                            mail_address: 'Box 50515',
                            mail_post_number: '20250',
                            mail_post_town: 'Malmö',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '040-311247',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'karin.lundstrom@bilia.se',
                            home_page: '',
                            country: 'Sweden',
                            latitude: '55.5714923',
                            longitude: '13.0550314',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: '9e00de90-837c-4b2c-97cf-0e81374f768e',
                        companyNumber: 'S144',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Limhamn',
                        branchCode: '0144',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '65dd31d5-dc9e-49df-b895-e454252888a0',
                        companyNumber: 'S145',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Värnhem',
                        branchCode: '0145',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '740f890c-5da7-46af-91ba-b84fb5967bb2',
                        companyNumber: 'S147',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Lund',
                        branchCode: '0147',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0147',
                            name: 'Bilia Personbilar AB, Region Syd',
                            structure_type: 'Center',
                            district: '0147',
                            occupation: 'Sälj                Service',
                            center_district: '0147',
                            visit_address: 'Fältspatvägen2',
                            visit_post_number: '22478',
                            visit_post_town: 'Lund',
                            mail_address: 'Fältspatvägen2',
                            mail_post_number: '22478',
                            mail_post_town: 'Lund',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '046-181826',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'karin.lundstrom@bilia.se',
                            home_page: '',
                            country: 'Sweden',
                            latitude: '55.6965291',
                            longitude: '13.2214008',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        }
                        }
                    },
                    {
                        companyId: 'e94c3c66-fe67-4dff-a54b-249fe5d6a79f',
                        companyNumber: 'S180',
                        parentCompanyId: '6141ab6a-85d1-4364-aff9-a624e78158c1',
                        name: 'Bilia Trelleborg',
                        branchCode: '0180',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0180',
                            name: 'Bilia Personbilar AB, Region Syd',
                            structure_type: 'Filial',
                            district: '0180',
                            occupation: 'Sälj                Service',
                            center_district: '0152',
                            visit_address: 'Hedvägen 111',
                            visit_post_number: '23166',
                            visit_post_town: 'Trelleborg',
                            mail_address: 'Hedvägen 111',
                            mail_post_number: '23166',
                            mail_post_town: 'Trelleborg',
                            region: 'Volvo Bilia',
                            address_2: '',
                            phone: '0771-400 000',
                            fax: '0410-54711',
                            company_email: 'webkontakt@bilia.se',
                            used_cars_email: 'leads@bilia.se',
                            new_cars_email: 'leads@bilia.se',
                            new_contact_email: 'karin.lundstrom@bilia.se',
                            home_page: '',
                            country: 'Sweden',
                            latitude: '55.3809304',
                            longitude: '13.1497958',
                            business_group: '0292',
                            price_localization: 'SE1076'
                        },
                        facilities: [
                            {
                            name: 'Matilda',
                            telephone: 'Bergram',
                            sort_order: 'matilda.bergram@bilia.se',
                            opening_hours: {
                                monday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                tuesday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                wednesday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                thursday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                friday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                saturday: {
                                from: '09:00',
                                to: '17:00'
                                },
                                sunday: {
                                from: '09:00',
                                to: '17:00'
                                }
                            },
                            exceptions: [],
                            department: 'showroom'
                            }
                        ]
                        }
                    },
                    {
                        companyId: '0b1a20ff-31e1-4424-b64d-b1e4b9a6b12b',
                        companyNumber: '003',
                        parentCompanyId: null,
                        name: 'Bil-Bengtsson Sjöbo',
                        branchCode: '0171',
                        branchCodeAliases: [],
                        localisationId: 'SE1010',
                        authId: null,
                        afPassword: 'Xe6zoA',
                        info: {
                        profile: {
                            id: '0171',
                            name: 'AB Bil-Bengtsson',
                            structure_type: 'Filial',
                            district: '0171',
                            occupation: 'Sälj                Service',
                            center_district: '0184',
                            visit_address: 'Planteringsgatan 52',
                            visit_post_number: '27533',
                            visit_post_town: 'Sjöbo',
                            mail_address: 'Planteringsgatan 52',
                            mail_post_number: '27533',
                            mail_post_town: 'Sjöbo',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0416-25400',
                            fax: '0416 -25429',
                            company_email: 'info@bilbengtsson.se',
                            used_cars_email: 'bilforsaljningen.sjobo@bilbengtsson.se',
                            new_cars_email: 'bilforsaljningen.sjobo@bilbengtsson.se',
                            new_contact_email: 'olov.bengtsson@bilbengtsson.se',
                            home_page: 'http://www.bilbengtsson.se/',
                            country: 'Sweden',
                            latitude: '55.6352164',
                            longitude: '13.6836984',
                            business_group: '0171',
                            price_localization: 'SE1010'
                        }
                        }
                    },
                    {
                        companyId: 'b3881a46-f1d6-4af0-8f05-aecef3492f33',
                        companyNumber: 'S184',
                        parentCompanyId: '0b1a20ff-31e1-4424-b64d-b1e4b9a6b12b',
                        name: 'Bil-Bengtsson Ystad',
                        branchCode: '0184',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0184',
                            name: 'AB Bil-Bengtsson',
                            structure_type: 'Center',
                            district: '0184',
                            occupation: 'Sälj                Service',
                            center_district: '0184',
                            visit_address: 'Metallgatan 1',
                            visit_post_number: '27139',
                            visit_post_town: 'Ystad',
                            mail_address: 'Box 1530',
                            mail_post_number: '27100',
                            mail_post_town: 'Ystad',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0411-64800',
                            fax: '0411-64839',
                            company_email: 'info@bilbengtsson.se',
                            used_cars_email: 'bilforsaljningen.ystad@bilbengtsson.se',
                            new_cars_email: 'bilforsaljningen.ystad@bilbengtsson.se',
                            new_contact_email: 'bilforsaljningen.ystad@bilbengtsson.se',
                            home_page: 'http://www.bilbengtsson.se/',
                            country: 'Sweden',
                            latitude: '55.4449821',
                            longitude: '13.8396743',
                            business_group: '0171',
                            price_localization: 'SE1010'
                        }
                        }
                    },
                    {
                        companyId: '203ca451-87d2-490d-9925-0e330ac170ec',
                        companyNumber: 'S185',
                        parentCompanyId: '0b1a20ff-31e1-4424-b64d-b1e4b9a6b12b',
                        name: 'Bil-Bengtsson Tomelilla',
                        branchCode: '0185',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: 'b604046e-766f-47b4-b599-1149d250323b',
                        companyNumber: 'S167',
                        parentCompanyId: '0b1a20ff-31e1-4424-b64d-b1e4b9a6b12b',
                        name: 'Bil-Bengtsson Simrishamn',
                        branchCode: '0167',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0167',
                            name: 'AB Bil-Bengtsson',
                            structure_type: 'Filial',
                            district: '0167',
                            occupation: 'Sälj                Service',
                            center_district: '0184',
                            visit_address: 'Fredsdalsgatan 1',
                            visit_post_number: '27234',
                            visit_post_town: 'Simrishamn',
                            mail_address: 'Fredsdalsgatan 1',
                            mail_post_number: '27234',
                            mail_post_town: 'Simrishamn',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0414-28700',
                            fax: '0414-28715',
                            company_email: 'info@bilbengtsson.se',
                            used_cars_email: 'bilforsaljningen.simrishamn@bilbengtsson.se',
                            new_cars_email: 'bilforsaljningen.simrishamn@bilbengtsson.se',
                            new_contact_email: 'olov.bengtsson@bilbengtsson.se',
                            home_page: 'http://www.bilbengtsson.se/',
                            country: 'Sweden',
                            latitude: '55.5560036',
                            longitude: '14.3398809',
                            business_group: '0171',
                            price_localization: 'SE1010'
                        }
                        }
                    },
                    {
                        companyId: '1c9a50f7-c6ef-49b7-9666-89906b44abc9',
                        companyNumber: '004',
                        parentCompanyId: null,
                        name: 'Bil-Månsson i Skåne Eslöv',
                        branchCode: '0109',
                        branchCodeAliases: [],
                        localisationId: 'SE8297',
                        authId: null,
                        afPassword: 'tadeki',
                        info: {
                        profile: {
                            id: '0109',
                            name: 'Bil-Månsson i Skåne AB',
                            structure_type: 'Center',
                            district: '0109',
                            occupation: 'Sälj                Service',
                            center_district: '0109',
                            visit_address: 'Vikhemsvägen 2',
                            visit_post_number: '24138',
                            visit_post_town: 'Eslöv',
                            mail_address: 'Box 181',
                            mail_post_number: '24123',
                            mail_post_town: 'Eslöv',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0413-688 60',
                            fax: '0413-68861',
                            company_email: 'info@bilmansson.se',
                            used_cars_email: 'andreas.nilsson@bilmansson.se',
                            new_cars_email: 'andreas.nilsson@bilmansson.se',
                            new_contact_email: '',
                            home_page: 'http://www.bilmansson.se/',
                            country: 'Sweden',
                            latitude: '55.8403651',
                            longitude: '13.3248858',
                            business_group: '0109',
                            price_localization: 'SE8297'
                        }
                        }
                    },
                    {
                        companyId: '6b3723c8-5acf-4482-9646-355cdc7a3c09',
                        companyNumber: 'S110',
                        parentCompanyId: '1c9a50f7-c6ef-49b7-9666-89906b44abc9',
                        name: 'Bil-Månsson Hörby',
                        branchCode: '0110',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0110',
                            name: 'Bil-Månsson i Skåne AB',
                            structure_type: 'Filial',
                            district: '0110',
                            occupation: 'Sälj                Service',
                            center_district: '0109',
                            visit_address: 'Storgatan 16',
                            visit_post_number: '24231',
                            visit_post_town: 'Hörby',
                            mail_address: 'Box 64',
                            mail_post_number: '24221',
                            mail_post_town: 'Hörby',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0415-188 00',
                            fax: '0415-18801',
                            company_email: 'info@bilmansson.se',
                            used_cars_email: 'andreas.nilsson@bilmansson.se',
                            new_cars_email: 'andreas.nilsson@bilmansson.se',
                            new_contact_email: '',
                            home_page: 'http://www.bilmansson.se/',
                            country: 'Sweden',
                            latitude: '55.8522259',
                            longitude: '13.6628378',
                            business_group: '0109',
                            price_localization: 'SE8297'
                        }
                        }
                    },
                    {
                        companyId: '189f5ce5-1f18-45ef-b2cc-e9507260f165',
                        companyNumber: 'S194',
                        parentCompanyId: '1c9a50f7-c6ef-49b7-9666-89906b44abc9',
                        name: 'Bil-Månsson Klippan',
                        branchCode: '0194',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {
                        profile: {
                            id: '0194',
                            name: 'Bil-Månsson i Skåne AB',
                            structure_type: 'Filial',
                            district: '0194',
                            occupation: 'Sälj                Service',
                            center_district: '0192',
                            visit_address: 'Fabriksallén 47',
                            visit_post_number: '264 36',
                            visit_post_town: 'Klippan',
                            mail_address: 'Fabriksallén 47',
                            mail_post_number: '26436',
                            mail_post_town: 'Klippan',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0435-448850',
                            fax: '0435-448855',
                            company_email: 'info@bilmansson.se',
                            used_cars_email: 'Bilforsäljning.klippan@bilmansson.se',
                            new_cars_email: 'Bilforsäljning.klippan@bilmansson.se',
                            new_contact_email: 'Bilforsäljning.klippan@bilmansson.se',
                            home_page: 'http://www.bilmansson.se/',
                            country: 'Sweden',
                            latitude: '56.128972',
                            longitude: '13.1573821',
                            business_group: '0109',
                            price_localization: 'SE8297'
                        }
                        }
                    },
                    {
                        companyId: '2c0d9f83-acc3-4001-85a9-263a18ea62c1',
                        companyNumber: 'S195',
                        parentCompanyId: '1c9a50f7-c6ef-49b7-9666-89906b44abc9',
                        name: 'Bil-Månsson Båstad',
                        branchCode: '0195',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: 'e2c0a5f2-58fb-45ab-bd58-d4887e3c1aa4',
                        companyNumber: 'S192',
                        parentCompanyId: '1c9a50f7-c6ef-49b7-9666-89906b44abc9',
                        name: 'Bil-Månsson Ängelholm',
                        branchCode: '0192',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {
                        profile: {
                            id: '0192',
                            name: 'Bil-Månsson i Skåne AB',
                            structure_type: 'Center',
                            district: '0192',
                            occupation: 'Sälj                Service',
                            center_district: '0192',
                            visit_address: 'Transportgatan 11',
                            visit_post_number: '26271',
                            visit_post_town: 'Ängelholm',
                            mail_address: 'Box 1155',
                            mail_post_number: '26222',
                            mail_post_town: 'Ängelholm',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0431-89800',
                            fax: '0431-16658',
                            company_email: 'info@bilmansson.se',
                            used_cars_email: 'bilforsaljning.angelholm@bilmansson.se',
                            new_cars_email: 'bilforsaljning.angelholm@bilmansson.se',
                            new_contact_email: 'lise-lotte.berg@skanebil.se',
                            home_page: 'http://www.bilmansson.se/',
                            country: 'Sweden',
                            latitude: '56.2463229',
                            longitude: '12.8963031',
                            business_group: '0109',
                            price_localization: 'SE8297'
                        }
                        }
                    },
                    {
                        companyId: 'b2a51e22-4d92-464f-a9be-3337a89179ce',
                        companyNumber: '005',
                        parentCompanyId: null,
                        name: 'Bilbolaget Personbilar Sundsvall',
                        branchCode: '0625',
                        branchCodeAliases: [],
                        localisationId: 'SE1172',
                        authId: null,
                        afPassword: 'dumedo',
                        info: {
                        profile: {
                            id: '0625',
                            name: 'Bilbolaget Personbilar Sundsvall AB',
                            structure_type: 'Center',
                            district: '0625',
                            occupation: 'Sälj                Service',
                            center_district: '0625',
                            visit_address: 'Bultgatan 1',
                            visit_post_number: '85350',
                            visit_post_town: 'Sundsvall',
                            mail_address: 'Box 870',
                            mail_post_number: '85124',
                            mail_post_town: 'Sundsvall',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '060-180800',
                            fax: '060-180823',
                            company_email: 'info@bilbolaget.nu',
                            used_cars_email: 'lars.svalstedt@bilbolaget.nu',
                            new_cars_email: 'lars.svalstedt@bilbolaget.nu',
                            new_contact_email: 'sandra.stattinpersson@bilbolaget.nu',
                            home_page: 'http://www.bilbolaget.nu/',
                            country: 'Sweden',
                            latitude: '62.3898447',
                            longitude: '17.2769754',
                            business_group: '0625',
                            price_localization: 'SE1172'
                        }
                        }
                    },
                    {
                        companyId: '7dc9a164-2010-428c-8a05-e77469c0914d',
                        companyNumber: 'S610',
                        parentCompanyId: 'b2a51e22-4d92-464f-a9be-3337a89179ce',
                        name: 'Bilbolaget Personbilar Bollnäs',
                        branchCode: '0610',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0610',
                            name: 'Bilbolaget Personbilar Hälsingland AB',
                            structure_type: 'Filial',
                            district: '0610',
                            occupation: 'Sälj                Service',
                            center_district: '0609',
                            visit_address: 'Hantverkargatan 3',
                            visit_post_number: '82130',
                            visit_post_town: 'Bollnäs',
                            mail_address: 'Box 501',
                            mail_post_number: '82110',
                            mail_post_town: 'Bollnäs',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0278-639500',
                            fax: '0278-639510',
                            company_email: 'info@bilbolaget.nu',
                            used_cars_email: 'fredrik.veenhuis@bilbolaget.nu',
                            new_cars_email: 'fredrik.veenhuis@bilbolaget.nu',
                            new_contact_email: 'karl-erik.hammarstrand@bilbolaget.nu',
                            home_page: 'http://www.bilbolaget.nu/',
                            country: 'Sweden',
                            latitude: '61.3437022',
                            longitude: '16.380519',
                            business_group: '0625',
                            price_localization: 'SE1172'
                        }
                        }
                    },
                    {
                        companyId: 'd8f3ae1b-e66b-4764-8ec9-ab524e61d966',
                        companyNumber: 'S618',
                        parentCompanyId: 'b2a51e22-4d92-464f-a9be-3337a89179ce',
                        name: 'Bilbolaget Personbilar Sveg',
                        branchCode: '0618',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '1e231fde-4127-4a62-922f-c287c42cd927',
                        companyNumber: 'S614',
                        parentCompanyId: 'b2a51e22-4d92-464f-a9be-3337a89179ce',
                        name: 'Bilbolaget Personbilar Timrå',
                        branchCode: '0614',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '7273b759-2120-4c62-be9b-744b03fa82ff',
                        companyNumber: 'S513',
                        parentCompanyId: 'b2a51e22-4d92-464f-a9be-3337a89179ce',
                        name: 'Bilbolaget Personbilar Gävle',
                        branchCode: '0513',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0513',
                            name: 'Bilbolaget Personbilar Gävle AB',
                            structure_type: 'Center',
                            district: '0513',
                            occupation: 'Sälj                Service',
                            center_district: '0513',
                            visit_address: 'Södra Kungsgatan 61-63',
                            visit_post_number: '80255',
                            visit_post_town: 'Gävle',
                            mail_address: 'Box 762',
                            mail_post_number: '80129',
                            mail_post_town: 'Gävle',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '026-655700',
                            fax: '026-602126',
                            company_email: 'info@bilbolaget.nu',
                            used_cars_email: 'bfgavle@bilbolaget.nu',
                            new_cars_email: 'bfgavle@bilbolaget.nu',
                            new_contact_email: 'fredrika.lonnberg@gostasamuelssonbil.se',
                            home_page: 'http://www.bilbolaget.nu/',
                            country: 'Sweden',
                            latitude: '60.6624324',
                            longitude: '17.1578995',
                            business_group: '0625',
                            price_localization: 'SE1172'
                        }
                        }
                    },
                    {
                        companyId: '8d8b9a76-26dc-4729-9782-8cfa5cf6a783',
                        companyNumber: 'S515',
                        parentCompanyId: 'b2a51e22-4d92-464f-a9be-3337a89179ce',
                        name: 'Bilbolaget Personbilar Sandviken',
                        branchCode: '0515',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0515',
                            name: 'Bilbolaget Personbilar Gävle AB',
                            structure_type: 'Filial',
                            district: '0515',
                            occupation: 'Sälj                Service',
                            center_district: '0513',
                            visit_address: 'Gävlevägen 88',
                            visit_post_number: '81140',
                            visit_post_town: 'Sandviken',
                            mail_address: 'Box 125',
                            mail_post_number: '81122',
                            mail_post_town: 'Sandviken',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '026-277800',
                            fax: '026-277810',
                            company_email: 'info@bilbolaget.nu',
                            used_cars_email: 'mats.eriksson@bilbolaget.nu',
                            new_cars_email: 'mats.eriksson@bilbolaget.nu',
                            new_contact_email: 'mats.eriksson@gostasamuelssonbil.se',
                            home_page: 'http://www.bilbolaget.nu/',
                            country: 'Sweden',
                            latitude: '60.623553',
                            longitude: '16.7668884',
                            business_group: '0625',
                            price_localization: 'SE1172'
                        }
                        }
                    },
                    {
                        companyId: 'aa812202-3e71-4cb8-9611-f968f25046ff',
                        companyNumber: 'S609',
                        parentCompanyId: 'b2a51e22-4d92-464f-a9be-3337a89179ce',
                        name: 'Bilbolaget Personbilar Hudiksvall',
                        branchCode: '0609',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0609',
                            name: 'Bilbolaget Personbilar Hälsingland AB',
                            structure_type: 'Center',
                            district: '0609',
                            occupation: 'Sälj                Service',
                            center_district: '0609',
                            visit_address: 'Kullbäcksvägen',
                            visit_post_number: '82430',
                            visit_post_town: 'Hudiksvall',
                            mail_address: 'Box 1063',
                            mail_post_number: '82412',
                            mail_post_town: 'Hudiksvall',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0650-548300',
                            fax: '0650-548324',
                            company_email: 'info@bilbolaget.nu',
                            used_cars_email: 'fredrik.veenhuis@bilbolaget.nu',
                            new_cars_email: 'fredrik.veenhuis@bilbolaget.nu',
                            new_contact_email: 'fredrik.veenhuis@bilbolaget.nu',
                            home_page: 'http://www.bilbolaget.nu/',
                            country: 'Sweden',
                            latitude: '61.7344213',
                            longitude: '17.0974125',
                            business_group: '0625',
                            price_localization: 'SE1172'
                        }
                        }
                    },
                    {
                        companyId: '6f082156-c8ed-4572-b3c1-254ff213045b',
                        companyNumber: 'S617',
                        parentCompanyId: 'b2a51e22-4d92-464f-a9be-3337a89179ce',
                        name: 'Bilbolaget Personbilar Ljusdal',
                        branchCode: '0617',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0617',
                            name: 'Bilbolaget Personbilar Hälsingland AB',
                            structure_type: 'Filial',
                            district: '0617',
                            occupation: 'Sälj                Service',
                            center_district: '0609',
                            visit_address: 'Kyrkogatan 9',
                            visit_post_number: '82730',
                            visit_post_town: 'Ljusdal',
                            mail_address: 'Kyrkogatan 9',
                            mail_post_number: '82723',
                            mail_post_town: 'Ljusdal',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0651-766400',
                            fax: '0651-766402',
                            company_email: 'info@bilbolaget.nu',
                            used_cars_email: 'fredrik.hansson@bilbolaget.nu',
                            new_cars_email: 'fredrik.veenhuis@bilbolaget.nu',
                            new_contact_email: 'karl-erik.hammarstrand@bilbolaget.nu',
                            home_page: 'http://www.bilbolaget.nu/',
                            country: 'Sweden',
                            latitude: '61.828105',
                            longitude: '16.0819837',
                            business_group: '0625',
                            price_localization: 'SE1172'
                        }
                        }
                    },
                    {
                        companyId: '781b1ab6-1d44-4738-ab21-855fbaae83a6',
                        companyNumber: 'S611',
                        parentCompanyId: 'b2a51e22-4d92-464f-a9be-3337a89179ce',
                        name: 'Bilbolaget Personbilar Söderhamn',
                        branchCode: '0611',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0611',
                            name: 'Bilbolaget Personbilar Hälsingland AB',
                            structure_type: 'Filial',
                            district: '0611',
                            occupation: 'Sälj                Service',
                            center_district: '0609',
                            visit_address: 'Flygvägen 4',
                            visit_post_number: '82639',
                            visit_post_town: 'Söderhamn',
                            mail_address: 'Flygvägen 4',
                            mail_post_number: '82627',
                            mail_post_town: 'Söderhamn',
                            region: 'Volvo Norr',
                            address_2: '',
                            phone: '0270-73700',
                            fax: '0270-73718',
                            company_email: 'info@bilbolaget.nu',
                            used_cars_email: 'fredrik.veenhuis@bilbolaget.nu',
                            new_cars_email: 'fredrik.veenhuis@bilbolaget.nu',
                            new_contact_email: 'karl-erik.hammarstrand@bilbolaget.nu',
                            home_page: 'http://www.bilbolaget.nu/',
                            country: 'Sweden',
                            latitude: '61.2943189',
                            longitude: '17.0639847',
                            business_group: '0625',
                            price_localization: 'SE1172'
                        }
                        }
                    },
                    {
                        companyId: '03f341d8-f7e7-4fdb-8b8d-f8a129947be7',
                        companyNumber: 'S436',
                        parentCompanyId: 'b2a51e22-4d92-464f-a9be-3337a89179ce',
                        name: 'Bilbolaget Personbilar Norrtälje',
                        branchCode: '0436',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {
                        profile: {
                            id: '0436',
                            name: 'Bilbolaget Personbilar Roslagen AB',
                            structure_type: 'Center',
                            district: '0436',
                            occupation: 'Sälj                Service',
                            center_district: '0436',
                            visit_address: 'Estunavägen 13',
                            visit_post_number: '76151',
                            visit_post_town: 'Norrtälje',
                            mail_address: 'Estunavägen 13',
                            mail_post_number: '76151',
                            mail_post_town: 'Norrtälje',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0176-756 00',
                            fax: '0176-127 59',
                            company_email: 'info.ro@bilbolaget.nu',
                            used_cars_email: 'info.ro@bilbolaget.nu',
                            new_cars_email: 'info.ro@bilbolaget.nu',
                            new_contact_email: 'sj@roslagsbil.se',
                            home_page: 'http://www.bilbolaget.nu/',
                            country: 'Sweden',
                            latitude: '59.7605635',
                            longitude: '18.6896382',
                            business_group: '0625',
                            price_localization: 'SE1172'
                        }
                        }
                    },
                    {
                        companyId: '0081bb48-acab-49c6-ad4c-c3ef4e0cbc0e',
                        companyNumber: '007',
                        parentCompanyId: null,
                        name: 'Bröderna Brandt Uddevalla',
                        branchCode: '0257',
                        branchCodeAliases: [],
                        localisationId: 'SE6589',
                        authId: null,
                        afPassword: 'hufige',
                        info: {
                        profile: {
                            id: '0257',
                            name: 'Bröderna Brandt Personbilar AB',
                            structure_type: 'Center',
                            district: '0257',
                            occupation: 'Sälj                Service',
                            center_district: '0257',
                            visit_address: 'Frölands ind.område',
                            visit_post_number: '45176',
                            visit_post_town: 'Uddevalla',
                            mail_address: 'Box 734',
                            mail_post_number: '45125',
                            mail_post_town: 'Uddevalla',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0522-81400',
                            fax: '0522-81422',
                            company_email: 'info@brandtbil.se',
                            used_cars_email: 'forfragning.uddevalla@brandtbil.se',
                            new_cars_email: 'forfragning.uddevalla@brandtbil.se',
                            new_contact_email: 'bernt.l@brandtbil.se',
                            home_page: 'http://www.brandtbil.se/',
                            country: 'Sweden',
                            latitude: '58.3447098',
                            longitude: '11.8655936',
                            business_group: '0257',
                            price_localization: 'SE6589'
                        }
                        }
                    },
                    {
                        companyId: '5341078c-d668-41ab-a1fe-f7cf3d59eaa6',
                        companyNumber: 'S255',
                        parentCompanyId: '0081bb48-acab-49c6-ad4c-c3ef4e0cbc0e',
                        name: 'Bröderna Brandt Dingle',
                        branchCode: '0255',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0255',
                            name: 'Bröderna Brandt Personbilar AB',
                            structure_type: 'Center',
                            district: '0255',
                            occupation: 'Sälj                Service',
                            center_district: '0255',
                            visit_address: 'Kustvägen 1',
                            visit_post_number: '455 61',
                            visit_post_town: 'Dingle',
                            mail_address: 'Kustvägen 1',
                            mail_post_number: '45561',
                            mail_post_town: 'Dingle',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0524-81400',
                            fax: '0524-81401',
                            company_email: 'lilian.s@brandtbil.se',
                            used_cars_email: 'forfragning.dingle@brandtbil.se',
                            new_cars_email: 'forfragning.dingle@brandtbil.se',
                            new_contact_email: 'lilian.s@brandtbil.se',
                            home_page: 'http://www.brandtbil.se/',
                            country: 'Sweden',
                            latitude: '58.5265983',
                            longitude: '11.5746376',
                            business_group: '0257',
                            price_localization: 'SE6589'
                        }
                        }
                    },
                    {
                        companyId: '42e89f35-4132-40eb-8dd5-017a3dad2c88',
                        companyNumber: 'S258',
                        parentCompanyId: '0081bb48-acab-49c6-ad4c-c3ef4e0cbc0e',
                        name: 'Bröderna Brandt Lysekil',
                        branchCode: '0258',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0258',
                            name: 'Bröderna Brandt Personbilar AB',
                            structure_type: 'Filial',
                            district: '0258',
                            occupation: 'Sälj                Service',
                            center_district: '0255',
                            visit_address: 'Förrådsvägen 1',
                            visit_post_number: '45338',
                            visit_post_town: 'Lysekil',
                            mail_address: 'Förrådsvägen 1',
                            mail_post_number: '45338',
                            mail_post_town: 'Lysekil',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0523-10001',
                            fax: '0523-10360',
                            company_email: 'lilian.s@brandtbil.se',
                            used_cars_email: 'forfragning.lysekil@brandtbil.se',
                            new_cars_email: 'forfragning.lysekil@brandtbil.se',
                            new_contact_email: 'lilian.o@brandtbil.se',
                            home_page: 'http://www.brandtbil.se/',
                            country: 'Sweden',
                            latitude: '58.2859028',
                            longitude: '11.4621036',
                            business_group: '0257',
                            price_localization: 'SE6589'
                        }
                        }
                    },
                    {
                        companyId: '643207c6-e398-43b4-8b63-8fcc785d573a',
                        companyNumber: 'S280',
                        parentCompanyId: '0081bb48-acab-49c6-ad4c-c3ef4e0cbc0e',
                        name: 'Bröderna Brandt Mellerud',
                        branchCode: '0280',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0280',
                            name: 'Bröderna Brandt Personbilar AB',
                            structure_type: 'Filial',
                            district: '0280',
                            occupation: 'Sälj                Service',
                            center_district: '0277',
                            visit_address: 'Landsvägsgatan 36',
                            visit_post_number: '46431',
                            visit_post_town: 'Mellerud',
                            mail_address: 'Landsvägsgatan 36',
                            mail_post_number: '46431',
                            mail_post_town: 'Mellerud',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0530-44770',
                            fax: '0530-41450',
                            company_email: '',
                            used_cars_email: 'forfragning.mellerud@brandtbil.se',
                            new_cars_email: 'forfragning.mellerud@brandtbil.se',
                            new_contact_email: 'gunnar.l@brandtbil.se',
                            home_page: 'http://www.brandtbil.se/',
                            country: 'Sweden',
                            latitude: '58.69761',
                            longitude: '12.446004',
                            business_group: '0257',
                            price_localization: 'SE6589'
                        }
                        }
                    },
                    {
                        companyId: '72aea696-3c45-4a9e-9a4e-9aec06589023',
                        companyNumber: 'S269',
                        parentCompanyId: '0081bb48-acab-49c6-ad4c-c3ef4e0cbc0e',
                        name: 'Bröderna Brandt Vänersborg',
                        branchCode: '0269',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0269',
                            name: 'Bröderna Brandt Personbilar AB',
                            structure_type: 'Center',
                            district: '0269',
                            occupation: 'Sälj                Service',
                            center_district: '0269',
                            visit_address: 'Johannesbergsvägen 1',
                            visit_post_number: '46240',
                            visit_post_town: 'Vänersborg',
                            mail_address: 'Johannesbergsvägen 1',
                            mail_post_number: '46240',
                            mail_post_town: 'Vänersborg',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0521-281500',
                            fax: '0521-281515',
                            company_email: '',
                            used_cars_email: 'forfragning.vanersborg@brandtbil.se',
                            new_cars_email: 'forfragning.vanersborg@brandtbil.se',
                            new_contact_email: 'jesper.e@brandtbil.se',
                            home_page: 'http://www.brandtbil.se/',
                            country: 'Sweden',
                            latitude: '58.3552697',
                            longitude: '12.3164723',
                            business_group: '0257',
                            price_localization: 'SE6589'
                        }
                        }
                    },
                    {
                        companyId: 'ff8cb6c5-217e-4f1e-b49b-e339dd766e6f',
                        companyNumber: 'S254',
                        parentCompanyId: '0081bb48-acab-49c6-ad4c-c3ef4e0cbc0e',
                        name: 'Bröderna Brandt Strömstad',
                        branchCode: '0254',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0254',
                            name: 'Bröderna Brandt Personbilar AB',
                            structure_type: 'Filial',
                            district: '0254',
                            occupation: 'Sälj                Service',
                            center_district: '0255',
                            visit_address: 'Tångenvägen 1',
                            visit_post_number: '45235',
                            visit_post_town: 'Strömstad',
                            mail_address: 'Tångenvägen 1',
                            mail_post_number: '45235',
                            mail_post_town: 'Strömstad',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0524-814 30',
                            fax: '0526-15157',
                            company_email: '',
                            used_cars_email: 'forfragning.stromstad@brandtbil.se',
                            new_cars_email: 'forfragning.stromstad@brandtbil.se',
                            new_contact_email: 'rolf.l@brandtbil.se',
                            home_page: 'http://www.brandtbil.se/',
                            country: 'Sweden',
                            latitude: '58.9479878',
                            longitude: '11.1953645',
                            business_group: '0257',
                            price_localization: 'SE6589'
                        }
                        }
                    },
                    {
                        companyId: '44d9eda0-7ee8-411a-96e3-aea70dd85a6c',
                        companyNumber: 'S270',
                        parentCompanyId: '0081bb48-acab-49c6-ad4c-c3ef4e0cbc0e',
                        name: 'Bröderna Brandt Trollhättan Tunhemsvägen',
                        branchCode: '0270',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '6357ec68-7c66-4d2e-8a58-a07368323770',
                        companyNumber: 'S279',
                        parentCompanyId: '0081bb48-acab-49c6-ad4c-c3ef4e0cbc0e',
                        name: 'Bröderna Brandt Ed',
                        branchCode: '0279',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0279',
                            name: 'Bröderna Brandt Personbilar AB',
                            structure_type: 'Filial',
                            district: '0279',
                            occupation: 'Sälj',
                            center_district: '0277',
                            visit_address: 'Verkstadsvägen 3',
                            visit_post_number: '66832',
                            visit_post_town: 'Ed',
                            mail_address: 'Verkstadsvägen 3',
                            mail_post_number: '66832',
                            mail_post_town: 'Ed',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0534-61780',
                            fax: '0534-61830',
                            company_email: 'anders.s@brandtbil.se',
                            used_cars_email: 'forfragning.ed@brandtbil.se',
                            new_cars_email: 'forfragning.ed@brandtbil.se',
                            new_contact_email: 'Uppgift saknas! / VPS',
                            home_page: 'http://www.brandtbil.se/',
                            country: 'Sweden',
                            latitude: '58.8999026',
                            longitude: '11.9538307',
                            business_group: '0257',
                            price_localization: 'SE6589'
                        }
                        }
                    },
                    {
                        companyId: '89d4a8b0-4469-4ae4-a37f-c81ecc9003c2',
                        companyNumber: 'S277',
                        parentCompanyId: '0081bb48-acab-49c6-ad4c-c3ef4e0cbc0e',
                        name: 'Bröderna Brandt Åmål',
                        branchCode: '0277',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0277',
                            name: 'Bröderna Brandt Personbilar AB',
                            structure_type: 'Center',
                            district: '0277',
                            occupation: 'Sälj                Service',
                            center_district: '0277',
                            visit_address: 'Vänersborgsvägen 17',
                            visit_post_number: '66232',
                            visit_post_town: 'Åmål',
                            mail_address: 'Box 84',
                            mail_post_number: '66222',
                            mail_post_town: 'Åmål',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0532-62600',
                            fax: '0532-15916',
                            company_email: '',
                            used_cars_email: 'forfragning.amal@brandtbil.se',
                            new_cars_email: 'forfragning.amal@brandtbil.se',
                            new_contact_email: 'mats.j@brandtbil.se',
                            home_page: 'http://www.brandtbil.se/',
                            country: 'Sweden',
                            latitude: '59.0427906',
                            longitude: '12.7132506',
                            business_group: '0257',
                            price_localization: 'SE6589'
                        }
                        }
                    },
                    {
                        companyId: 'd154d33d-c252-4698-9787-eda0780467bb',
                        companyNumber: 'S559',
                        parentCompanyId: '0081bb48-acab-49c6-ad4c-c3ef4e0cbc0e',
                        name: 'Bröderna Brandt Säffle',
                        branchCode: '0559',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: 'cb0ff0ba-6859-42b6-9fbf-bcf76e68898a',
                        companyNumber: 'S271',
                        parentCompanyId: '0081bb48-acab-49c6-ad4c-c3ef4e0cbc0e',
                        name: 'Bröderna Brandt Trollhättan Flygmotorvägen/Stallbacka',
                        branchCode: '0271',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: 'ca1170a1-044a-4d60-9291-e6ca312c8bf0',
                        companyNumber: 'S278',
                        parentCompanyId: '0081bb48-acab-49c6-ad4c-c3ef4e0cbc0e',
                        name: 'Bröderna Brandt Bengtsfors',
                        branchCode: '0278',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '807c01bd-e907-4814-92f2-9a2970caeae1',
                        companyNumber: '006',
                        parentCompanyId: null,
                        name: 'Bilkompaniet Mora',
                        branchCode: '0549',
                        branchCodeAliases: [],
                        localisationId: 'SE9712',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0549',
                            name: 'Bilkompaniet Mora Leksand Malung AB',
                            structure_type: 'Center',
                            district: '0549',
                            occupation: 'Sälj                Service',
                            center_district: '0549',
                            visit_address: 'Engelbrektsväg 3',
                            visit_post_number: '79232',
                            visit_post_town: 'Mora',
                            mail_address: 'Box 430',
                            mail_post_number: '79227',
                            mail_post_town: 'Mora',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0250-28800',
                            fax: '0250-28880',
                            company_email: 'info@bilkompaniet.se',
                            used_cars_email: 'begbil@bilkompaniet.se',
                            new_cars_email: 'nybil@bilkompaniet.se',
                            new_contact_email: 'nybil@bilkompaniet.se',
                            home_page: 'http://www.bilkompaniet.se/',
                            country: 'Sweden',
                            latitude: '61.0099379',
                            longitude: '14.5636737',
                            business_group: '0549',
                            price_localization: 'SE9712'
                        }
                        }
                    },
                    {
                        companyId: '37e104ac-9252-484a-bf02-ff253a861769',
                        companyNumber: 'S550',
                        parentCompanyId: '807c01bd-e907-4814-92f2-9a2970caeae1',
                        name: 'Bilkompaniet Malung',
                        branchCode: '0550',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0550',
                            name: 'Bilkompaniet Mora Leksand Malung AB',
                            structure_type: 'Filial',
                            district: '0550',
                            occupation: 'Sälj                Service',
                            center_district: '0549',
                            visit_address: 'Källvägen 6',
                            visit_post_number: '78233',
                            visit_post_town: 'Malung',
                            mail_address: 'Källvägen 6',
                            mail_post_number: '78223',
                            mail_post_town: 'Malung',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0280-44500',
                            fax: '0280-10030',
                            company_email: 'info@bilkompaniet.se',
                            used_cars_email: 'begbil@bilkompaniet.se',
                            new_cars_email: 'nybil@bilkompaniet.se',
                            new_contact_email: 'nybil@bilkompaniet.se',
                            home_page: 'http://www.bilkompaniet.se/',
                            country: 'Sweden',
                            latitude: '60.6875448',
                            longitude: '13.7196463',
                            business_group: '0549',
                            price_localization: 'SE9712'
                        }
                        }
                    },
                    {
                        companyId: '2f9275ce-4c8f-4895-aa89-013b5925f8c6',
                        companyNumber: 'S548',
                        parentCompanyId: '807c01bd-e907-4814-92f2-9a2970caeae1',
                        name: 'Bilkompaniet Leksand',
                        branchCode: '0548',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0548',
                            name: 'Bilkompaniet Mora Leksand Malung AB',
                            structure_type: 'Filial',
                            district: '0548',
                            occupation: 'Sälj                Service',
                            center_district: '0549',
                            visit_address: 'Limsjöänget 6',
                            visit_post_number: '79331',
                            visit_post_town: 'Leksand',
                            mail_address: 'Limsjöänget 6',
                            mail_post_number: '79331',
                            mail_post_town: 'Leksand',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0247-44900',
                            fax: '0247-44905',
                            company_email: 'info@bilkompaniet.se',
                            used_cars_email: 'begbil@bilkompaniet.se',
                            new_cars_email: 'nybil@bilkompaniet.se',
                            new_contact_email: 'nybil@bilkompaniet.se',
                            home_page: 'http://www.bilkompaniet.se/',
                            country: 'Sweden',
                            latitude: '60.7374715',
                            longitude: '15.0048585',
                            business_group: '0549',
                            price_localization: 'SE9712'
                        }
                        }
                    },
                    {
                        companyId: '2970a39b-68b1-4096-92bb-11b17f01e42b',
                        companyNumber: '009',
                        parentCompanyId: null,
                        name: 'Göinge Bil Hässleholm',
                        branchCode: '0117',
                        branchCodeAliases: [],
                        localisationId: 'SE1011',
                        authId: null,
                        afPassword: '2zIm5g',
                        info: {
                        profile: {
                            id: '0117',
                            name: 'Göinge Bil AB',
                            structure_type: 'Center',
                            district: '0117',
                            occupation: 'Sälj                Service',
                            center_district: '0117',
                            visit_address: 'N. Kringelvägen 70',
                            visit_post_number: '28140',
                            visit_post_town: 'Hässleholm',
                            mail_address: 'Box 24',
                            mail_post_number: '28121',
                            mail_post_town: 'Hässleholm',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0451-43600',
                            fax: '0451-12450',
                            company_email: 'info@goingebil.se',
                            used_cars_email: 'fsg.hlm@goingebil.se',
                            new_cars_email: 'fsg.hlm@goingebil.se',
                            new_contact_email: 'pm.persson@goingebil.se',
                            home_page: 'http://www.goingebil.se/',
                            country: 'Sweden',
                            latitude: '56.1702589',
                            longitude: '13.7587598',
                            business_group: '0117',
                            price_localization: 'SE1011'
                        }
                        }
                    },
                    {
                        companyId: 'ca27754b-1cd0-45fc-b6b2-171380dd7e0b',
                        companyNumber: 'S166',
                        parentCompanyId: '2970a39b-68b1-4096-92bb-11b17f01e42b',
                        name: 'Göinge Bil Markaryd',
                        branchCode: '0166',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0166',
                            name: 'Göinge Bil AB',
                            structure_type: 'Filial',
                            district: '0166',
                            occupation: 'Sälj                Service',
                            center_district: '0117',
                            visit_address: 'Hässleholmsvägen 2',
                            visit_post_number: '28533',
                            visit_post_town: 'Markaryd',
                            mail_address: 'Hässleholmsvägen 2',
                            mail_post_number: '28533',
                            mail_post_town: 'Markaryd',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0433-12050',
                            fax: '0433-10878',
                            company_email: 'info@goingebil.se',
                            used_cars_email: 'fsg.mry@goingebil.se',
                            new_cars_email: 'fsg.mry@goingebil.se',
                            new_contact_email: 'pm.persson@goingebil.se',
                            home_page: 'http://www.goingebil.se/',
                            country: 'Sweden',
                            latitude: '56.45501',
                            longitude: '13.593471',
                            business_group: '0117',
                            price_localization: 'SE1011'
                        }
                        }
                    },
                    {
                        companyId: '4389b5ec-a07d-40a6-b428-0ae39265237a',
                        companyNumber: 'S164',
                        parentCompanyId: '2970a39b-68b1-4096-92bb-11b17f01e42b',
                        name: 'Göinge Bil Älmhult',
                        branchCode: '0164',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0164',
                            name: 'Göinge Bil AB',
                            structure_type: 'Filial',
                            district: '0164',
                            occupation: 'Sälj                Service',
                            center_district: '0117',
                            visit_address: 'Danska Vägen 4',
                            visit_post_number: '34331',
                            visit_post_town: 'Älmhult',
                            mail_address: 'Danska Vägen 4',
                            mail_post_number: '34331',
                            mail_post_town: 'Älmhult',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0476-12410',
                            fax: '0476-71222',
                            company_email: 'info@goingebil.se',
                            used_cars_email: 'fsg.almh@goingebil.se',
                            new_cars_email: 'fsg.almh@goingebil.se',
                            new_contact_email: 'pm.persson@goingebil.se',
                            home_page: 'http://www.goingebil.se/',
                            country: 'Sweden',
                            latitude: '56.5548373',
                            longitude: '14.1437638',
                            business_group: '0117',
                            price_localization: 'SE1011'
                        }
                        }
                    },
                    {
                        companyId: 'aefe455c-7db7-4ca1-894f-8e9174ab69f2',
                        companyNumber: 'S163',
                        parentCompanyId: '2970a39b-68b1-4096-92bb-11b17f01e42b',
                        name: 'Göinge Bil Osby',
                        branchCode: '0163',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0163',
                            name: 'Göinge Bil AB',
                            structure_type: 'Filial',
                            district: '0163',
                            occupation: 'Sälj                Service',
                            center_district: '0117',
                            visit_address: 'Såggatan 1',
                            visit_post_number: '28341',
                            visit_post_town: 'Osby',
                            mail_address: 'Box 74',
                            mail_post_number: '28321',
                            mail_post_town: 'Osby',
                            region: 'Volvo Syd',
                            address_2: '',
                            phone: '0479-31950',
                            fax: '0479-12989',
                            company_email: 'info@goingebil.se',
                            used_cars_email: 'fsg.osby@goingebil.se',
                            new_cars_email: 'fsg.osby@goingebil.se',
                            new_contact_email: 'pm.persson@goingebil.se',
                            home_page: 'http://www.goingebil.se/',
                            country: 'Sweden',
                            latitude: '56.3770036',
                            longitude: '13.9922578',
                            business_group: '0117',
                            price_localization: 'SE1011'
                        }
                        }
                    },
                    {
                        companyId: '26abe739-9bbf-449e-b538-fd8688742b25',
                        companyNumber: '010',
                        parentCompanyId: null,
                        name: 'Helmia Bil Karlstad',
                        branchCode: '0526',
                        branchCodeAliases: [],
                        localisationId: 'SE1013',
                        authId: null,
                        afPassword: 'uu82XA',
                        info: {
                        profile: {
                            id: '0526',
                            name: 'Helmia Bil AB',
                            structure_type: 'Center',
                            district: '0526',
                            occupation: 'Sälj                Service',
                            center_district: '0526',
                            visit_address: 'Packhusgatan 1',
                            visit_post_number: '65226',
                            visit_post_town: 'Karlstad',
                            mail_address: 'Box 5114',
                            mail_post_number: '65005',
                            mail_post_town: 'Karlstad',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '054-7754100',
                            fax: '054-7754250',
                            company_email: 'kontakt.karlstad@helmia.se',
                            used_cars_email: 'kontakt.karlstad@helmia.se',
                            new_cars_email: 'kontakt.karlstad@helmia.se',
                            new_contact_email: 'robert.leben@helmia.se',
                            home_page: 'http://www.helmia.se/',
                            country: 'Sweden',
                            latitude: '59.3744132',
                            longitude: '13.5044907',
                            business_group: '0526',
                            price_localization: 'SE1013'
                        }
                        }
                    },
                    {
                        companyId: '819f635b-2d60-4b79-beaa-dfb807d5dea5',
                        companyNumber: 'S530',
                        parentCompanyId: '26abe739-9bbf-449e-b538-fd8688742b25',
                        name: 'Helmia Bil Karlskoga',
                        branchCode: '0530',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0530',
                            name: 'Helmia Bil AB',
                            structure_type: 'Filial',
                            district: '0530',
                            occupation: 'Sälj                Service',
                            center_district: '0526',
                            visit_address: 'Tibastvägen 8',
                            visit_post_number: '69122',
                            visit_post_town: 'Karlskoga',
                            mail_address: 'Box 89',
                            mail_post_number: '69122',
                            mail_post_town: 'Karlskoga',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0586-794400',
                            fax: '0586-794450',
                            company_email: 'kontakt.karlskoga@helmia.se',
                            used_cars_email: 'kontakt.karlskoga@helmia.se',
                            new_cars_email: 'kontakt.karlskoga@helmia.se',
                            new_contact_email: 'jens.mellkvist@helmia.se',
                            home_page: 'http://www.helmia.se/',
                            country: 'Sweden',
                            latitude: '59.3004433',
                            longitude: '14.4908841',
                            business_group: '0526',
                            price_localization: 'SE1013'
                        }
                        }
                    },
                    {
                        companyId: 'f8c8d91e-82fc-4dcf-b959-bbe3c2ae09c4',
                        companyNumber: 'S572',
                        parentCompanyId: '26abe739-9bbf-449e-b538-fd8688742b25',
                        name: 'Helmia Bil Filipstad',
                        branchCode: '0572',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0572',
                            name: 'Helmia Bil AB',
                            structure_type: 'Filial',
                            district: '0572',
                            occupation: 'Sälj                Service',
                            center_district: '0526',
                            visit_address: 'Scheélegatan 2',
                            visit_post_number: '68222',
                            visit_post_town: 'Filipstad',
                            mail_address: 'Box 76',
                            mail_post_number: '68222',
                            mail_post_town: 'Filipstad',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0590-774700',
                            fax: '0590-774750',
                            company_email: 'kontakt.filipstad@helmia.se',
                            used_cars_email: 'kontakt.filipstad@helmia.se',
                            new_cars_email: 'kontakt.filipstad@helmia.se',
                            new_contact_email: 'anna.hermansson@helmia.se',
                            home_page: 'http://www.helmia.se/',
                            country: 'Sweden',
                            latitude: '59.7145792',
                            longitude: '14.1744162',
                            business_group: '0526',
                            price_localization: 'SE1013'
                        }
                        }
                    },
                    {
                        companyId: 'f83f159d-b649-4b40-aa75-eb553a3343b5',
                        companyNumber: 'S501',
                        parentCompanyId: '26abe739-9bbf-449e-b538-fd8688742b25',
                        name: 'Helmia Bil Arvika',
                        branchCode: '0501',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0501',
                            name: 'Helmia Bil AB',
                            structure_type: 'Center',
                            district: '0501',
                            occupation: 'Sälj                Service',
                            center_district: '0501',
                            visit_address: 'Åkaregatan 5',
                            visit_post_number: '67126',
                            visit_post_town: 'Arvika',
                            mail_address: 'Box 250',
                            mail_post_number: '67126',
                            mail_post_town: 'Arvika',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0570-744300',
                            fax: '0570-744350',
                            company_email: 'kontakt.arvika@helmia.se',
                            used_cars_email: 'kontakt.arvika@helmia.se',
                            new_cars_email: 'kontakt.arvika@helmia.se',
                            new_contact_email: 'stefan.andersson@helmia.se',
                            home_page: 'http://www.helmia.se/',
                            country: 'Sweden',
                            latitude: '59.6602921',
                            longitude: '12.6103588',
                            business_group: '0526',
                            price_localization: 'SE1013'
                        }
                        }
                    },
                    {
                        companyId: '3f43b08e-3b67-4126-9f65-e7cb6eb6d97d',
                        companyNumber: 'S557',
                        parentCompanyId: '26abe739-9bbf-449e-b538-fd8688742b25',
                        name: 'Helmia Bil Sunne',
                        branchCode: '0557',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0557',
                            name: 'Helmia Bil AB',
                            structure_type: 'Filial',
                            district: '0557',
                            occupation: 'Sälj                Service',
                            center_district: '0501',
                            visit_address: 'Norrgårdsgatan 1',
                            visit_post_number: '68623',
                            visit_post_town: 'Sunne',
                            mail_address: 'Box 103',
                            mail_post_number: '68623',
                            mail_post_town: 'Sunne',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0565-744000',
                            fax: '0565-744050',
                            company_email: 'kontakt.sunne@helmia.se',
                            used_cars_email: 'kontakt.sunne@helmia.se',
                            new_cars_email: 'kontakt.sunne@helmia.se',
                            new_contact_email: 'magnus.lofterud@helmia.se',
                            home_page: 'http://www.helmia.se/',
                            country: 'Sweden',
                            latitude: '59.8382441',
                            longitude: '13.1270383',
                            business_group: '0526',
                            price_localization: 'SE1013'
                        }
                        }
                    },
                    {
                        companyId: 'a68af785-52bf-4fa7-a387-0d1c4ce28e4e',
                        companyNumber: 'S502',
                        parentCompanyId: '26abe739-9bbf-449e-b538-fd8688742b25',
                        name: 'Helmia Bil Årjäng',
                        branchCode: '0502',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '6f201116-e55d-4c4d-90d4-39cf363b3504',
                        companyNumber: 'S517',
                        parentCompanyId: '26abe739-9bbf-449e-b538-fd8688742b25',
                        name: 'Helmia Bil Hagfors',
                        branchCode: '0517',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '119448f4-5d67-4ef7-84f8-3a600dcb6322',
                        companyNumber: 'S558',
                        parentCompanyId: '26abe739-9bbf-449e-b538-fd8688742b25',
                        name: 'Helmia Bil Torsby',
                        branchCode: '0558',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0558',
                            name: 'Helmia Bil AB',
                            structure_type: 'Filial',
                            district: '0558',
                            occupation: 'Sälj                Service',
                            center_district: '0501',
                            visit_address: 'Toria Center',
                            visit_post_number: '68523',
                            visit_post_town: 'Torsby',
                            mail_address: 'Toria Center',
                            mail_post_number: '68523',
                            mail_post_town: 'Torsby',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0560-774500',
                            fax: '0560-774550',
                            company_email: 'kontakt.torsby@helmia.se',
                            used_cars_email: 'kontakt.torsby@helmia.se',
                            new_cars_email: 'kontakt.torsby@helmia.se',
                            new_contact_email: 'patrik.hedlund@helmia.se',
                            home_page: 'http://www.helmia.se/',
                            country: 'Sweden',
                            latitude: '60.1305374',
                            longitude: '12.9935888',
                            business_group: '0526',
                            price_localization: 'SE1013'
                        }
                        }
                    },
                    {
                        companyId: '83ab7bbc-87cb-4a42-be5b-f73bbbe7c3c5',
                        companyNumber: '014',
                        parentCompanyId: null,
                        name: 'Stendahls Bil Lilla Edet',
                        branchCode: '0234',
                        branchCodeAliases: [],
                        localisationId: 'SE5044',
                        authId: null,
                        afPassword: 'bokuho',
                        info: {
                        profile: {
                            id: '0234',
                            name: 'Stendahls Bil AB',
                            structure_type: 'Center',
                            district: '0234',
                            occupation: 'Sälj                Service',
                            center_district: '0234',
                            visit_address: 'Brovägen 20',
                            visit_post_number: '46330',
                            visit_post_town: 'Lilla Edet',
                            mail_address: 'Box 1022',
                            mail_post_number: '46324',
                            mail_post_town: 'Lilla Edet',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0520-494090',
                            fax: '0520-494091',
                            company_email: '',
                            used_cars_email: 'intresseanmalan@stendahlsbil.com',
                            new_cars_email: 'intresseanmalan@stendahlsbil.com',
                            new_contact_email: 'ake@stendahlsbil.se',
                            home_page: 'http://www.stendahlsbil.se/',
                            country: 'Sweden',
                            latitude: '58.1435557',
                            longitude: '12.1342922',
                            business_group: '0234',
                            price_localization: 'SE5044'
                        }
                        }
                    },
                    {
                        companyId: '1abe6c80-0614-43da-a175-40903b5d8f6d',
                        companyNumber: 'S223',
                        parentCompanyId: '83ab7bbc-87cb-4a42-be5b-f73bbbe7c3c5',
                        name: 'Stendahls Bil Stenungsund',
                        branchCode: '0223',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0223',
                            name: 'Stendahls Bil AB',
                            structure_type: 'Filial',
                            district: '0223',
                            occupation: 'Sälj                Service',
                            center_district: '0234',
                            visit_address: 'Munkerödsvägen 3',
                            visit_post_number: '44432',
                            visit_post_town: 'Stenungsund',
                            mail_address: 'Munkerödsvägen 3',
                            mail_post_number: '44432',
                            mail_post_town: 'Stenungsund',
                            region: 'Volvo Mitt',
                            address_2: '',
                            phone: '0303-360330',
                            fax: '0520-494091',
                            company_email: '',
                            used_cars_email: 'intresseanmalan@stendahlsbil.com',
                            new_cars_email: 'intresseanmalan@stendahlsbil.com',
                            new_contact_email: 'glenn@stendahlsbil.se',
                            home_page: 'http://www.stendahlsbil.se/',
                            country: 'Sweden',
                            latitude: '58.0639142',
                            longitude: '11.8727915',
                            business_group: '0234',
                            price_localization: 'SE5044'
                        }
                        }
                    },
                    {
                        companyId: 'd6e5d74d-4403-453d-8e86-87fff1c589d3',
                        companyNumber: 'S386',
                        parentCompanyId: '83ab7bbc-87cb-4a42-be5b-f73bbbe7c3c5',
                        name: 'Stendahls Bil Älvängen',
                        branchCode: '0386',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '60aa05d4-fa05-41c8-92b1-76f8150cc10c',
                        companyNumber: '015',
                        parentCompanyId: null,
                        name: 'Upplands Motor Uppsala',
                        branchCode: '0435',
                        branchCodeAliases: [],
                        localisationId: 'SE9997',
                        authId: null,
                        afPassword: 'dorale',
                        info: {
                        profile: {
                            id: '0435',
                            name: 'Upplands Motor AB',
                            structure_type: 'Center',
                            district: '0435',
                            occupation: 'Sälj                Service',
                            center_district: '0435',
                            visit_address: 'Kungsgatan 97',
                            visit_post_number: '75318',
                            visit_post_town: 'Uppsala',
                            mail_address: 'Box 435',
                            mail_post_number: '75106',
                            mail_post_town: 'Uppsala',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0771-900 100',
                            fax: '018-693097',
                            company_email: 'info@upplandsmotor.se',
                            used_cars_email: 'kontakt@upplandsmotor.se',
                            new_cars_email: 'personalbilfoersaeljningvolvoua@upplandsmotor.se',
                            new_contact_email: 's.hedlund@upplandsmotor.se',
                            home_page: 'http://www.upplandsmotor.se/',
                            country: 'Sweden',
                            latitude: '59.8480992',
                            longitude: '17.6603815',
                            business_group: '0435',
                            price_localization: 'SE9997'
                        }
                        }
                    },
                    {
                        companyId: 'ea68ac6e-a6b0-44a6-a067-7444ab377f4a',
                        companyNumber: 'S438',
                        parentCompanyId: '60aa05d4-fa05-41c8-92b1-76f8150cc10c',
                        name: 'Upplands Motor Stockholm Arlandastad',
                        branchCode: '0438',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0438',
                            name: 'Upplands Motor AB / Arlandastad',
                            structure_type: 'Filial',
                            district: '0438',
                            occupation: 'Sälj                Service',
                            center_district: '0435',
                            visit_address: 'Servogatan 16',
                            visit_post_number: '19560',
                            visit_post_town: 'Stockholm',
                            mail_address: 'Servogatan 16',
                            mail_post_number: '19560',
                            mail_post_town: 'Arlandastad',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0771-900 100',
                            fax: '08-59115427',
                            company_email: 'info@upplandsmotor.se',
                            used_cars_email: 'kontakt@upplandsmotor.se',
                            new_cars_email: 'PersonalFoersaeljningArlandastad@upplandsmotor.se',
                            new_contact_email: 's.hedlund@upplandsmotor.se',
                            home_page: 'http://www.upplandsmotor.se/',
                            country: 'Sweden',
                            latitude: '59.6142639',
                            longitude: '17.8710995',
                            business_group: '0435',
                            price_localization: 'SE9997'
                        }
                        }
                    },
                    {
                        companyId: '67211e11-a2a5-47ca-92aa-3a3976a4cfcc',
                        companyNumber: 'S468',
                        parentCompanyId: '60aa05d4-fa05-41c8-92b1-76f8150cc10c',
                        name: 'Upplands Motor Stockholm Kista',
                        branchCode: '0468',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0468',
                            name: 'Upplands Motor AB / Kista',
                            structure_type: 'Center',
                            district: '0468',
                            occupation: 'Sälj                Service',
                            center_district: '0468',
                            visit_address: 'Kronåsvägen 2-4',
                            visit_post_number: '19146',
                            visit_post_town: 'Stockholm',
                            mail_address: 'Box 466',
                            mail_post_number: '19124',
                            mail_post_town: 'Sollentuna',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0771-900 100',
                            fax: '018-69 30 97',
                            company_email: 'info@upplandsmotor.se',
                            used_cars_email: 'BilforsAK@upplandsmotor.se',
                            new_cars_email: 'bilfoersaeljningak@upplandsmotor.se',
                            new_contact_email: '',
                            home_page: 'http://www.upplandsmotor.se/',
                            country: 'Sweden',
                            latitude: '59.4214703',
                            longitude: '17.9244',
                            business_group: '0435',
                            price_localization: 'SE9997'
                        }
                        }
                    },
                    {
                        companyId: 'b8066afe-c5c5-49ee-86a4-f4c544b55ec3',
                        companyNumber: 'S434',
                        parentCompanyId: '60aa05d4-fa05-41c8-92b1-76f8150cc10c',
                        name: 'Upplands Motor Tierp',
                        branchCode: '0434',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0434',
                            name: 'Upplands Motor AB',
                            structure_type: 'Filial',
                            district: '0434',
                            occupation: 'Sälj',
                            center_district: '0435',
                            visit_address: 'Bäggebygatan 17',
                            visit_post_number: '81522',
                            visit_post_town: 'Tierp',
                            mail_address: 'Bäggebygatan 17',
                            mail_post_number: '81522',
                            mail_post_town: 'Tierp',
                            region: 'Volvo Öst',
                            address_2: '',
                            phone: '0293-13770',
                            fax: '0293-13765',
                            company_email: '',
                            used_cars_email: 'a.adamsson@upplandsmotor.se',
                            new_cars_email: 'a.adamsson@upplandsmotor.se',
                            new_contact_email: 'SE',
                            home_page: '',
                            country: 'Sweden',
                            latitude: '60.33891',
                            longitude: '17.52053'
                        }
                        }
                    },
                    {
                        companyId: 'deace4dc-fb15-42b6-8be3-87b954bc7455',
                        companyNumber: 'S466',
                        parentCompanyId: '60aa05d4-fa05-41c8-92b1-76f8150cc10c',
                        name: 'Upplands Motor Stockholm Hammarby',
                        branchCode: '0466',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0466',
                            name: 'Upplands Motor AB / Hammarby',
                            structure_type: 'Center',
                            district: '0466',
                            occupation: 'Sälj                Service',
                            center_district: '0466',
                            visit_address: 'Hammarby Fabriksväg 33-35',
                            visit_post_number: '12030',
                            visit_post_town: 'Stockholm',
                            mail_address: 'Hammarby Fabriksväg 33-35',
                            mail_post_number: '12030',
                            mail_post_town: 'Stockholm',
                            region: '',
                            address_2: '',
                            phone: '0771-900 100',
                            fax: '',
                            company_email: 'personalforsaljninghammarby@upplandsmotor.se',
                            used_cars_email: 'personalforsaljninghammarby@upplandsmotor.se',
                            new_cars_email: 'personalforsaljninghammarby@upplandsmotor.se',
                            new_contact_email: '',
                            home_page: 'http://www.upplandsmotor.se/',
                            country: 'Sweden',
                            latitude: '59.3012076',
                            longitude: '18.0958329',
                            business_group: '0435',
                            price_localization: 'SE9997'
                        }
                        }
                    },
                    {
                        companyId: '84110f2e-3898-499c-9b7c-f92ec2c762dc',
                        companyNumber: 'V100',
                        parentCompanyId: null,
                        name: 'Lidingö Bilteknik AB',
                        branchCode: '0472',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: 'cc5bc4b7-b4fb-40bd-be30-c4d4ea553996',
                        companyNumber: 'V101',
                        parentCompanyId: null,
                        name: 'Mechanum',
                        branchCode: '0288',
                        branchCodeAliases: [
                        '0149',
                        '0233',
                        '0200'
                        ],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: 'adf3824f-b0dc-4b4b-b125-e1174966b689',
                        companyNumber: 'V102',
                        parentCompanyId: null,
                        name: 'CMS Carmont Service AB',
                        branchCode: '0274',
                        branchCodeAliases: [
                        '0275',
                        '0296'
                        ],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {}
                    },
                    {
                        companyId: '3cb78558-76ee-4a76-9f2b-f1561fb31b5c',
                        companyNumber: '016',
                        parentCompanyId: null,
                        name: 'Visby Motorcentral',
                        branchCode: '0433',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: null,
                        afPassword: null,
                        info: {
                        profile: {
                            id: '0433',
                            name: 'AB Visby Motorcentral',
                            structure_type: 'Center',
                            district: '0433',
                            occupation: 'Sälj                Service',
                            center_district: '0433',
                            visit_address: 'Verkstadsgatan 7C',
                            visit_post_number: '621 41',
                            visit_post_town: 'Visby',
                            mail_address: 'Box 1093',
                            mail_post_number: '62121',
                            mail_post_town: 'Visby',
                            region: '',
                            address_2: '',
                            phone: '0498-205200',
                            fax: '0498-205228',
                            company_email: 'info@vmcgotland.se',
                            used_cars_email: 'info@vmcgotland.se',
                            new_cars_email: 'tony.wigsten@vmcgotland.se',
                            new_contact_email: '',
                            home_page: 'http://www.vmcgotland.se/',
                            country: 'Sweden',
                            latitude: '57.64461',
                            longitude: '18.324044',
                            business_group: '0433',
                            price_localization: 'SE_NATIONAL'
                        }
                        }
                    },
                    {
                        companyId: 'ae2438a5-a388-4fb3-8eda-4102062c36b5',
                        companyNumber: '409',
                        parentCompanyId: null,
                        name: 'TKBM',
                        branchCode: '0409',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: 'c0d48608-1912-4dae-a62c-322a864ae9ca',
                        companyNumber: '401',
                        parentCompanyId: null,
                        name: 'Volvo Studio Stockholm',
                        branchCode: '0752',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {
                        facilities: [
                            {
                            name: 'Öppettider',
                            telephone: '',
                            sort_order: 0,
                            opening_hours: {
                                monday: {
                                from: '11:00',
                                to: '17:00'
                                },
                                tuesday: {
                                from: '11:00',
                                to: '17:00'
                                },
                                wednesday: {
                                from: '11:00',
                                to: '17:00'
                                },
                                thursday: {
                                from: '11:00',
                                to: '17:00'
                                },
                                friday: {
                                from: '11:00',
                                to: '17:00'
                                },
                                saturday: {
                                from: '11:00',
                                to: '15:00'
                                },
                                sunday: null
                            },
                            exceptions: [],
                            department: 'showroom'
                            }
                        ]
                        }
                    },
                    {
                        companyId: '71687b8a-25a9-418d-a608-40eb68fb0f00',
                        companyNumber: 'H0001',
                        parentCompanyId: null,
                        name: 'Hertz',
                        branchCode: 'HQ0001',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: 'a68904bf-2bef-47d5-8066-befd5b07b854',
                        companyNumber: 'SWKTD64',
                        parentCompanyId: '71687b8a-25a9-418d-a608-40eb68fb0f00',
                        name: 'Karlstad DT',
                        branchCode: 'SWKTD64',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: '94b9e2be-c395-43d6-a66a-5efd3cfb5efd',
                        companyNumber: 'SWKRN50',
                        parentCompanyId: '71687b8a-25a9-418d-a608-40eb68fb0f00',
                        name: 'Kiruna AP',
                        branchCode: 'SWKRN50',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: '4c0345e7-ad02-4116-a030-195619a168be',
                        companyNumber: 'SWGOT50',
                        parentCompanyId: '71687b8a-25a9-418d-a608-40eb68fb0f00',
                        name: 'Göteborg Landvetter AP',
                        branchCode: 'SWGOT50',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: 'e9563efe-ddf5-46fe-bfb3-b0fa9d4d5548',
                        companyNumber: 'SWMMA50',
                        parentCompanyId: '71687b8a-25a9-418d-a608-40eb68fb0f00',
                        name: 'Malmö AP',
                        branchCode: 'SWMMA50',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: '8766bd29-7079-4f5e-97a0-9978b27f31f3',
                        companyNumber: 'SWMMA60',
                        parentCompanyId: '71687b8a-25a9-418d-a608-40eb68fb0f00',
                        name: 'Malmö DT',
                        branchCode: 'SWMMA60',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: '9047afe3-9490-4589-a3a1-32c1a9b0ef8f',
                        companyNumber: 'SWNYO50',
                        parentCompanyId: '71687b8a-25a9-418d-a608-40eb68fb0f00',
                        name: 'Nyköping Skavsta AP',
                        branchCode: 'SWNYO50',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: '328568e1-d99b-497a-84d9-7854fb35c290',
                        companyNumber: 'SWSTO60',
                        parentCompanyId: '71687b8a-25a9-418d-a608-40eb68fb0f00',
                        name: 'Stockholm Centralstationen',
                        branchCode: 'SWSTO60',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: '1cef1403-8e4d-473e-b3d1-b6cb3e0532cd',
                        companyNumber: 'SWSTK62',
                        parentCompanyId: '71687b8a-25a9-418d-a608-40eb68fb0f00',
                        name: 'Stockholm Odenplan',
                        branchCode: 'SWSTK62',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: '540eca8d-d1c4-42cf-b380-9f32fe8f4973',
                        companyNumber: 'SWSTO50',
                        parentCompanyId: '71687b8a-25a9-418d-a608-40eb68fb0f00',
                        name: 'Stockholm Arlanda AP',
                        branchCode: 'SWSTO50',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: '0c452695-04ea-4b42-97aa-8ca26cc2ab5f',
                        companyNumber: 'SWBRO50',
                        parentCompanyId: '71687b8a-25a9-418d-a608-40eb68fb0f00',
                        name: 'Stockholm Bromma AP',
                        branchCode: 'SWBRO50',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: 'f66fc467-bd78-42c3-bc04-70265fc2641a',
                        companyNumber: 'SWGOT60',
                        parentCompanyId: '71687b8a-25a9-418d-a608-40eb68fb0f00',
                        name: 'Göteborg DT',
                        branchCode: 'SWGOT60',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    },
                    {
                        companyId: '944543be-8150-485e-b386-cbfe354ff90d',
                        companyNumber: 'SWHSI60',
                        parentCompanyId: '71687b8a-25a9-418d-a608-40eb68fb0f00',
                        name: 'Helsingborg',
                        branchCode: 'SWHSI60',
                        branchCodeAliases: [],
                        localisationId: 'SE_NATIONAL',
                        authId: 0,
                        afPassword: '',
                        info: {}
                    }
                ] as ICompanyNodeData[]);
            }, this.opts.companyTreeDelay || 0);
        });
    }

    public getModelYears(): Promise<IModelYearData[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve([
                    {
                      modelYearId: 'f4cbf84f-3aaf-4863-866f-6da66d8cb05f',
                      name: '2013',
                      startDate: '2012-12-31T23:00:00.000Z',
                      endDate: '2013-12-30T23:00:00.000Z',
                      current: false,
                      archived: false
                    },
                    {
                      modelYearId: 'b8218492-60be-46f1-b9de-399d45484be4',
                      name: '2014',
                      startDate: '2013-12-31T23:00:00.000Z',
                      endDate: '2014-12-30T23:00:00.000Z',
                      current: false,
                      archived: false
                    },
                    {
                      modelYearId: 'de81672a-580f-4d8d-a82f-00f5051b0259',
                      name: '2015',
                      startDate: '2014-12-31T23:00:00.000Z',
                      endDate: '2015-12-30T23:00:00.000Z',
                      current: false,
                      archived: false
                    },
                    {
                      modelYearId: 'd2a95aca-057e-43e1-a725-133527b628c2',
                      name: '2016',
                      startDate: '2015-03-01T00:00:00.000Z',
                      endDate: '2016-03-31T00:00:00.000Z',
                      current: false,
                      archived: false
                    },
                    {
                      modelYearId: '4b8a6eb1-db6f-46a3-986b-d4e01a061099',
                      name: '2017',
                      startDate: '2016-03-09T00:00:00.000Z',
                      endDate: '2017-03-31T00:00:00.000Z',
                      current: false,
                      archived: false
                    },
                    {
                      modelYearId: 'fa7d426e-f9df-4536-bcc4-d9cdf643c6e4',
                      name: '2018',
                      startDate: '2016-12-31T23:00:00.000Z',
                      endDate: '2017-12-30T23:00:00.000Z',
                      current: false,
                      archived: false
                    },
                    {
                      modelYearId: '954abdae-ac5b-43b8-9168-32ea8f114b22',
                      name: '2019',
                      startDate: '2018-02-28T23:00:00.000Z',
                      endDate: '2018-12-30T23:00:00.000Z',
                      current: false,
                      archived: false
                    },
                    {
                      modelYearId: 'ec0a16bb-9d5a-4e7b-81f2-b9c46af09078',
                      name: '2020',
                      startDate: '2018-12-31T23:00:00.000Z',
                      endDate: '2019-12-30T23:00:00.000Z',
                      current: false,
                      archived: false
                    },
                    {
                      modelYearId: '9e74e5ea-0e89-4c3f-90da-0dc514dcabbe',
                      name: '2021',
                      startDate: '2019-12-31T23:00:00.000Z',
                      endDate: '2020-12-30T23:00:00.000Z',
                      current: true,
                      archived: false
                    }
                ]);
            }, this.opts.modelYearsDelay || 0);
        });
    }

    public getXSearchResult(
        search: string,
        tags: string[],
        companyId?: string,
        modelYearId?: string
    ): Promise<IXSearchResult> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    count: 4,
                    pageNumber: 0,
                    types: [
                        {
                            count: 10,
                            code: 'ppnm/subscribers',
                            label: 'Prenumeranter',
                            theme: {
                                primaryColor: 'rgba(36, 93, 242, 1)',
                                secondaryColor: 'rgba(251, 212, 78, 1)'
                            }
                        },
                        {
                            count: 5,
                            code: 'ppnm/mailingLists',
                            label: 'Utskickslistor',
                            theme: {
                                primaryColor: 'rgba(36, 93, 242, 1)',
                                secondaryColor: 'rgba(251, 212, 78, 1)'
                            }
                        },
                        {
                            count: 22,
                            code: 'dms/offer',
                            label: 'Budskap',
                            theme: {
                                primaryColor: 'rgb(21, 101, 192)',
                                secondaryColor: '#43a047'
                            }
                        }
                    ],
                    list: [
                        {
                            applicationId: '5e383309de15501226d39d28',
                            icon: 'Person',
                            ingress: 'maks.bolotin+2@gmail.com',
                            path: '/subscriber/5e302eeaf1ac57222031341b',
                            title: 'Adam Brunsson',
                            type: 'ppnm/subscribers'
                        },
                        {
                            applicationId: '5e383309de15501226d39d28',
                            icon: 'Book',
                            ingress: 'Beskrivning',
                            path: '/mailingList/5e302b9862289b14fdfba247',
                            title: 'Min lista',
                            type: 'ppnm/mailingLists'
                        },
                        {
                            applicationId: '5e383309de15501226d39d28',
                            icon: 'Book',
                            ingress: 'Lista av ngt slag',
                            path: '/mailingList/5e302b9862289b14fdfba247',
                            title: 'Ny lista',
                            type: 'ppnm/mailingLists'
                        },
                        {
                            applicationId: '5e3837b1de15501226d39d2a',
                            thumb: 'https://static.fbinhouse.se/2020-1/loungepaket_4096x2304_SUP00079.jpg',
                            ingress: 'Gör ditt resande mer bekvämt. Med ett loungepaket får du '
                                + '360 graders parkeringskamera och...',
                            path: '/offer/ffcf142b-fc69-480f-8721-806ecee2a504',
                            title: 'Loungepaket XC40 RDes MY21 - endast P8 EL',
                            type: 'dms/offer'
                        }
                    ]
                });
            }, this.opts.xSearchDelay || 0);
        });
    }

    public markApplicationAsFavorite(id: string): Promise<void> {
        return Promise.resolve();
    }

    public unmarkApplicationAsFavorite(id: string): Promise<void> {
        return Promise.resolve();
    }

    public setPassword(password: string): Promise<void> {
        return Promise.resolve();
    }
}
