import { component } from '@lardy/core';
import { mui } from '@lardy/mui';
import NativeSelect from '@material-ui/core/NativeSelect';
import Skeleton from '@material-ui/lab/Skeleton';
import * as React from 'react';
import { ICompanyNode, INavigation } from '../../../interfaces';

export const AccountMenuCompanySelector = component(
    'AccountMenuCompanySelector'
)
    .use(mui)
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .style(() => ({
        Container: {
            root: {
                '&&': {
                    maxWidth: 105,
                    margin: '0 auto',
                    textAlign: 'center' as 'center'
                }
            }
        },
        Select: {
            root: {
                '&&': {
                    fontSize: 12,
                    textOverflow: 'ellipsis',
                    margin: '0px 0px 0px 5px'
                },
                '&&:before': {
                    display: 'none'
                }
            }
        }
    }))
    .render(({ $, navigation }) => {
        const $Select = $(NativeSelect, 'Select');

        const handleSelect = React.useCallback(
            (e: any) => {
                if (e.target.value === 'root') {
                    navigation.setSelectedCompanyId(undefined);
                } else {
                    navigation.setSelectedCompanyId(e.target.value);
                }
            },
            [navigation]
        );

        const sortByName = (a: ICompanyNode, b: ICompanyNode) => {
            if (a.name && b.name) {
                const companyA = a.name.toUpperCase();
                const companyB = b.name.toUpperCase();

                if (companyA < companyB) {
                    return -1;
                }
                if (companyA > companyB) {
                    return 1;
                }
            }
            return 0;
        };

        return (
            <$.div.Container>
                {navigation.account && navigation.companyTree.length ? (
                    <>
                        <$Select
                            value={
                                navigation.topLevelCompany
                                    ? navigation.topLevelCompany.companyId
                                    : -1
                            }
                            onChange={handleSelect}
                            fullWidth={true}
                        >
                            {navigation.accountCompanyTree
                                .sort(sortByName)
                                .map((company) => (
                                    <option
                                        value={company.companyId || 'root'}
                                        key={company.companyId || 'root'}
                                    >
                                        {company.name || 'Välj företag...'}
                                    </option>
                                ))}
                        </$Select>
                        {navigation.topLevelCompany &&
                        navigation.topLevelCompany.children.length ? (
                            <$Select
                                value={navigation.selectedCompanyId}
                                onChange={handleSelect}
                                fullWidth={true}
                            >
                                <option
                                    value={
                                        navigation.topLevelCompany.companyId ||
                                        'root'
                                    }
                                    key={
                                        navigation.topLevelCompany.companyId ||
                                        'root'
                                    }
                                >
                                    Välj dotterbolag...
                                </option>
                                {navigation.topLevelCompany.children
                                    .sort(sortByName)
                                    .map((company) => (
                                        <option
                                            value={company.companyId || 'root'}
                                            key={company.companyId || 'root'}
                                        >
                                            {company.name}
                                        </option>
                                    ))}
                            </$Select>
                        ) : null}
                    </>
                ) : (
                    <Skeleton height={20} variant="text" />
                )}
            </$.div.Container>
        );
    })
    .compile();
