import { component, types } from '@lardy/core';
import { INavigation } from './interfaces';

export const WithNavigation = component('WithNavigation')
    .props({
        children: types.fn<(navigation: INavigation) => JSX.Element>()
    })
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .render(({ children, navigation }) => children(navigation))
    .compile();
