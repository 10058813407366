import { action, computed } from 'mobx';
import { IAccount, IAccountData, INavigation } from '../interfaces';

export class Account implements IAccount {

    @computed public get userId() {
        return this.data.userId;
    }

    @computed public get name() {
        return this.data.name;
    }

    @computed public get role() {
        return this.data.role;
    }

    @computed public get emailAddress() {
        return this.data.emailAddress;
    }

    @computed public get companies() {
        return this.data.companies;
    }

    @computed public get active() {
        return this.data.active;
    }

    @computed public get dailyDigest() {
        return this.data.dailyDigest;
    }

    @computed public get confirmed() {
        return this.data.confirmed;
    }

    @computed public get lastLoginDate() {
        return this.data.lastLoginDate;
    }

    @computed public get reminderDate() {
        return this.data.reminderDate;
    }

    @computed public get isAdmin() {
        return this.role.system === true;
    }

    @computed public get avatar() {
        return `https://avatars.dicebear.com/api/initials/${encodeURIComponent(this.name)}.svg`;
    }

    private data: IAccountData;
    private nav: INavigation;

    public constructor(nav: INavigation, data: IAccountData) {
        this.nav = nav;
        this.data = data;
    }

    @action public logout() {
        this.nav.auth.logout();
    }

    @action public async setPassword(password: string) {
        try {
            await this.nav.service.setPassword(password);
        } catch (err) {
            throw new Error('Error setting password');
        }
    }

    public gotoSetPasswordPage() {
        window.open('https://chaz.fbinhouse.se/myAccount/' + this.userId);
    }
}
