import { component, types } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import List from '@material-ui/core/List';
import * as React from 'react';
import { INavigation } from '../../interfaces';
import { Popover } from '../popover';
import { XSearchbarDivider } from './xSearchbarDivider';
import { XSearchbarResult } from './xSearchbarResult';

export const XSearchbarResultsInner = component('XSearchbarResults')
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .use(mui)
    .use(theme)
    .style(({ theme }) => ({
        Root: {
            width: '90vw',
            maxWidth: 900,
            backgroundColor: theme ? theme.palette.background.paper : undefined,
            padding: 0
        }
    }))
    .render(({ $, navigation }) => {
        const $Root = $(List, 'Root');

        return (
            <$Root>
                {
                    navigation.xSearchEntities !== undefined
                    ? navigation.xSearchEntities.map((item) => item.type === 'item' ? (
                        <XSearchbarResult item={item.data} />
                    ) : (
                        <XSearchbarDivider data={item.data} />
                    ))
                    : Array.from(Array(5).keys()).map(() => (
                        <XSearchbarResult />
                    ))
                }
            </$Root>
        );
    })
    .compile();

export const XSearchbarResults = component('XSearchbarResults')
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .props({
        anchorEl: types.maybe(types.complex<HTMLDivElement>())
    })
    .reduce(({ navigation, ...props }) => ({
        ...props,
        anchorEl: navigation.xSearchQuery ? props.anchorEl : undefined,
        onClose: () => {
            navigation.clearXSearch();
        }
    }))
    .render(({ $, anchorEl, onClose }) => {
        return (
            <Popover
                placement="bottom"
                anchorEl={anchorEl}
                onClose={onClose}
                id="x-searchbar-results"
            >
                <XSearchbarResultsInner />
            </Popover>
        );
    })
    .compile();
