import { observable } from 'mobx';
import { IUIControl } from '../interfaces';

export class UIControl<T> implements IUIControl<T> {
    public role: string;
    @observable public state: T;

    public constructor(role: string, defaultValue: T) {
        this.role = role;
        this.state = defaultValue;
    }

    public setState = (state: T) => {
        this.state = state;
    }
}
