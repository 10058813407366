import { IDataService } from '../interfaces';
import { ClientGQLTransport } from './clientGqlTransport';
import { DataService } from './dataService';

export class ClientGQLService extends DataService implements IDataService {
    public constructor(
        fallbackUrl: string = 'https://api.chaz.fbinhouse.se'
    ) {
        const transport = new ClientGQLTransport(fallbackUrl);

        super(transport);
    }
}
