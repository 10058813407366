import { component } from '@lardy/core';
import { mui } from '@lardy/mui';
import Box from '@material-ui/core/Box';
import * as React from 'react';
import { AccountMenuAvatar } from '../accountMenuAvatar';
import { AccountMenuChangePasswordLink } from '../accountMenuChangePasswordLink';
import { AccountMenuCompanySelector } from '../accountMenuCompanySelector';
import { AccountMenuEditProfileLink } from '../accountMenuEditProfileLink';
import { AccountMenuFooter } from '../accountMenuFooter';
import { AccountMenuUsername } from '../accountMenuUsername';

export const AccountMenuInner = component('AccountMenuInner')
    .use(mui)
    .style(() => ({
        Inner: {
            root: {
                '&&': {
                    padding: '20px'
                }
            }
        }
    }))
    .render(({  $  }) => {
        const $Inner = $(Box, 'Inner');

        return (
            <>
                <$Inner>
                    <AccountMenuAvatar />
                    <AccountMenuUsername />
                    <AccountMenuCompanySelector />
                    <AccountMenuChangePasswordLink />
                    <AccountMenuEditProfileLink />
                </$Inner>
                <AccountMenuFooter />
            </>
        );
    })
    .compile();
