import { action, computed, observable } from 'mobx';
import { IMenu, IMenuItem, IMenuItemData } from '../interfaces';

export class MenuItem implements IMenuItem {

    @computed public get children() {
        return this.menu.menuItems.filter((menuItem) => {
            return menuItem.parentId === this.id;
        });
    }

    @computed public get data(): IMenuItemData {
        return this.menu.menuItemData.find((menuItemData) => {
            return menuItemData.id === this.id;
        })!;
    }

    @computed public get isExpanded() {
        return this.menu.expandedMenuItemIds.indexOf(this.id) !== -1;
    }

    @computed public get type() {
        if (this.data.type === 'expander' && this.children.length === 0) {
            return 'action';
        }

        return this.data.type;
    }

    @computed public get label() {
        return this.data.label;
    }

    @computed public get icon() {
        return this.data.icon;
    }

    @computed public get iconColor() {
        return this.data.iconColor;
    }

    @computed public get shortcut() {
        return this.data.shortcut;
    }

    @computed public get parentId() {
        return this.data.parentId;
    }

    @computed public get active() {
        return this.data.active;
    }

    @observable public id: string;
    private menu: IMenu;

    public constructor(menu: IMenu, id: string) {
        this.menu = menu;
        this.id = id;
    }

    public click = () => {
        if (this.type === 'action') {
            this.select();
        } else if (this.type === 'modal') {
            this.toggle();
        } else if (this.type === 'expander') {
            this.expand();
        }
    }

    public focus = () => {
        this.menu.setHoveringMenuItemId(this.id);

        if (this.type === 'expander' && this.menu.expandedMenuItemIds.length > 0) {
            this.expand();
        }
    }

    public blur = () => {
        if (this.type === 'expander') {
            this.collapse();
        }
    }

    public select() {
        this.menu.controller.emit('menuItemActionClicked', {
            id: this.id
        });
    }

    public toggle() {
        this.menu.setMenuItemModalActive(this.id, !this.active);

        this.menu.controller.emit('menuItemModalSet', {
            id: this.id,
            active: this.active
        });
    }

    @action public expand() {
        this.menu.setHoveringMenuItemId(this.id);

        if (this.parentId === undefined) {
            this.menu.setExpandedMenuItemIds([this.id]);

            return;
        }

        const parentIndex = this.menu.expandedMenuItemIds.indexOf(this.parentId);

        if (parentIndex === -1) {
            return;
        }

        this.menu.setExpandedMenuItemIds(this.menu.expandedMenuItemIds.slice(0, parentIndex + 1).concat(this.id));

        this.menu.controller.emit('menuItemExpanded', {
            id: this.id
        });
    }

    @action public collapse() {
        /* const index = this.menu.expandedMenuItemIds.indexOf(this.id);

        if (index === undefined) {
            return;
        }

        this.menu.setExpandedMenuItemIds(this.menu.expandedMenuItemIds.slice(0, index));

        this.menu.controller.emit('menuItemCollapsed', {
            id: this.id
        }); */
    }
}
