import { component, IType, types } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { Palette } from '@material-ui/core/styles/createPalette';
import * as React from 'react';
import { ExtendedColorSet, TintSet } from '../../interfaces';

export const extendedColorSetType = types.maybe(types.complex<ExtendedColorSet>());
export const tintSetType = types.maybe(types.complex<TintSet>());

export const chuiInputBaseProps = {
    backgroundColor: extendedColorSetType.withDefault('paper'),
    backgroundTint: tintSetType.withDefault('main'),
    elevation: types.maybe(types.num).withDefault(2),
    textColor: extendedColorSetType.withDefault('inherit'),
    textTint: tintSetType.withDefault('main')
};

export type ChuiInputBaseITypes = {
    [K in keyof InputBaseProps]-?: IType<InputBaseProps[K]>
} & typeof chuiInputBaseProps;

export const getColor = (palette: Palette, color?: ExtendedColorSet, tint?: TintSet) => {
    const {
        primary,
        secondary,
        action,
        error,
        background
    } = palette;

    tint = tint || 'main';

    switch (color) {
        case 'inherit':
            return undefined;
        case 'action':
            return action.active;
        case 'background':
            return background.default;
        case 'disabled':
            return action.disabled;
        case 'error':
            return error[tint];
        case 'paper':
            return background.paper;
        case 'primary':
            return primary[tint];
        case 'secondary':
            return secondary[tint];
    }

    return undefined;
};

export const ChuiInputBase = component('ChuiInputBase')
    .use(mui)
    .use(theme)
    .props<ChuiInputBaseITypes>(chuiInputBaseProps as any)
    .style(({
        backgroundColor,
        backgroundTint,
        textColor,
        textTint,
        theme
    }) => {
        const Root: any = {
            margin: '0px',
            padding: '0px 0px 0px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 380
        };

        const InputBase: any = {
            marginLeft: 8,
            flex: 1
        };

        if (theme) {
            Root.background = getColor(theme.palette, backgroundColor, backgroundTint);
            InputBase.color = getColor(theme.palette, textColor, textTint);
        }

        return { InputBase, Root };
    })
    .reduce(({ backgroundColor, backgroundTint, textColor, textTint, theme, ...props }) => props)
    .render(({ $, children, elevation, ...props }) => {
        const $Root = $(Paper, 'Root');
        const $InputBase = $(InputBase, 'InputBase');

        return (
            <$Root square elevation={elevation}>
                <$InputBase
                    {...props}
                    placeholder="Sök i hela Chaz..."
                    inputProps={{ 'aria-label': 'search in chaz' }}
                />
                {children}
            </$Root>
        );
    })
    .compile();
