import { component, types } from '@lardy/core';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import * as React from 'react';
import { INavigation } from '../../interfaces';
import { PopupMenu } from '../popupMenu';
import { AccountMenuInner } from './accountMenuInner';

export const AccountMenu = component('AccountMenu')
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .props({
        color: types.maybe(
            types.complex<'inherit' | 'disabled' | 'error' | 'action' | 'primary' | 'secondary'>()
                .withDefault('inherit')
        ),
        tint: types.maybe(
            types.complex<'dark' | 'main' | 'light'>()
                .withDefault('main')
        )
    })
    .render(({ color, tint, navigation }) => {
        const Icon = navigation.account
            ? () => <Avatar style={{ width: 35, height: 35 }} src={navigation!.account!.avatar} />
             : AccountCircle;

        return (
            <PopupMenu
                Icon={Icon}
                onOpen={() => {
                    navigation.loadAccount();
                    navigation.loadApplications();
                    navigation.loadCompanyTree();
                }}
                id="popup-menu-account"
                color={color}
                tint={tint}
            >
                <AccountMenuInner />
            </PopupMenu >
        );
    })
    .compile();
