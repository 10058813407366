import { component } from '@lardy/core';
import { mui } from '@lardy/mui';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { ApplicationMenuList } from '../applicationMenuList';
import { ApplicationMenuSearchbar } from '../applicationMenuSearchbar';

export const ApplicationMenuInner = component('ApplicationMenuInner')
    .use(mui)
    .style(() => ({
        Inner: {
            root: {
                '&&': {
                    padding: '20px'
                }
            }
        },
        Title: {
            root: {
                '&&': {
                    fontSize: 18,
                    fontWeight: 100,
                    textAlign: 'center' as 'center'
                }
            }
        }
    }))
    .render(({ $ }) => {
        const $Inner = $(Box, 'Inner');
        const $Title = $(Typography, 'Title');

        return (
            <$Inner>
                <$Title variant="h6">
                    Applications
                </$Title>
                <ApplicationMenuSearchbar />
                <ApplicationMenuList />
            </$Inner>
        );
    })
    .compile();
