import { action, computed } from 'mobx';
import { IModelYear, IModelYearData, INavigation } from '../interfaces';

export class ModelYear implements IModelYear {

    @computed private get data(): IModelYearData {
        return this.nav.modelYearsData.find((data) => {
            return data.modelYearId === this.modelYearId;
        })!;
    }

    @computed public get selected() {
        return this.data.modelYearId === this.nav.selectedModelYearId;
    }

    @computed public get current() {
        return this.data.current;
    }

    @computed public get name() {
        return this.data.name;
    }

    @computed public get startDate() {
        return this.data.startDate;
    }

    @computed public get endDate() {
        return this.data.endDate;
    }

    @computed public get archived() {
        return this.data.archived;
    }

    public modelYearId: string;
    private nav: INavigation;

    public constructor(nav: INavigation, modelYearId: string) {
        this.nav = nav;
        this.modelYearId = modelYearId;
    }

    @action public select() {
        this.nav.setSelectedModelYearId(this.modelYearId);
    }
}
