import { component } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import Divider from '@material-ui/core/Divider';
import * as React from 'react';
import { /* ButtonSnippet,  */CheckboxSnippet, TypographySnippet } from './snippets';

export const ThemePreview = component('ThemePreview')
    .use(mui)
    .use(theme)
    .style(({ theme }) => ({
        Divider: {
            root: {
                '&&': {
                    margin: `${theme ? theme.spacing(4) : 0}px 0px`
                }
            }
        }
    }))
    .render(({ $ }) => {
        const $Divider = $(Divider, 'Divider');

        return (
            <>
                <TypographySnippet />
                <$Divider />
                {/* <ButtonSnippet /> */}
                <$Divider />
                <CheckboxSnippet />
            </>
        );
    })
    .compile();
