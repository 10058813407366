import { computed } from 'mobx';
import { IApplication, ISubdomain, ISubdomainData } from '../interfaces';

export class Subdomain implements ISubdomain {
    @computed private get data(): ISubdomainData {
        return this.application.subdomainData.find((data) => {
            return data.id === this.id;
        })!;
    }

    @computed public get pathnames() {
        return this.data.pathnames;
    }

    @computed public get title() {
        return this.data.title;
    }

    @computed public get tag() {
        return this.data.tag;
    }

    @computed public get source() {
        return this.data.source;
    }

    public id: string;
    private application: IApplication;

    public constructor(application: IApplication, id: string) {
        this.application = application;
        this.id = id;
    }

    public getPathname = () => {
        return this.pathnames[0] || '/';
    }
}
