import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

/* const vanillaGrey = {
    light: grey[100],
    main: grey[300],
    dark: grey[500]
}; */

const primaryColor = {
    main: 'rgb(253, 70, 131)'
};

const secondaryColor = {
    light: 'rgb(236, 250, 236)',
    main: 'rgb(45, 181, 48)',
    contrastText: '#fff'
};

export const CHAZ_MAGASIN: ThemeOptions = {
    palette: {
        primary: primaryColor,
        secondary: secondaryColor
    },
    spacing: 4
};
