import { component, types } from '@lardy/core';
import { mui } from '@lardy/mui';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import * as React from 'react';
import { IMenuItem } from '../../interfaces';
import { IMenuIcons, Menu } from '../menu';

export const renderIcon = (icon: string, icons: IMenuIcons = {}) => {
    const ItemIcon = icons[icon];

    if (ItemIcon) {
        return (
            <ItemIcon fontSize="small" />
        );
    } else {
        return (
            <Icon fontSize="small">
                {icon}
            </Icon>
        );
    }
};

export const MenuItem = component('MenuItem')
    .use(mui)
    .inject<IMenuIcons, 'icons'>('icons', 'icons')
    .props({
        data: types.complex<IMenuItem>()
    })
    .style(() => ({
        ListItemText: {
            primary: {
                '&&': {
                    fontSize: '0.75rem'
                }
            }
        }
    }))
    .state({
        menuItemElement: types.complex<HTMLElement | null>().withDefault(null)
    }, {
        setMenuItemElement: (props) => (menuItemElement: HTMLElement | null) => ({
            ...props,
            menuItemElement
        })
    })
    .render(({ $, data, icons, menuItemElement, setMenuItemElement }) => {
        const $ListItemText = $(ListItemText, 'ListItemText');

        return (
            <>
                <ListItem
                    button
                    onClick={data.click}
                    onMouseEnter={data.focus}
                    onMouseOut={data.blur}
                    innerRef={setMenuItemElement}
                >
                    {
                        data.icon
                        ? (
                            <ListItemIcon>
                                {renderIcon(data.icon, icons)}
                            </ListItemIcon>
                        )
                        : null
                    }
                    <$ListItemText primary={data.label} />
                    {
                        data.type === 'expander'
                        ? (
                            <ListItemSecondaryAction>
                                {renderIcon('keyboard_arrow_right', icons)}
                            </ListItemSecondaryAction>
                        )
                        : null
                    }
                </ListItem>
                {
                    data.isExpanded && data.children.length !== 0
                    ? (
                        <Menu
                            anchorEl={menuItemElement}
                            items={data.children}
                            onClose={data.blur}
                            placement="left-start"
                            icons={icons}
                        />
                    )
                    : null
                }
            </>
        );
    })
    .compile();
