import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Omit } from '@material-ui/types';
import * as EventEmitter from 'eventemitter3';
import * as Fuse from 'fuse.js';
import { ComponentType } from 'react';
import request = require('request');

export interface ITransport {
    send<ResponseType>(query: string, variables?: any, headers?: request.Headers): Promise<ResponseType>;
}

export interface IDataService {
    getAccount(): Promise<IAccountData>;
    getApplications(): Promise<TApplicationData[]>;
    getModelYears(): Promise<IModelYearData[]>;
    getCompanyTree(): Promise<ICompanyNodeData[]>;
    getXSearchResult(search: string, tags: string[], companyId?: string, modelYearId?: string): Promise<IXSearchResult>;

    markApplicationAsFavorite(id: string): Promise<void>;
    unmarkApplicationAsFavorite(id: string): Promise<void>;
    setPassword(password: string): Promise<void>;
}

export interface INavigation {
    account?: IAccount;
    applications: IApplication[];
    favorites: IApplication[];
    filteredApplications: IApplication[];
    modelYears: IModelYear[];
    companies: ICompanyNode[];
    companyTree: ICompanyNode[];
    auth: IAuthService;
    service: IDataService;
    accountCompanies: ICompanyNode[];
    accountCompanyTree: ICompanyNode[];

    applicationsData: TApplicationData[];
    modelYearsData: IModelYearData[];
    companyTreeData: ICompanyNodeData[];
    companyTreeFullData: ICompanyNodeData[];

    applicationSearch: string;
    selectedModelYearId?: string;
    selectedCompanyId?: string;
    selectedCompany?: ICompanyNode;
    topLevelCompany?: ICompanyNode;

    navbarHeight: number;

    searchEngine: Fuse<IApplication, Fuse.FuseOptions<IApplication>>;

    xSearchEntities?: XSearchResultEntity[];
    xSearchQuery?: IXSearchQuery;
    xSearchResult?: IXSearchResult;

    getApplicationByTag(tag: string): IApplication;
    getUrl(applicationTag: string, args?: IApplicationOpenArgs): string | void;
    navigate(applicationTag: string, args?: IApplicationOpenArgs): void;

    loadAccount(): Promise<void>;
    loadApplications(): Promise<void>;
    loadModelYears(): Promise<void>;
    loadCompanyTree(): Promise<void>;
    loadXSearchResults(): Promise<void>;

    markApplicationAsFavorite(id: string): Promise<void>;
    unmarkApplicationAsFavorite(id: string): Promise<void>;

    clearXSearch(): void;
    setApplicationSearch(search: string): void;
    setSelectedModelYearId(modelYearId: string): void;
    setSelectedCompanyId(companyId: string | undefined): void;
    setXSearch(query: IXSearchQuery): void;

    getUIState<T>(role: string, defaultValue: T): IUIControl<T>;
    setNavbarHeight(height: number): void;

    getContextComponent(): ComponentType;
    resume(): Promise<void>;

    bootstrap(): Promise<void>;
    mount(): Promise<void>;
}

export interface IAccountData {
    userId: string;
    name: string;
    emailAddress: string;
    role: IRoleData;
    companies: ICompanyNodeData[];
    active: boolean;
    dailyDigest: boolean;
    confirmed: boolean;
    lastLoginDate: string;
    reminderDate: string;
}

export interface IAccount extends IAccountData {
    isAdmin: boolean;
    avatar: string;
    logout(): void;
    setPassword(password: string): void;
    gotoSetPasswordPage(): void;
}

export interface IRoleData {
    roleId: string;
    name: string;
    permissions: string[];
    profileCategories: IProfileCategoryData[];
    external: boolean;
    rootCompanyOnly: boolean;
    system: boolean;
}

export interface IProfileCategoryData {
    profileCategoryId: string;
}

export type TApplicationData = Omit<IApplicationServerData, 'subdomains'> & {
    subdomainData: ISubdomainData[];
};

export interface IApplicationServerData {
    id: string;
    title: string;
    tag: string;
    logo: string;
    hostname: string;
    protocol: string;
    devServerPort?: string;
    subdomains: ISubdomainData[];
    favorite: boolean;
    visible: boolean;
    theme: IApplicationTheme;
}

export interface IApplicationTheme {
    primaryColor: string;
    secondaryColor: string;
}

export interface IApplication extends TApplicationData {
    subdomains: ISubdomainData[];
    open(args?: IApplicationOpenArgs): void;
    markAsFavorite(): void;
    unmarkAsFavorite(): void;
    getUrl(args?: IApplicationOpenArgs): string;
}

export interface IApplicationOpenArgs {
    path?: string;
    subdomainTag?: string;
}

export interface IModelYearData {
    modelYearId: string;
    current: boolean;
    name: string;
    startDate: string;
    endDate: string;
    archived: boolean;
}

export interface IModelYear extends IModelYearData {
    selected: boolean;
    select(): void;
}

export interface ICompanyNodeData {
    companyId: string | null;
    companyNumber: string | null;
    parentCompanyId: string | null;
    name: string | null;
    branchCode: string | null;
    branchCodeAliases: string[] | null;
    localisationId: string | null;
    authId: number | null;
    afPassword: string | null;
    info: any;
    ppnm__bulkImportUrl: string;
    ppnm__bulkExportUrl: string;
}

export interface ICompanyNode extends ICompanyNodeData {
    children: ICompanyNode[];
    isRoot: boolean;
    selected: boolean;
    parent?: ICompanyNode;
    select(): void;
}

export interface IError {
    message: string;
    added: Date;
    id: string;
}

export interface IErrorHandler {
    visibleErrors: IError[];
    addError(message: string): void;
    deleteError(id: string): void;
}

export interface IUIControl<T> {
    role: string;
    state: T;
    setState(state: T): void;
}

export interface IAbstractMenuItemData {
    type: 'action' | 'expander' | 'modal';
    id: string;
    label: string;
    icon?: string;
    iconColor?: string;
    shortcut?: IMenuItemShortCut;
    parentId?: string;
    active?: boolean;
}

export interface IMenuItemShortCut {
    modifier1?: number;
    modifier2?: number;
    modifier3?: number;
    keyCode: number;
}

export interface IActionMenuItemData extends IAbstractMenuItemData {
    type: 'action';
}

export interface IExpanderMenuItemData extends IAbstractMenuItemData {
    type: 'expander';
}

export interface IModalMenuItemData extends IAbstractMenuItemData {
    type: 'modal';
}

export type IMenuItemData = IActionMenuItemData | IExpanderMenuItemData | IModalMenuItemData;

export interface IMenuItem extends IAbstractMenuItemData {
    children: IMenuItem[];
    isExpanded: boolean;
    click(): void;
    focus(): void;
    blur(): void;
}

export interface IMenuData {
    id: string;
    title: string;
    menuItemData: IMenuItemData[];
}

export interface IMenu extends IMenuData {
    menuItems: IMenuItem[];
    menuTree: IMenuItem[];
    controller: EventEmitter<MenuEvent>;
    expandedMenuItemIds: string[];
    hoveringMenuItemId?: string;
    setExpandedMenuItemIds(ids: string[]): void;
    setHoveringMenuItemId(id: string): void;
    setMenuItemModalActive(id: string, active: boolean): void;
    closeAll(): void;
    getRootComponent(): ComponentType<any>;
}

export interface IAppMenuProps {
    icons?: {
        [index: string]: React.ComponentType<SvgIconProps>;
    };
}

export interface ISubdomainData {
    id: string;
    title: string;
    tag: string;
    pathnames: string[];
    source: string;
}

export interface ISubdomain extends ISubdomainData {
    getPathname(): string;
}

export interface IXSearchResult {
    count: number;
    pageNumber: number;
    list: IXSearchResultItem[];
    types: IXSearchResultTypeAggregation[];
}

export interface IXSearchQuery {
    search: string;
    tags: string[];
    companyId?: string;
    modelYearId?: string;
}

export interface IXSearchResultItem {
    applicationId: string;
    icon?: string;
    ingress?: string;
    path?: string;
    subdomainTag?: string;
    thumb?: string;
    title: string;
    type: string;
}

export interface IXSearchResultTypeAggregation {
    count: number;
    code: string;
    label: string;
    theme: IApplicationTheme;
}

export interface IXSearchResultDivider {
    label: string;
    count: number;
    theme: IApplicationTheme;
}

export interface IXSearchResultDividerEntity {
    type: 'divider';
    data: IXSearchResultDivider;
}

export interface IXSearchResultItemEntity {
    type: 'item';
    data: IXSearchResultItem;
}

export type XSearchResultEntity = IXSearchResultDividerEntity | IXSearchResultItemEntity;

export function isXSearchResultItem(entity: XSearchResultEntity): entity is IXSearchResultItemEntity {
    return entity.type === 'item';
}

export function isXSearchResultDivider(entity: XSearchResultEntity): entity is IXSearchResultDividerEntity {
    return entity.type === 'divider';
}

export type ColorSet = 'inherit'| 'disabled' | 'error' | 'action' | 'primary' | 'secondary';
export type ExtendedColorSet = ColorSet | 'paper' | 'background';

export type TintSet = 'dark' | 'main' | 'light';

export type FontSizeSet = 'inherit' | 'small' | 'default' | 'large';

export type MenuEvent = 'menuItemActionClicked' | 'menuItemExpanded' | 'menuItemCollapsed' | 'menuItemModalSet';

export interface IAuthService {
    login(): void;
    resume(): void;
    handleAuthentication(): Promise<boolean>;
    isAuthenticated(): boolean;
    logout(): void;
}

export interface IAuthOptions {
    domain: string;
    audience: string;
    clientId: string;
    authUri: string;
    logoutUri: string;
}
