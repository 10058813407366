import {
    IAccountData, IApplicationServerData,
    ICompanyNodeData, IDataService, IModelYearData, ITransport, IXSearchResult, TApplicationData
} from '../interfaces';

export class DataService implements IDataService {
    private transport: ITransport;

    public constructor(transport: ITransport) {
        this.transport = transport;
    }

    public async getAccount(): Promise<IAccountData> {
        return (await this.transport.send<{
            account: IAccountData
        }>(`
            query{
                account{
                    userId
                    emailAddress
                    name
                    role{
                        roleId
                        name
                        permissions
                        profileCategories{
                        profileCategoryId
                        }
                        external
                        rootCompanyOnly
                        system
                    }
                    companies{
                        companyId
                        companyNumber
                        parentCompanyId
                        name
                        branchCode
                        branchCodeAliases
                        localisationId
                        authId
                        afPassword
                        info
                        ppnm__bulkImportUrl
                        ppnm__bulkExportUrl
                    }
                    active
                    dailyDigest
                    confirmed
                    lastLoginDate
                    reminderDate
                }
            }
        `, {})).account;
    }

    public async getApplications(): Promise<TApplicationData[]> {
        const result = await this.transport.send<{
            applications: IApplicationServerData[]
        }>(`
            query{
                applications {
                    id
                    title
                    tag
                    logo
                    hostname
                    protocol
                    devServerPort
                    subdomains {
                        id
                        title
                        tag
                        pathnames
                        source
                    }
                    favorite
                    visible
                    theme {
                        primaryColor
                        secondaryColor
                    }
                }
            }
        `);

        return result.applications.map(({ subdomains, ...other }) => ({
            ...other,
            subdomainData: subdomains
        }));
    }

    public async getCompanyTree(): Promise<ICompanyNodeData[]> {
        return (await this.transport.send<{
            companies: ICompanyNodeData[]
        }>(`
            query{
                companies{
                    companyId
                    companyNumber
                    parentCompanyId
                    name
                    branchCode
                    branchCodeAliases
                    localisationId
                    authId
                    afPassword
                    info
                    ppnm__bulkImportUrl
                    ppnm__bulkExportUrl
                }
            }
        `, {})).companies;
    }

    public async getModelYears(): Promise<IModelYearData[]> {
        return (await this.transport.send<{
            modelYears: IModelYearData[]
        }>(`
            query{
                modelYears{
                    modelYearId
                    name
                    startDate
                    endDate
                    current
                    archived
                }
            }
        `, {})).modelYears;
    }

    public getXSearchResult(
        search: string,
        tags: string[],
        companyId?: string,
        modelYearId?: string
    ): Promise<IXSearchResult> {
        return Promise.resolve({
            count: 4,
            pageNumber: 0,
            types: [
                {
                    count: 10,
                    code: 'ppnm/subscribers',
                    label: 'Prenumeranter',
                    theme: {
                        primaryColor: 'rgba(36, 93, 242, 1)',
                        secondaryColor: 'rgba(251, 212, 78, 1)'
                    }
                },
                {
                    count: 5,
                    code: 'ppnm/mailingLists',
                    label: 'Utskickslistor',
                    theme: {
                        primaryColor: 'rgba(36, 93, 242, 1)',
                        secondaryColor: 'rgba(251, 212, 78, 1)'
                    }
                },
                {
                    count: 22,
                    code: 'dms/offer',
                    label: 'Budskap',
                    theme: {
                        primaryColor: 'rgb(21, 101, 192)',
                        secondaryColor: '#43a047'
                    }
                }
            ],
            list: [
                {
                    applicationId: '5e383309de15501226d39d28',
                    icon: 'Person',
                    ingress: 'maks.bolotin+2@gmail.com',
                    path: '/subscriber/5e302eeaf1ac57222031341b',
                    title: 'Adam Brunsson',
                    type: 'ppnm/subscribers'
                },
                {
                    applicationId: '5e383309de15501226d39d28',
                    icon: 'Book',
                    ingress: 'Beskrivning',
                    path: '/mailingList/5e302b9862289b14fdfba247',
                    title: 'Min lista',
                    type: 'ppnm/mailingLists'
                },
                {
                    applicationId: '5e383309de15501226d39d28',
                    icon: 'Book',
                    ingress: 'Lista av ngt slag',
                    path: '/mailingList/5e302b9862289b14fdfba247',
                    title: 'Ny lista',
                    type: 'ppnm/mailingLists'
                },
                {
                    applicationId: '5e3837b1de15501226d39d2a',
                    thumb: 'https://static.fbinhouse.se/2020-1/loungepaket_4096x2304_SUP00079.jpg',
                    ingress: 'Gör ditt resande mer bekvämt. Med ett loungepaket får du '
                        + '360 graders parkeringskamera och...',
                    path: '/offer/ffcf142b-fc69-480f-8721-806ecee2a504',
                    title: 'Loungepaket XC40 RDes MY21 - endast P8 EL',
                    type: 'dms/offer'
                }
            ]
        });
    }

    public async markApplicationAsFavorite(id: string) {
        await this.transport.send(`
            mutation(
                $application: String!
            ) {
                markApplicationAsFavorite(
                    application: $application
                )
            }
        `, {
                application: id
            });

        /* return this.transport.send<void>(`
            query {
                ...
            }
        `, {}); */
    }

    public async unmarkApplicationAsFavorite(id: string) {
        await this.transport.send(`
            mutation(
                $application: String!
            ) {
                unmarkApplicationAsFavorite(
                    application: $application
                )
            }
        `, {
                application: id
            });
    }

    public setPassword(password: string) {
        return Promise.resolve();
    }
}
