import { component, types } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import * as rectserver from '@lardy/rectserver';
import AppBar from '@material-ui/core/AppBar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import * as React from 'react';
import { INavigation } from '../../interfaces';

export const NavBar = component('NavBar')
    .use(mui)
    .use(theme)
    .use(rectserver.bindElement)
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .props({
        contractPush: types.maybe(types.str),
        contractWidth: types.maybe(types.num.withDefault(0)),
        contractDirection: types.maybe(types.complex<'left' | 'right'>()),
        inverted: types.maybe(types.bool.withDefault(false))
    })
    .provide<'inverted'>('inverted', 'inverted')
    .reduce(({ contractPush, contractWidth, navigation, ...props }) => {
        let contracted = false;

        if (contractPush) {
            contracted = navigation.getUIState<boolean>(contractPush, false).state;
        }

        return {
            ...props,
            contractWidth: contracted ? (contractWidth || 0) : 0
        };
    })
    .style(({ contractDirection, contractWidth, inverted  }) => ({
        AppBar: {
            root: {
                '&&': {
                    backgroundColor: inverted ? undefined : '#fff !important',
                    width: `calc(100% - ${contractWidth}px)`,
                    [contractDirection === 'right' ? 'marginRight' : 'marginLeft']: contractWidth,
                    transform: 'translate3d(0,0,0)'
                }
            }
        }
    }))
    .reduce(({ contractWidth, ...props }) => props)
    .render(({ $, bindElement, children }) => {
        const $AppBar = $(AppBar, 'AppBar');

        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 0
        });

        return (
            <$AppBar elevation={trigger ? 4 : 0} position="fixed" color="primary">
                <span
                    ref={(element: HTMLElement | null) => {
                        if (element === null) {
                            return;
                        }

                        bindElement(element, '__navbar');
                    }}
                >
                    {children}
                </span>
            </$AppBar>
        );
    })
    .compile();
