import { component, types } from '@lardy/core';
import IconButton from '@material-ui/core/IconButton';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';
import { UIIcon } from '../icon';
import { Popover } from '../popover';

export const PopupMenu = component('PopupMenu')
    .props({
        Icon: types.complex<React.ComponentType<SvgIconProps>>(),
        onOpen: types.maybe(types.fn()),
        onClose: types.maybe(types.fn()),
        id: types.str,
        color: types.maybe(
            types.complex<'inherit' | 'disabled' | 'error' | 'action' | 'primary' | 'secondary'>()
                .withDefault('primary')
        ),
        tint: types.maybe(
            types.complex<'dark' | 'main' | 'light'>()
                .withDefault('main')
        )
    })
    .state({
        anchorEl: types.complex<HTMLDivElement | undefined>().withDefault(undefined),
        open: types.bool.withDefault(false)
    }, {
        setAnchorEl: (state) => (anchorEl: HTMLDivElement | null) => ({
            ...state,
            anchorEl: anchorEl || undefined
        }),
        setOpen: (state) => (open: boolean) => ({
            ...state,
            open
        })
    })
    .reduce(({ anchorEl, setAnchorEl, open, onClose, onOpen, setOpen, ...props }) => ({
        ...props,
        anchorEl: open ? anchorEl : undefined,
        onClosePopover: () => {
            if (!open) {
                return;
            }

            if (onClose) {
                onClose();
            }
            setOpen(false);
        },
        onTogglePopover: () => {
            if (open && onClose) {
                onClose();
            }

            if (!open && onOpen) {
                onOpen();
            }

            setOpen(!open);
        },
        setAnchorEl: (ref: HTMLDivElement | null) => {
            if (ref !== null && anchorEl !== ref) {
                setAnchorEl(ref);
            }
        }
    }))
    .render(({ Icon, children, setAnchorEl, anchorEl, onTogglePopover, onClosePopover, color, id, tint }) => {
        return (
            <>
                <span ref={setAnchorEl} className={`chaz-global---ignore-clickoutside-popupMenu-${id}`}>
                    <IconButton
                        onClick={onTogglePopover}
                    >
                        <UIIcon
                            Icon={Icon}
                            color={color}
                            tint={tint}
                            fontSize="large"
                        />
                    </IconButton>
                </span>
                <Popover
                    placement="bottom"
                    anchorEl={anchorEl}
                    onClose={onClosePopover}
                    id={id}
                >
                    {children}
                </Popover>
            </>
        );
    })
    .compile();
