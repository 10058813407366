import * as Fuse from 'fuse.js';
import { action, autorun, computed, observable, runInAction, toJS } from 'mobx';
import { ComponentType } from 'react';
import * as store from 'store';
import { IAccount, IAccountData, IApplication, IApplicationOpenArgs, IAuthService, ICompanyNode,
    ICompanyNodeData, IDataService, IErrorHandler, IModelYear, IModelYearData, INavigation, IUIControl,
    IXSearchQuery, IXSearchResult, TApplicationData, XSearchResultEntity } from '../interfaces';
import { getNavigationContextWithNavigation } from '../views/decorators';
import { Account } from './account';
import { Application } from './application';
import { CompanyNode } from './companyNode';
import { ErrorHandler } from './errorHandler';
import { ModelYear } from './modelYear';
import { UIControl } from './uiState';

export class Navigation implements INavigation {
    public static fuseOptions = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
            'title'
        ]
    };

    @computed public get searchEngine() {
        return new Fuse(this.applications, Navigation.fuseOptions);
    }

    @computed public get account(): IAccount | undefined {
        if (this.accountData === undefined) {
            return this.accountData;
        }

        return new Account(this, this.accountData);
    }

    @computed public get applications(): IApplication[] {
        return this.applicationsData.map((data) => {
            return new Application(this, data.id);
        });
    }

    @computed public get favorites(): IApplication[] {
        return this.applications.filter((application) => {
            return application.favorite;
        });
    }

    @computed public get filteredApplications(): IApplication[] {
        let results = this.applications;

        if (this.applicationSearch) {
            results = this.searchEngine.search(this.applicationSearch);
        }

        return results.sort((a, b) => {
            if (a.favorite === b.favorite) {
                if (a.title === b.title) {
                    return 0;
                }

                return a.title > b.title ? 1 : -1;
            }
            return a.favorite > b.favorite ? -1 : 1;
        });
    }

    @computed public get modelYears(): IModelYear[] {
        return this.modelYearsData.map((data) => {
            return new ModelYear(this, data.modelYearId);
        });
    }

    @computed public get companyTreeFullData() {
        return [
            ...(
                (this.account && this.account.isAdmin)
                    ? [
                        {
                            companyId: null,
                            companyNumber: null,
                            parentCompanyId: null,
                            name: null,
                            branchCode: null,
                            branchCodeAliases: null,
                            localisationId: null,
                            authId: null,
                            afPassword: null,
                            info: null,
                            ppnm__bulkImportUrl: '',
                            ppnm__bulkExportUrl: ''
                        }
                    ]
                    : []
            ),
            ...toJS(this.companyTreeData)
        ];
    }

    @computed public get companies(): ICompanyNode[] {
        return this.companyTreeFullData.map((data) => {
            return new CompanyNode(this, data.companyId, false);
        });
    }

    @computed public get accountCompanies(): ICompanyNode[] {
        return this.companyTreeFullData.map((data) => {
            return new CompanyNode(this, data.companyId, true);
        });
    }

    @computed public get companyTree(): ICompanyNode[] {
        return this.companies.filter((company) => {
            return company.parentCompanyId === null;
        });
    }

    @computed public get accountCompanyTree(): ICompanyNode[] {
        if (!this.account) {
            return [];
        }

        if (this.account.isAdmin) {
            return this.companyTree;
        }

        return this.accountCompanies.filter((company) => {
            return company.parentCompanyId === null
                && this.account!.companies.map((c) => c.companyId).indexOf(company.companyId) !== -1;
        });
    }

    @computed public get selectedCompany(): ICompanyNode | undefined {
        return this.accountCompanies.find((company) => {
            return company.companyId === this.selectedCompanyId;
        });
    }

    @computed public get topLevelCompany(): ICompanyNode | undefined {
        if (this.selectedCompany && this.selectedCompany.parent !== undefined) {
            return this.selectedCompany.parent;
        }

        return this.selectedCompany;
    }

    @computed public get xSearchEntities(): XSearchResultEntity[] | undefined {
        if (!this.xSearchResult) {
            return undefined;
        }

        return this.xSearchResult.types.reduce((results, { code, ...data }) => ([
            ...results,
            {
                type: 'divider' as 'divider',
                data
            },
            ...this.xSearchResult!.list.filter((item) => item.type === code).map((item) => ({
                type: 'item' as 'item',
                data: item
            }))
        ]), [] as XSearchResultEntity[]);
    }

    public auth: IAuthService;
    public service: IDataService;
    public errorHandler: IErrorHandler = new ErrorHandler();

    @observable public applicationsData: TApplicationData[] = [];
    @observable public modelYearsData: IModelYearData[] = [];
    @observable public companyTreeData: ICompanyNodeData[] = [];

    @observable public selectedModelYearId?: string;
    @observable public selectedCompanyId?: string;
    @observable public applicationSearch: string = '';
    @observable public xSearchQuery?: IXSearchQuery;

    @observable public baseUrl: string = '';

    @observable public navbarHeight: number = 0;
    @observable public xSearchResult?: IXSearchResult;

    @observable private accountData?: IAccountData;
    @observable private uiState: { [index: string]: IUIControl<any> } = {};

    public constructor(
        dataService: IDataService,
        authService: IAuthService
    ) {
        this.auth = authService;
        this.service = dataService;

        this.resume();

        autorun(() => {
            store.set('selectedCompanyId', this.selectedCompanyId);
            store.set('selectedModelYearId', this.selectedModelYearId);
        });
    }

    public bootstrap = async () => {
        await this.auth.resume();
        await this.loadAccount();
        await this.loadApplications();
        await this.loadCompanyTree();
    }

    public mount = async () => {
        await this.resume();
    }

    public async resume() {
        this.selectedCompanyId = store.get('selectedCompanyId', undefined);
        this.selectedModelYearId = store.get('selectedModelYearId', undefined);
    }

    public getApplicationByTag = (tag: string) => {
        const application = this.applications.find((application) => {
            return application.tag === tag;
        });

        if (application === undefined) {
            throw new Error('Application not found: ' + tag);
        }

        return application;
    }

    public getContextComponent = (): ComponentType => {
        return getNavigationContextWithNavigation(this);
    }

    public getUrl = (applicationTag: string, args: IApplicationOpenArgs = {}): string | void => {
        let application: IApplication;

        try {
            application = this.getApplicationByTag(applicationTag);
        } catch {
            return;
        }

        return application.getUrl(args);
    }

    public getUIState = <T>(role: string, defaultValue: T) => {
        if (this.uiState[role] === undefined) {
            this.uiState[role] = new UIControl<T>(role, defaultValue);
        }
        return this.uiState[role];
    }

    @action public clearXSearch = () => {
        this.xSearchQuery = undefined;
        this.xSearchResult = undefined;
    }

    @action public setApplicationSearch = (search: string) => {
        this.applicationSearch = search;
    }

    @action public setBaseUrl = (baseUrl: string) => {
        this.baseUrl = baseUrl;
    }

    @action public setSelectedCompanyId = (companyId: string | undefined) => {
        this.selectedCompanyId = companyId;
    }

    @action public setSelectedModelYearId = (modelYearId: string) => {
        this.selectedModelYearId = modelYearId;
    }

    @action public setNavbarHeight = (height: number) => {
        this.navbarHeight = height;
    }

    @action public setXSearch = (query: IXSearchQuery) => {
        if (!query.search) {
            this.xSearchQuery = undefined;
            return;
        }

        this.xSearchQuery = query;
    }

    public async markApplicationAsFavorite(id: string) {
        const app = this.applicationsData.find((app) => {
            return app.id === id;
        });

        if (!app) {
            throw new Error('Could not find application with id ' + id);
        }

        runInAction('markApplicationAsFavorite', () => {
            app.favorite = true;
        });

        try {
            await this.service.markApplicationAsFavorite(id);
        } catch {
            runInAction('markApplicationAsFavorite', () => {
                app.favorite = false;
            });

            this.errorHandler.addError('Unknown error: Could not mark application as favorite');
        }
    }

    public async unmarkApplicationAsFavorite(id: string) {
        const app = this.applicationsData.find((app) => {
            return app.id === id;
        });

        if (!app) {
            throw new Error('Could not find application with id ' + id);
        }

        runInAction('unmarkApplicationAsFavorite', () => {
            app.favorite = false;
        });

        try {
            await this.service.unmarkApplicationAsFavorite(id);
        } catch {
            runInAction('unmarkApplicationAsFavorite', () => {
                app.favorite = true;
            });

            this.errorHandler.addError('Unknown error: Could not unmark application as favorite');
        }
    }

    public async loadAccount() {
        try {
            const result = await this.service.getAccount();

            runInAction('loadAccount', () => {
                this.accountData = result;

                if (this.selectedCompanyId === undefined) {
                    if (this.accountData.companies.length > 0) {
                        this.selectedCompanyId = this.accountData.companies[0].companyId || undefined;
                    }
                }
            });
        } catch (err) {
            throw new Error('Error loading account');
        }
    }

    public async loadApplications() {
        try {
            const result = await this.service.getApplications();

            runInAction('loadApplications', () => {
                this.applicationsData = result;
            });
        } catch (err) {
            throw new Error('Error loading applications');
        }
    }

    public async loadCompanyTree() {
        try {
            const result = await this.service.getCompanyTree();

            runInAction('loadCompanyTree', () => {
                this.companyTreeData = result;
            });
        } catch (err) {
            throw new Error('Error loading company tree');
        }
    }

    public async loadModelYears() {
        try {
            const result = await this.service.getModelYears();

            runInAction('loadModelYears', () => {
                this.modelYearsData = result;

                if (this.selectedModelYearId === undefined) {
                    this.setSelectedModelYearId(this.modelYearsData[0].modelYearId);
                }
            });
        } catch (err) {
            throw new Error('Error loading model years');
        }
    }

    public async loadXSearchResults() {
        if (this.xSearchQuery === undefined) {
            this.xSearchResult = undefined;
            return;
        }

        try {
            const {
                search,
                tags,
                companyId,
                modelYearId
            } = this.xSearchQuery;

            const result = await this.service.getXSearchResult(
                search,
                tags,
                companyId,
                modelYearId
            );

            runInAction('loadXSearchResults', () => {
                this.xSearchResult = result;
            });
        } catch (err) {
            throw new Error('Error loading X-Search results');
        }
    }

    public navigate = (applicationTag: string, args: IApplicationOpenArgs = {}) => {
        const application = this.applications.find((application) => {
            return application.tag === applicationTag;
        });

        if (application === undefined) {
            throw new Error('Application not found: ' + applicationTag);
        }

        application.open(args);
    }
}
