import { component, types } from '@lardy/core';
import { mui } from '@lardy/mui';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';
import { onClickOutside } from '../../decorators/onClickOutside';
import { IMenuItem } from '../../interfaces';
import { MenuItem } from '../menuItem';

export interface IMenuIcons {
    [index: string]: React.ComponentType<SvgIconProps>;
}

export const Menu = component('Menu')
    .use(mui)
    .props({
        anchorEl: types.complex<HTMLElement | null>(),
        items: types.complex<IMenuItem[]>(),
        onClose: types.fn<() => void>(),
        placement: types.complex<'left-start' | 'bottom-start' | 'bottom-end' | 'bottom'>(),
        icons: types.maybe(types.complex<IMenuIcons>().withDefault({}))
    })
    .provide('icons', 'icons')
    .decorate(onClickOutside(({ onClose }) => {
        onClose();
    }, 'chaz-global---ignore-menu-clickoutside'))
    .style(() => ({
        List: {
            root: {
                '&&': {
                }
            }
        },
        Popper: {
            zIndex: 1100
        }
    }))
    .render(({ $, anchorEl, placement, onClose, items }) => {
        const $Popper = $(Popper, 'Popper');

        return (
            <$Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement={placement}
                disablePortal={false}
                modifiers={{
                    flip: {
                        enabled: true
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent'
                    }
                }}
                transition
            >
                <Paper>
                    <List
                        component="nav"
                        dense={true}
                    >
                    {
                        items.map((menuItem) => (
                            <MenuItem key={menuItem.id} data={menuItem} />
                        ))
                    }
                    </List>
                </Paper>
            </$Popper>
        );
    })
    .compile();
