import { component } from '@lardy/core';
import { mui } from '@lardy/mui';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import * as React from 'react';
import { INavigation } from '../../../interfaces';

export const AccountMenuUsername = component('AccountMenuUsername')
    .use(mui)
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .style(() => ({
        Container: {
            root: {
                '&&': {
                    maxWidth: 300,
                    margin: '0px auto',
                    textAlign: 'center' as 'center'
                }
            }
        },
        Skeleton: {
            root: {
                '&&': {
                    margin: '0'
                }
            }
        },
        Typography: {
            root: {
                '&&': {
                    fontSize: 18,
                    fontWeight: 100,
                    margin: '0'
                }
            }
        }
    }))
    .render(({ $, navigation }) => {
        const $Skeleton = $(Skeleton, 'Skeleton');
        const $Typography = $(Typography, 'Typography');

        return (
            <$.div.Container>
                {
                    navigation.account
                    ? (
                        <$Typography variant="h4">
                            {navigation.account.name}
                        </$Typography>
                    )
                    : (
                        <$Skeleton height={30} variant="text" />
                    )
                }
            </$.div.Container>
        );
    })
    .compile();
