import { component, types } from '@lardy/core';
import IconButton from '@material-ui/core/IconButton';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';
import { INavigation } from '../../interfaces';
import { UIIcon } from '../icon';

export const Toggle = component('Toggle')
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .props({
        Icon: types.complex<React.ComponentType<SvgIconProps>>(),
        push: types.str,
        color: types.maybe(
            types.complex<'inherit' | 'disabled' | 'error' | 'action' | 'primary' | 'secondary'>()
                .withDefault('primary')
        ),
        tint: types.maybe(
            types.complex<'dark' | 'main' | 'light'>()
                .withDefault('main')
        ),
        className: types.maybe(types.str)
    })
    .reduce(({ navigation, push, ...props }) => {
        const uiState = navigation.getUIState<boolean>(push, false);

        const onToggle = () => {
            uiState.setState(!uiState.state);
        };

        return {
            ...props,
            onToggle
        };
    })
    .render(({ Icon, className, onToggle, color, tint }) => {
        return (
            <IconButton
                onClick={onToggle}
                className={className}
            >
                <UIIcon
                    fontSize="large"
                    Icon={Icon}
                    color={color}
                    tint={tint}
                />
            </IconButton>
        );
    })
    .compile();
