import { component } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import * as React from 'react';
import { INavigation } from '../../../interfaces';

export const AccountMenuAvatar = component('AccountMenuAvatar')
    .use(mui)
    .use(theme)
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .style(({ theme }) => ({
        Avatar: {
            root: {
                '&&': {
                    background: theme !== undefined ? theme.palette.grey[300] : '#fff',
                    width: 90,
                    height: 90,
                    margin: '20px auto'
                }
            }
        },
        Skeleton: {
            root: {
                '&&': {
                    margin: '20px auto'
                }
            }
        }
    }))
    .render(({ $, navigation }) => {
        const $Avatar = $(Avatar, 'Avatar');
        const $Skeleton = $(Skeleton, 'Skeleton');
        return (
            navigation.account
            ? (
                <$Avatar src={navigation.account.avatar} />
            )
            : (
                <$Skeleton width={90} height={90} variant="circle" />
            )
        );
    })
    .compile();
