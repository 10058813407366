import { component, types } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import * as React from 'react';

export const CheckboxSnippet = component('CheckboxSnippet')
    .use(mui)
    .use(theme)
    .style(({ theme }) => ({
        GreenCheckbox: {
            root: {
                '&&': {
                    'color': green[400],
                    '&$checked': {
                        color: green[600]
                    }
                }
            },
            checked: {}
        },
        Paper: {
            root: {
                '&&': {
                    padding: theme ? theme.spacing(2) : 0
                }
            }
        }
    }))
    .state({
        checkedA: types.bool.withDefault(true),
        checkedB: types.bool.withDefault(true),
        checkedF: types.bool.withDefault(true),
        checkedG: types.bool.withDefault(true)
    }, {
        setCheckedA: (props) => (e: any) => ({ ...props, checkedA: e.target.checked }),
        setCheckedB: (props) => (e: any) => ({ ...props, checkedB: e.target.checked }),
        setCheckedF: (props) => (e: any) => ({ ...props, checkedF: e.target.checked }),
        setCheckedG: (props) => (e: any) => ({ ...props, checkedG: e.target.checked })
    })
    .render(({
        $,
        checkedA,
        checkedB,
        checkedF,
        checkedG,
        setCheckedA,
        setCheckedB,
        setCheckedF,
        setCheckedG
    }) => {
        const $Paper = $(Paper, 'Paper');
        const $GreenCheckbox = $(Checkbox, 'GreenCheckbox');

        return (
            <>
                <$Paper>
                    <Typography variant="h5">
                        Checkboxes
                    </Typography>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkedA}
                                    onChange={setCheckedA}
                                    value="checkedA"
                                />
                            }
                            label="Secondary"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkedB}
                                    onChange={setCheckedB}
                                    value="checkedB"
                                    color="primary"
                                />
                            }
                            label="Primary"
                        />
                        <FormControlLabel control={<Checkbox value="checkedC" />} label="Uncontrolled" />
                        <FormControlLabel disabled control={<Checkbox value="checkedD" />} label="Disabled" />
                        <FormControlLabel disabled control={<Checkbox checked value="checkedE" />} label="Disabled" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkedF}
                                    onChange={setCheckedF}
                                    value="checkedF"
                                    indeterminate
                                />
                            }
                            label="Indeterminate"
                        />
                        <FormControlLabel
                            control={
                                <$GreenCheckbox
                                    checked={checkedG}
                                    onChange={setCheckedG}
                                    value="checkedG"
                                />
                            }
                            label="Custom color"
                        />
                        <FormControlLabel
                            control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} value="checkedH" />}
                            label="Custom icon"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    value="checkedI"
                                />
                            }
                            label="Custom size"
                        />
                    </FormGroup>
                </$Paper>
            </>
        );
    })
    .compile();
