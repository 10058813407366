import { green, grey } from '@material-ui/core/colors';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

/* const vanillaGrey = {
    light: grey[100],
    main: grey[300],
    dark: grey[500]
}; */

const volvoDmsBlue = {
    main: 'rgb(21, 101, 192)',
    light: 'rgb(80, 148, 226)',
    dark: 'rgb(12, 100, 199)'
};

export const CHAZ_DMS: ThemeOptions = {
    palette: {
        primary: volvoDmsBlue,
        secondary: green,
        text: {
            primary: grey[900],
            secondary: grey[100],
            disabled: grey[900],
            hint: grey[900]
        }
    },
    spacing: 4
};
