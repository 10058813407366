import { component, types } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';
import { ExtendedColorSet, FontSizeSet, TintSet } from '../../interfaces';
import { getInvertedColorStyling } from '../../utils';

export const UIIcon = component('UIIcon')
    .use(mui)
    .use(theme)
    .inject<boolean, 'inverted'>('inverted', 'inverted')
    .props({
        Icon: types.complex<React.ComponentType<SvgIconProps>>(),
        color: types.maybe(types.complex<ExtendedColorSet>().withDefault('primary')),
        tint: types.maybe(types.complex<TintSet>().withDefault('main')),
        fontSize: types.maybe(types.complex<FontSizeSet>())
    })
    .style(({ color, inverted, tint, theme }) => {
        const Icon: any = {};

        if (theme) {
            const {
                primary,
                secondary,
                action,
                error,
                background
            } = theme.palette;

            tint = tint || 'main';

            const fillPrimary = primary[tint];
            const fillSecondary = secondary[tint];
            const fillAction = action.active;
            const fillError = error[tint];
            const fillDisabled = action.disabled;
            const fillBackground = background.default;
            const fillPaper = background.paper;

            if (color === 'primary') {
                Icon.root = inverted ? getInvertedColorStyling(theme, fillPrimary) : { fill: fillPrimary };
            } else if (color === 'secondary') {
                Icon.root = inverted ? getInvertedColorStyling(theme, fillSecondary) : { fill: fillSecondary };
            } else if (color === 'action') {
                Icon.root = inverted ? getInvertedColorStyling(theme, fillAction) : { fill: fillAction };
            } else if (color === 'error') {
                Icon.root = inverted ? getInvertedColorStyling(theme, fillError) : { fill: fillError };
            } else if (color === 'disabled') {
                Icon.root = inverted ? getInvertedColorStyling(theme, fillDisabled) : { fill: fillDisabled };
            } else if (color === 'background') {
                Icon.root = inverted ? getInvertedColorStyling(theme, fillBackground) : { fill: fillBackground };
            } else if (color === 'paper') {
                Icon.root = inverted ? getInvertedColorStyling(theme, fillPaper) : { fill: fillPaper };
            }
        }

        return { Icon };
    })
    .reduce(({ theme, inverted, ...props }) => {
        if (props.color !== 'inherit') {
            delete props.color;
        }

        return props as typeof props & { color?: 'inherit' };
    })
    .render(({ $, Icon, tint, ...props }) => {
        const $Icon = $(Icon, 'Icon');

        return (
            <$Icon {...props} />
        );
    })
    .compile();
