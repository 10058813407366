import { Lokka } from 'lokka';
import { Transport as LokkaTransport } from 'lokka-transport-http';
import { ITransport } from '../interfaces';

export class ClientGQLTransport implements ITransport {
    private client: any;

    public constructor(fallbackUrl?: string) {
        this.client = new Lokka({
            transport: new LokkaTransport((window as any).APP_SERVICE_REGISTRY_URI || fallbackUrl)
        });
    }

    public async send<T>(query: string, payload: any): Promise<T> {
        return await this.client.query(query, payload);
    }
}
