import { action, computed } from 'mobx';
import { IApplication, IApplicationOpenArgs, IApplicationTheme, INavigation,
    ISubdomain, ISubdomainData, TApplicationData } from '../interfaces';
import { Subdomain } from './subdomain';

export class Application implements IApplication {
    public id: string;
    private nav: INavigation;

    public constructor(nav: INavigation, id: string) {
        this.nav = nav;
        this.id = id;
    }

    @computed private get data(): TApplicationData {
        return this.nav.applicationsData.find((data) => {
            return data.id === this.id;
        })!;
    }

    @computed public get title() {
        return this.data.title;
    }

    @computed public get logo() {
        return this.data.logo;
    }

    @computed public get tag() {
        return this.data.tag;
    }

    @computed public get protocol() {
        return this.data.protocol;
    }

    @computed public get hostname() {
        return this.data.hostname;
    }

    @computed public get devServerPort() {
        return this.data.devServerPort;
    }

    @computed public get favorite() {
        return this.data.favorite;
    }

    @computed public get subdomainData(): ISubdomainData[] {
        return this.data.subdomainData;
    }

    @computed public get subdomains(): ISubdomain[] {
        return this.subdomainData.map((data) => {
            return new Subdomain(this, data.id);
        });
    }

    @computed public get theme(): IApplicationTheme {
        return this.data.theme;
    }

    @computed public get visible() {
        return this.data.visible;
    }

    @action public markAsFavorite = () => {
        this.nav.markApplicationAsFavorite(this.id);
    }

    @action public unmarkAsFavorite = () => {
        this.nav.unmarkApplicationAsFavorite(this.id);
    }

    public getUrl = ({ subdomainTag, path }: IApplicationOpenArgs = {}) => {
        let url = `${this.protocol}://${this.hostname}`;

        if (this.devServerPort) {
            url += `:${this.devServerPort}`;
        }

        if (path !== undefined) {
            return url + path;
        } else if (subdomainTag !== undefined) {
            const subdomain = this.subdomains.find((subdomain) => {
                return subdomain.tag === subdomainTag;
            });

            if (!subdomain) {
                throw new Error('Subdomain not found: ' + subdomainTag);
            }

            return url + subdomain.getPathname();
        }

        return url;
    }

    public open = (args: IApplicationOpenArgs = {}) => {
        const url = this.getUrl();
        let title = this.title;
        let pathname = '/';

        if (args.subdomainTag !== undefined) {
            const subdomain = this.subdomains.find((subdomain) => {
                return subdomain.tag === args.subdomainTag;
            });

            if (!subdomain) {
                throw new Error('Subdomain not found: ' + args.subdomainTag);
            }

            pathname = subdomain.getPathname();
            title = subdomain.title;
        }

        if (args.path !== undefined) {
            pathname = args.path;
        }

        if (window.location.hostname === this.hostname) {
            window.history.pushState({}, title, pathname);
        } else {
            window.open(url + pathname);
        }
    }
}
