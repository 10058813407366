import { component, types } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import * as rectserver from '@lardy/rectserver';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import * as React from 'react';
import { INavigation } from '../../interfaces';

export const DrawerView = component('DrawerView')
    .use(mui)
    .use(theme)
    .use(rectserver.getElementRect)
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .props({
        anchor: types.complex<'left' | 'right'>(),
        Menu: types.complex<React.ComponentType<any>>(),
        role: types.str,
        title: types.str,
        width: types.num.withDefault(240),
        dark: types.maybe(types.bool.withDefault(false))
    })
    .reduce(({ dark, getElementRect, role, navigation, theme, ...props }) => {
        const drawer = navigation.getUIState<boolean>(role, false);

        const onClose = () => {
            drawer.setState(false);
        };

        const navbarRect = getElementRect('__navbar');

        let paddingTop = 0;

        if (navbarRect !== null) {
            paddingTop = navbarRect.height;
        }

        let bgColor = theme.palette.background.paper;
        let fontColor = theme.palette.getContrastText(bgColor);

        if (dark) {
            fontColor = theme.palette.grey[100];
            bgColor = theme.palette.grey[900];
        }

        return {
            ...props,
            open: drawer.state,
            onClose,
            paddingTop,
            fontColor,
            bgColor
        };
    })
    .style(({ anchor, open, paddingTop, fontColor, bgColor, width }) => ({
        ViewContainer: {
            display: 'flex',
            minHeight: '100vh'
        },
        Drawer: {
            root: {
                width: width || 0,
                flexShrink: 0
            },
            paper: {
                'width': width || 0,
                '&[class*="MuiPaper-root"]': {
                    color: fontColor,
                    background: bgColor
                }
            }
        },
        DrawerHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 8px'
        },
        Header: {
            fontSize: 20
        },
        Main: {
            flexGrow: 1,
            padding: `${paddingTop}px 20px 20px 20px`,
            [anchor === 'left' ? 'marginLeft' : 'marginRight']: open ? 0 : -(width || 0)
        }
    }))
    .reduce(({ paddingTop, ...props }) => props)
    .render(({ $, anchor, children, fontColor, open, Menu, onClose, title }) => {
        const $Drawer = $(Drawer, 'Drawer');
        const $Header = $(Typography, 'Header');

        return (
            <$.div.ViewContainer>
                {
                    anchor === 'right'
                    ? (
                        <$.main.Main>
                            {children}
                        </$.main.Main>
                    )
                    : null
                }
                <$Drawer
                    variant="persistent"
                    anchor={anchor}
                    open={open}
                >
                    <$.div.DrawerHeader>
                        <$Header variant="h6">
                            {title}
                        </$Header>
                        <IconButton onClick={onClose}>
                            <Close htmlColor={fontColor} />
                        </IconButton>
                    </$.div.DrawerHeader>
                    <Menu />
                </$Drawer>
                {
                    anchor === 'left'
                    ? (
                        <$.main.Main>
                            {children}
                        </$.main.Main>
                    )
                    : null
                }
            </$.div.ViewContainer>
        );
    })
    .compile();
