import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const CHAZ_PROPARTNER: ThemeOptions = {
    palette: {
        common: {
            black: '#000',
            white: '#fff'
        },
        background: {
            paper: '#fff',
            default: '#fafafa'
        },
        primary: {
            light: 'rgba(114, 150, 242, 1)',
            main: 'rgba(36, 93, 242, 1)',
            dark: 'rgba(17, 73, 220, 1)',
            contrastText: '#fff'
        },
        secondary: {
            light: 'rgba(252, 230, 156, 1)',
            main: 'rgba(251, 212, 78, 1)',
            dark: 'rgba(250, 164, 62, 1)',
            contrastText: '#fff'
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff'
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)'
        }
    }
};
