import { ComponentType, createElement, createRef, SFC, useEffect } from 'react';

export const hasClass = (element: HTMLElement, className: string) => {
    return (' ' + element.className + ' ').replace(/[\n\t]/g, ' ').indexOf(` ${className} `) > -1;
};

export const hasAncestor = (element: HTMLElement, className: string) => {
    return !!element.closest(`.${className}`);
};

export const onClickOutside = (callback: (props: any, target: HTMLElement) => void, ignoreClassName?: string) => {
    return <P>(Component: ComponentType<P>) => {
        const ClickOutside: SFC<P> = (props) => {
            const ref = createRef<HTMLDivElement>();

            function onClick(e: MouseEvent) {
                if (!ref.current || !e.target || ref.current.contains(e.target as Node)) {
                    return;
                }

                if (ignoreClassName && hasClass(e.target as HTMLElement, ignoreClassName)) {
                    return;
                }

                callback(props, e.target as HTMLElement);
            }

            useEffect(() => {
                if (typeof document === 'undefined') {
                    return;
                }

                document.body.addEventListener('click', onClick);

                return () => {
                    document.body.removeEventListener('click', onClick);
                };
            });

            return createElement(
                'span',
                { ref },
                createElement(Component, props)
            );
        };

        ClickOutside.displayName = 'onClickOutside('
            + Component.displayName
            + ')';

        return ClickOutside;
    };
};
