import { component } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

export const TypographySnippet = component('TypographySnippet')
    .use(mui)
    .use(theme)
    .style(({ theme }) => ({
        Paper: {
            root: {
                '&&': {
                    padding: theme ? theme.spacing(2) : 0
                }
            }
        },
        ColorTest: {
            color: theme ? theme.palette.primary.dark : '#000',
            padding: 20,
            fontSize: 36,
            textAlign:  'center' as 'center'
        }
    }))
    .render(({ $ }) => {
        const $Paper = $(Paper, 'Paper');

        return (
            <>
                <$Paper>
                    <Typography variant="h1">
                        Typography h1
                    </Typography>
                    <Typography variant="h2">
                        Typography h2
                    </Typography>
                    <Typography variant="h3">
                        Typography h3
                    </Typography>
                    <Typography variant="h4">
                        Typography h4
                    </Typography>
                    <Typography variant="h5">
                        Typography h5
                    </Typography>
                    <Typography variant="h6">
                        Typography h6
                    </Typography>
                    <Typography paragraph>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum
                        facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
                        gravida rutrum quisque non tellus. Convallis convallis tellus id interdum velit laoreet id
                        donec ultrices. Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
                        adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra nibh cras.
                        Metus vulputate eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo quis
                        imperdiet massa tincidunt. Cras tincidunt lobortis feugiat vivamus at augue. At augue eget
                        arcu dictum varius duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem
                        donec massa sapien faucibus et molestie ac.
                    </Typography>
                </$Paper>
                <$Paper>
                    <$.div.ColorTest>
                        This string is a non-MUI component that retrieves its color from the MUI theme
                    </$.div.ColorTest>
                </$Paper>
            </>
        );
    })
    .compile();
