import { action, computed, observable } from 'mobx';
import { ICompanyNode, ICompanyNodeData, INavigation } from '../interfaces';

export class CompanyNode implements ICompanyNode {
    @observable public companyId: string | null;

    private nav: INavigation;
    private hideForbiddenChildren: boolean;

    public constructor(nav: INavigation, companyId: string | null, hideForbbidenChildren: boolean) {
        this.nav = nav;
        this.companyId = companyId;
        this.hideForbiddenChildren = hideForbbidenChildren;
    }

    @computed private get data(): ICompanyNodeData {
        return this.nav.companyTreeFullData.find((data) => {
            return data.companyId === this.companyId;
        })!;
    }

    @computed public get children() {
        if (this.companyId === null) {
            return [];
        }

        const companies = this.hideForbiddenChildren ? this.nav.companies : this.nav.accountCompanies;

        return companies.filter((company) => {
            const show = company.parentCompanyId === this.companyId;

            if (this.hideForbiddenChildren && !this.nav.account) {
                return [];
            } else if (this.hideForbiddenChildren) {
                return show
                    && (
                        this.nav.account!.isAdmin
                        || this.nav.account!.companies.map((c) => c.companyId).indexOf(company.companyId) !== -1
                    );
            } else {
                return show;
            }
        });
    }

    @computed public get selected() {
        return this.companyId === this.nav.selectedCompanyId;
    }

    @computed public get name() {
        return this.data.name;
    }

    @computed public get parentCompanyId() {
        return this.data.parentCompanyId;
    }

    @computed public get companyNumber() {
        return this.data.companyNumber;
    }

    @computed public get branchCode() {
        return this.data.branchCode;
    }

    @computed public get branchCodeAliases() {
        return this.data.branchCodeAliases;
    }

    @computed public get localisationId() {
        return this.data.localisationId;
    }

    @computed public get authId() {
        return this.data.authId;
    }

    @computed public get afPassword() {
        return this.data.afPassword;
    }

    @computed public get info() {
        return this.data.info;
    }

    @computed public get ppnm__bulkExportUrl() {
        return this.data.ppnm__bulkExportUrl;
    }

    @computed public get ppnm__bulkImportUrl() {
        return this.data.ppnm__bulkImportUrl;
    }

    @computed public get isRoot() {
        return this.companyId === null;
    }

    @computed public get parent() {
        if (this.parentCompanyId === null) {
            return undefined;
        }

        const companies = this.hideForbiddenChildren ? this.nav.companies : this.nav.accountCompanies;

        return companies.find((company) => {
            return company.companyId === this.parentCompanyId;
        });
    }

    @action public select() {
        return this.nav.setSelectedCompanyId(this.companyId || undefined);
    }
}
