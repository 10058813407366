import { component } from '@lardy/core';
import { mui, theme } from '@lardy/mui';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import * as React from 'react';
import { INavigation } from '../../../interfaces';

export const AccountMenuEditProfileLink = component('AccountMenuEditProfileLink')
    .use(mui)
    .use(theme)
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .style(({ theme }) => ({
        Container: {
            root: {
                '&&': {
                    maxWidth: 200,
                    margin: '0px auto',
                    textAlign: 'center' as 'center'
                }
            }
        },
        Skeleton: {
            root: {
                '&&': {
                    margin: '0'
                }
            }
        },
        Link: {
            root: {
                '&&': {
                    fontSize: 12,
                    fontWeight: 100,
                    margin: '0',
                    textTransform: 'none' as 'none'
                }
            }
        },
        Icon: {
            root: {
                '&&': {
                    fontSize: 12,
                    position: 'relative' as 'relative',
                    top: -2,
                    marginRight: 3
                }
            }
        }
    }))
    .render(({ $, navigation }) => {
        const $Skeleton = $(Skeleton, 'Skeleton');
        const $Link = $(Button, 'Link');
        const $Icon = $(Edit, 'Icon');

        const handleClick = React.useCallback((e: any) => {
            e.preventDefault();
            navigation.navigate('mypages', { subdomainTag: 'account' });
        }, [navigation]);

        if (!navigation.getUrl('mypages', { subdomainTag: 'account' })) {
            return null;
        }

        return (
            <$.div.Container>
                {
                    navigation.account
                    ? (
                        <$Link
                            variant="text"
                            color="primary"
                            fullWidth={true}
                            onClick={handleClick}
                        >
                            <$Icon /> Redigera profil
                        </$Link>
                    )
                    : (
                        <$Skeleton height={20} variant="text" />
                    )
                }
            </$.div.Container>
        );
    })
    .compile();
