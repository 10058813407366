import { component, types } from '@lardy/core';
import * as mui from '@lardy/mui';
import * as rectserver from '@lardy/rectserver';
import * as responsivity from '@lardy/responsivity';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { StylesProviderProps } from '@material-ui/styles/StylesProvider';
import { Omit } from '@material-ui/types';
import * as React from 'react';
import { INavigation } from '../interfaces';

export const NavigationContext = component('NavigationContext')
    .props({
        navigation: types.complex<INavigation>()
    })
    .provide('navigation', 'navigation')
    .render(({ children }) => <>{children}</>)
    .compile();

export const getNavigationContextWithNavigation = (navigation: INavigation) => {
    const WrappedNavigationContext = (props: any) => {
        return React.createElement(NavigationContext, {
            navigation,
            ...props
        });
    };

    WrappedNavigationContext.displayName = 'WrappedNavigationContext';

    return WrappedNavigationContext;
};

export const getUIContext = (
    theme: ThemeOptions,
    breakpoints: { sm: number; md: number; lg: number } = {
        sm: 0,
        md: 768,
        lg: 1024
    },
    stylesProviderProps?: Omit<StylesProviderProps, 'children'>
) => {
    return component('UIContext')
        .use(rectserver.provider())
        .use(responsivity.provider(breakpoints))
        .use(mui.themeProvider(theme, stylesProviderProps))
        .render(({ children }) => <>{children}</>)
        .compile();
};
