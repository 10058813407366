import { component, types } from '@lardy/core';
import { mui } from '@lardy/mui';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';
import * as React from 'react';
import { INavigation, IXSearchResultItem } from '../../../interfaces';

export const XSearchbarResult = component('XSearchbarResult')
    .inject<INavigation, 'navigation'>('navigation', 'navigation')
    .use(mui)
    .props({
        item: types.maybe(types.complex<IXSearchResultItem>())
    })
    .style(() => {
        const style = {
            Avatar: {
                width: 40,
                height: 40
            },
            ListItem: {},
            ListItemAvatar: {},
            ListItemText: {},
            SkeletonIngress: {
                height: 10,
                width: '40%'
            },
            SkeletonTitle: {
                height: 10,
                marginBottom: 6,
                width: '80%'
            }
        };

        return style;
    })
    .reduce(({ navigation, item, ...props }) => ({
        onClick: () => {
            const application = navigation.applications.find((application) => {
                return application.id === item!.applicationId;
            });

            application!.open({ path: item!.path });
        },
        item,
        ...props
    }))
    .render(({ $, item, onClick }) => {
        const $Avatar = $(Avatar, 'Avatar');
        const $ListItem = $(ListItem, 'ListItem');
        const $SkeletonAvatar = $(Skeleton, 'Avatar');
        const $SkeletonIngress = $(Skeleton, 'SkeletonIngress');
        const $SkeletonTitle = $(Skeleton, 'SkeletonTitle');

        return (
            <$ListItem
                button={true}
                alignItems="flex-start"
                onClick={onClick}
            >
                <ListItemAvatar>
                    {
                        item
                        ? (
                            item.thumb
                            ? (
                                <$Avatar
                                    alt={item.title}
                                    src={item.thumb}
                                />
                            )
                            : (
                                <$Avatar>
                                    XX
                                </$Avatar>
                            )
                        )
                        : (
                            <$SkeletonAvatar variant="circle" />
                        )
                    }
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <>
                            {
                                item
                                ? item.title
                                : (
                                    <$SkeletonTitle />
                                )
                            }
                        </>
                    }
                    secondary={
                        <>
                            {
                                item
                                ? item.ingress
                                : (
                                    <$SkeletonIngress />
                                )
                            }
                        </>
                    }
                />
            </$ListItem>
        );
    })
    .compile();
